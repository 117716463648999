import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, BigState, createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, EntityTableSimpleRRC, EntityTableSimple, EntityTableSimpleReducers, EntityTableSimpleState, ENT_ADD, ENT_DELETE, ENT_SAVE, getBaseImpures, getBaseReducers, Optional, Organization, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, StateFrom, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { AddressForMap } from "apollo-gen/AddressForMap";
import { loadAddressesForMap, loadAddressesForMapVariables } from "apollo-gen/loadAddressesForMap";
import { InitializationsForClient, MapSettings, MarkerSettings } from "app";
import { EditMode, ID, MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { MapGoToButton, RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import Interweave from "interweave";
import lodash from "lodash";
import { DELETE_ADDRESS_FROM_MAP, LOAD_ADDRESSES_FOR_MAP, SAVE_ADDRESSES_FOR_MAP } from "pages/Address/queries";
import React from "react";
import { Dimmer, Loader, Message } from "semantic-ui-react";

export class AddressEntityDescriptor extends EntityDescriptor {
    protected customize() {
        // as var because it's used in some inner functions
        const addressEntityDescriptor = this;

        const sliceAddressTablePage = addressEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

            nestedSlices = {
                ...sliceEntityTablePageOnlyForExtension.nestedSlices,
            }

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
                addresses: undefined as Optional<{ [key: string]: AddressForMap }>,
            }

            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),

                add(state: StateFrom<Ext>, newAddresses: AddressForMap[]) {
                    if (!state.addresses) {
                        state.addresses = {};
                    }
                    newAddresses.forEach(newAddress => {
                        state.addresses![newAddress.id] = newAddress;
                    });
                },

                remove(state: StateFrom<Ext>, ids: ID[]) {
                    ids.forEach(id => {
                        delete state.addresses![id];
                    });
                }
            }

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

                async loadAddressesOnMap(mapContainer: Optional<MapContainerLeaflet>, ids?: Optional<ID[]>) {
                    let filterFromCQ = this.getFilterForLoad();
                    let filter: Filter;
                    if (ids && ids.length > 0) {
                        const filters: Filter[] = [];
                        filters.push(filterFromCQ);
                        filters.push(Filter.create("id", FilterOperators.forNumber.in, ids.join(", ")));
                        filter = Filter.createComposed(FilterOperators.forComposedFilter.and, filters);
                    } else {
                        filter = filterFromCQ;
                    }
                    const list: Optional<AddressForMap[]> = (await apolloClient.query<loadAddressesForMap, loadAddressesForMapVariables>({
                        query: LOAD_ADDRESSES_FOR_MAP,
                        variables: FindByFilterParams.create().filter(filter)
                    })).data.addressService_findByFilter?.results;

                    if (!list || list.length === 0) {
                        this.getDispatchers().add([]);
                        return;
                    }

                    this.getDispatchers().add(list);
                    this.addAddressesOnMap(list, mapContainer);
                },

                addAddressesOnMap(list: AddressForMap[], mapContainer: Optional<MapContainerLeaflet>) {
                    let data: MarkerData[] = [];
                    list.forEach((a: AddressForMap) => {
                        if (a.longitude !== undefined && a.latitude !== undefined &&
                            a.longitude !== null && a.latitude !== null) {
                            data.push({ id: a.id, point: { longitude: a.longitude, latitude: a.latitude }, text: a.name || "" });
                        }
                    });
                    mapContainer?.addOrUpdateLayers(data, addressEntityDescriptor.name);
                },

                isAllowed(editMode: EditMode): boolean {
                    const mode = editMode === EditMode.DRAW ? ENT_ADD : editMode === EditMode.EDIT ? ENT_SAVE : ENT_DELETE;
                    const permission = Utils.pipeJoin([mode, addressEntityDescriptor.name]);
                    if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
                        return false;
                    }
                    return true;
                }

            }
        }).setEntityDescriptor(addressEntityDescriptor);

        type PropsNotFromState = { mapSettings: MapSettings };
        type Props = EntityTablePageProps & PropsFrom<typeof sliceAddressTablePage> & PropsNotFromState;

        async function updateAddresses(this: any, markers: MarkerData[], mapContainer: MapContainerLeaflet, org?: Organization) {
            let ids: number[] = [];
            for (const m of markers) {
                let address = m.id ? { ...this.props.addresses![m.id] } : { name: "UNKNOWN" } as AddressForMap;

                let fieldsAndValue: any = {
                    name: address.name,
                    latitude: m.point.latitude,
                    longitude: m.point.longitude
                };
                if (org) {
                    fieldsAndValue['organization'] = { id: org?.id }
                }
                const id = (await apolloClient.mutate({ mutation: SAVE_ADDRESSES_FOR_MAP, variables: { params: { id: address.id, fieldsAndValues: fieldsAndValue } } })).data.addressService_save?.id;
                ids.push(id);
            }
            // refresh the table
            this.refresh();

            // update only modified & added features
            await this.props.dispatchers.loadAddressesOnMap(mapContainer, ids);

            if (markers.length === 1 && markers[0].id === undefined) {
                mapContainer.props.r.setInReduxState({ selectedLayer: { id: ids[0], type: addressEntityDescriptor.name } });
            }
        }

        async function removeAddresses(this: any, markers: MarkerData[], mapContainer: MapContainerLeaflet) {
            const permission = Utils.pipeJoin([ENT_DELETE, addressEntityDescriptor.name]);
            if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
                return;
            }

            let ids: ID[] = [];
            for (const m of markers) {
                ids.push(m.id!);
                await apolloClient.mutate({ mutation: DELETE_ADDRESS_FROM_MAP, variables: { id: m.id } });
            }
            // refresh the table
            this.refresh();

            this.props.dispatchers.remove(ids);
            mapContainer?.removeLayers(addressEntityDescriptor.name, ids);
        }

        addressEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<Props> {

            async updateAddresses(markers: MarkerData[], mapContainer: MapContainerLeaflet, org?: Organization) {
                updateAddresses.apply(this, [markers, mapContainer, org]);
            }

            async removeAddresses(markers: MarkerData[], mapContainer: MapContainerLeaflet) {
                removeAddresses.apply(this, [markers, mapContainer]);
            }

            mapContainerRef = React.createRef<MapContainerLeaflet>();

            constructor(props: Props) {
                super(props);
                this.renderTooltipContent = this.renderTooltipContent.bind(this);
                this.renderMarkerIcon = this.renderMarkerIcon.bind(this);

                const that = this;
                this.tableSimpleClass = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, class extends EntityTableSimple {
                    onSelectItem(selectId: any) {
                        that.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: selectId, type: addressEntityDescriptor.name } });
                    }
                });
            }

            componentDidMount() {
                this.componentDidUpdateInternal();
            }

            componentDidUpdateInternal(prevProps?: Props) {
                super.componentDidUpdateInternal(prevProps);

                // set this here instead of componentDidMount, because ref is null there
                if (!this.mapContainerRef?.current?.props.s.editDrawEnabledOnType){
                    this.mapContainerRef?.current?.props.r.setInReduxState({ editDrawEnabledOnType: addressEntityDescriptor.name, editMode: undefined });
                }

                if (this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter !== prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter) {
                    this.props.dispatchers.setInReduxState({ addresses: undefined });
                    this.entityTableSimpleRef.current?.setSelected(undefined);
                    this.mapContainerRef.current?.clearMap();
                    this.props.dispatchers.loadAddressesOnMap(this.mapContainerRef.current);
                }

                // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
                if (this.mapContainerRef.current && lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && this.props.mapSettings.airport !== null) {
                    RealTimeUtils.setAirportCoordinates(this.props.mapSettings.airport, this.mapContainerRef);
                }

                RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(prevProps, this.props, this.mapContainerRef);
            }

            renderTooltipContent(markerData: MarkerData, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
                return <div className="flex-container">{markerData.text}</div>;
            }

            renderMarkerIcon(markerData: MarkerData, type: string): React.ReactNode {
                const markerSettings: Optional<MarkerSettings> = this.props.mapSettings.markers.find(m => m.markerType === addressEntityDescriptor.name);
                return <><span className='fa fa-stack fa-lg'><i className={'fa fa-map-marker fa-stack-1x'} style={{ color: 'blue' }}></i></span>
                    {markerSettings?.showTextUnderIcon ? <div style={{ font: 'bold 12px Lato', whiteSpace: "nowrap" }}>{markerData.text}</div> : undefined}</>;
            }

            protected preRenderButtons(params: any): Array<OverrideableElement> {
                return [
                    ...super.preRenderButtons(params),
                    {
                        element:
                            <div key="selectAirport" className="MapContainerHeader_segment">
                                <Dimmer inverted active={this.props.addresses === undefined}></Dimmer>
                                <MapGoToButton options={RealTimeUtils.getMapGoToButtonProps(this.mapContainerRef)} />
                            </div>
                    }
                ];
            };

            renderMain() {
                return (<>                   
                    <SplitPaneExt size="30%">
                        {this.mapContainerRef.current?.props.s.editMode
                            ? <Message data-cy="editModeMessage" className="flex-container flex-grow">
                                <Interweave content={_msg(addressEntityDescriptor.name + "." + this.mapContainerRef.current!.props.s.editMode + "Mode")} />
                            </Message>
                            : this.renderTableSimple()}
                        <>
                            <Dimmer inverted active={this.props.addresses === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>

                            <MapContainerLeafletRRC id={"mapContainerLeafletAddressTable"} ref={this.mapContainerRef} mapId={"address-map"}
                                pruneClusterMode={localStorage.getItem('clusterMode') === 'pruneCluster'}
                                renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon} onEditModeChanged={() => this.forceUpdate()}
                                bingAPIKey={this.props.mapSettings.bingAPIKey} saveCenterZoomInStorage={true}
                                isAllowed={(editMode: EditMode) => { return this.props.dispatchers.isAllowed(editMode) }}
                                onLayerAdded={(marker: MarkerData, type: string) => this.updateAddresses([marker], this.mapContainerRef.current!, this.props.currentOrganization)}
                                onLayersEdited={(markers: MarkerData[], type: string) => this.updateAddresses(markers, this.mapContainerRef.current!, this.props.currentOrganization)}
                                onLayersRemoved={(markers: MarkerData[], type: string) => this.removeAddresses(markers, this.mapContainerRef.current!)}
                                layers={{ [addressEntityDescriptor.name]: { layerType: MARKER_TYPE, options: { useCluster: false, flyToSelectedMarker: true } } }} />
                        </>
                    </SplitPaneExt>
                </>)
            }
        }

        addressEntityDescriptor.infoTable.mapBigStateToProps = (state: BigState, props: any) => {
            props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;           
        }

    }
}
