import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import moment from "moment";
import React, { ReactElement } from "react";
import { Button, Checkbox, Dropdown, DropdownItemProps, Input, Segment } from "semantic-ui-react";
import { AbstractGantt, AbstractGanttProps, AbstractGanttReducers, AbstractGanttState, GanttGroup, GanttItem, GanttLayer } from "./AbstractGantt";
import { flightEntityDescriptor, taskEntityDescriptor } from "AppEntityDescriptors";
import { GanttUtils } from "./GanttUtils";
import { apolloClientHolder, ColumnDefinition, EntityDescriptor, FieldDescriptor, Utils } from "@crispico/foundation-react";
import gql from "graphql-tag";
import { isFlexMode } from "app";
import { InfoTaskMode } from "./typeRenderers/TaskItemRenderer";
import { FlightInfoRenderer } from "./typeRenderers/FlightInfoRenderer";
import { Table } from "fixed-data-table-2";
import { IntervalSelectorRRC } from "./components/IntervalSelector";
import FlightFilterComponent, { FlightFilter, FlightFilterComponentRRC } from "pages/flight/FlightFilterComponent";

const FIXED_FLIGHT_SEGMENT_WIDTH: number = 2; // value from flex, should be configurable
const FIXED_TASK_SEGMENT_WIDTH: number = 0.5;

export enum GanttTasksMode {
    FLIGHT_TASKS = "FLIGHT_TASKS",
    TASKS = "TASKS"
}

export interface GanttTasksOptions {
    displayMode: GanttTasksMode,
    hideAssignedTasks: boolean,
    tableFields?: string[]
}

class GanttTasksState extends AbstractGanttState {
    mode: GanttTasksMode | undefined;
    hideAssignedTasks: boolean | undefined;
    filterTaskType: string[] | undefined;
    infoTaskMode: InfoTaskMode = InfoTaskMode.ARR_DPT;
}
class GanttTasksReducers<S extends GanttTasksState = GanttTasksState> extends AbstractGanttReducers<S> { }

type GanttTasksProps = AbstractGanttProps & { showDatePicker?: boolean, showStatusFilter: boolean };
type Props = RRCProps<GanttTasksState, GanttTasksReducers> & GanttTasksProps;

export class GanttTasks extends AbstractGantt<GanttTasksProps, GanttTasksReducers, GanttTasksState> {

    private flightFilterRef = React.createRef<FlightFilterComponent>();

    constructor(props: Props) {
        super(props);
        this.isTaskMode = this.isTaskMode.bind(this);
        this.flexMode_onDropOnGantt = this.flexMode_onDropOnGantt.bind(this);
    }

    componentDidMount() {
        let mode: GanttTasksMode = (window.sessionStorage.getItem(GANTT_TASKS_DISPLAY_MODE) || GanttTasksMode.TASKS) as GanttTasksMode;
        let hideAssignedTasks: boolean = JSON.parse(window.sessionStorage.getItem(GANTT_TASKS_HIDE_ASSIGNED_TASKS) || "true");

        this.props.r.setInReduxState({ mode, hideAssignedTasks });

        if (isFlexMode()) {
            window.addEventListener("dropOnGantt", this.flexMode_onDropOnGantt);
            globalThis.ganttTasksTableWidthChange = (widthTablePercent: number) => {
                const tableWidth = window.innerWidth / widthTablePercent;
                this.props.r.setInReduxState({ tableWidth });
            }
            globalThis.ganttTasksDisplayIntervalChange = ([start, end]: [number, number]) => {
                this.props.r.setInReduxState({ start, end });
            }
            globalThis.setGanttTasksTableWidthAndDisplayInterval = ([widthTablePercent, start, end]: [number, number, number]) => {
                const tableWidth = window.innerWidth / widthTablePercent;
                this.props.r.setInReduxState({ tableWidth, start, end });
            }
            globalThis.ganttTasksFilter = (filter: string[]) => {
                this.props.r.setInReduxState({ filterTaskType: filter });
            }
            globalThis.ganttTasksInfoTaskMode = (mode: number) => {
                this.props.r.setInReduxState({ infoTaskMode: (mode as InfoTaskMode) | InfoTaskMode.ARR_DPT });
            }
            if (globalThis.js_to_as) {
                globalThis.js_to_as(["getGanttMissionTableWidthAndDisplayInterval"], "setGanttTasksTableWidthAndDisplayInterval")
                globalThis.js_to_as(["getGanttTasksFilter"], "ganttTasksFilter");
                globalThis.js_to_as(["getGanttTasksInfoTaskMode"], "ganttTasksInfoTaskMode");
            }
        }
    }

    flexMode_onDropOnGantt(event: any) {
        const details = JSON.parse(event.detail);
        const ganttBodyElement = document.querySelector(`.rct9k-id-${this.timelineId} .rct9k-grid`)
        if (!ganttBodyElement) {
            return;
        }
        const { top, bottom, left, right } = ganttBodyElement.getBoundingClientRect();
        if (details.clientX < right && details.clientX > left && details.clientY > top && details.clientY < bottom) {
            const header = document.getElementById("root")!;
            const startTime = this.getTimeAtPixel(details.clientX - details.offsetX).valueOf();
            const endTime = startTime + details.duration;
            const detail = {
                entityUid: details.entityUid,
                startTime,
                endTime
            }
            header.dispatchEvent(new CustomEvent("openEditor", { detail: JSON.stringify(detail) }));
        }
    }

    protected async componentDidUpdateInternal(prevProps?: Props) {
        super.componentDidUpdateInternal(prevProps);
        let shouldRefreshGanttData = false;
        if (prevProps && prevProps.s.hideAssignedTasks != this.props.s.hideAssignedTasks) {
            window.sessionStorage.setItem(GANTT_TASKS_HIDE_ASSIGNED_TASKS, this.props.s.hideAssignedTasks ? "true" : "false");
            shouldRefreshGanttData = true;
        }
        if (prevProps && prevProps.s.mode != this.props.s.mode) {
            window.sessionStorage.setItem(GANTT_TASKS_DISPLAY_MODE, this.props.s.mode as GanttTasksMode);
            shouldRefreshGanttData = true;
        }
        if (prevProps && prevProps.s.infoTaskMode != this.props.s.infoTaskMode) {
            shouldRefreshGanttData = true;
        }
        if (shouldRefreshGanttData) {
            this.processData(this.props.entities);
        }
    }

    public isTaskMode() {
        return this.props.s.mode === GanttTasksMode.TASKS;
    }

    private checkConditionsForMission(mission: any) {
        if (!mission) {
            return false;
        }
        let hasMission = false;
        if ((mission.humanResource && !(this.props.hideResources?.["HumanResource"] && this.props.hideResources["HumanResource"]?.findIndex(id => id === mission.humanResource.id) !== -1))) {
            hasMission = true;
        }
        if (!hasMission && mission.equipmentResource && !(this.props.hideResources?.["EquipmentResource"] && this.props.hideResources["EquipmentResource"]?.findIndex(id => id === mission.equipmentResource.id) !== -1)) {
            hasMission = true;
        }
        if (!hasMission && mission.equipmentType && !(this.props.hideResources?.["EquipmentType"] && this.props.hideResources["EquipmentType"]?.findIndex(id => id === mission.equipmentType.id) !== -1)) {
            hasMission = true;
        }
        return hasMission;
    }

    protected addTaskItems(items: { [uid: string]: GanttItem }, entities: any, flight: any, index: number) {
        let taskCounter = 0;
        flight.tasks?.forEach((task: any) => {
            let hasMission = this.checkConditionsForMission(task.mission);
            if ((this.props.s.hideAssignedTasks && hasMission) || (this.props.s.filterTaskType && this.props.s.filterTaskType.length && !this.props.s.filterTaskType.find(taskType => taskType === task.taskType.name))) {
                return;
            }
            const entityUid = GanttUtils.toEntityUid(taskEntityDescriptor.name, task.id);
            if (items[entityUid]) {
                console.log(`The Task(id = ${task.id}) ${taskEntityDescriptor.toMiniString(task)} from Flight(id = ${flight.id}) ${flightEntityDescriptor.toMiniString(flight)} exist in gantt!`);
                return;
            }
            let startTime = task.actualStartTime, endTime = task.actualEndTime;

            if (!startTime && !endTime) {
                startTime = moment(flight.date).add(0.05 + taskCounter * FIXED_TASK_SEGMENT_WIDTH, 'h').valueOf();
                taskCounter++;
                endTime = moment(flight.date).add(taskCounter * FIXED_TASK_SEGMENT_WIDTH, 'h').valueOf();
            } else if (!startTime) {
                startTime = moment(endTime).add(-FIXED_TASK_SEGMENT_WIDTH, 'h').valueOf();
            } else if (!endTime) {
                endTime = moment(startTime).add(FIXED_TASK_SEGMENT_WIDTH, 'h').valueOf();
            }
            if (startTime > endTime) {
                console.log(`The Task(id = ${task.id}) ${taskEntityDescriptor.toMiniString(task)} from Flight(id = ${flight.id}) ${flightEntityDescriptor.toMiniString(flight)} has startTime > endTime; it isn't displayed in gantt!`);
                return;
            }

            items[entityUid] = {
                key: entityUid,
                ganttId: this.constructor.name,
                row: index,
                start: startTime,
                end: endTime,
                entityUid: entityUid,
                visible: true
            }
        });
    }

    protected filtersMatchesFlight(flight: any) {
        if (!flight.showFlightInGantt) {
            return false;
        }
        let startDate: number = moment(flight.arrivalDate || flight.date).valueOf();
        let endDate: number = moment(flight.departureDepartureDate || flight.date).valueOf();
        if (this.props.s.end < startDate || this.props.s.start > endDate) {
            return false;
        }
        const filter = this.flightFilterRef.current?.props.s.filter;
        if (!filter) {
            return true;
        }
        return FlightFilterComponent.filterMatchesFlight(flight, filter);
    }

    protected processData(newEntities: any) {
        let flights: any = newEntities["Flight"];
        if (!flights) {
            return;
        }
        let layers: GanttLayer[] = [], groups: GanttGroup[] = [], items: { [uid: string]: GanttItem } = {};

        const sortedFlights = Object.keys(flights).map(key => Number.parseFloat(key))
            .map(key => {
                const flight = flights[key];
                let startDate: number = moment(flight.arrivalDate || flight.date).valueOf();
                let endDate: number = moment(flight.departureDepartureDate || flight.date).valueOf();
                return { flight, startDate, endDate };
            }).sort((a, b) => a.startDate === b.startDate ? 0 : a.startDate > b.startDate ? 1 : -1);

        let groupIndex = -1;
        sortedFlights.forEach((x) => {
            const flight = x.flight;
            const rotationFlight = x.flight.rotationFlight ? flights[x.flight.rotationFlight.id] : undefined;
            const flightUid = GanttUtils.toEntityUid("Flight", flight.id);
            const rotationFlightUid = rotationFlight ? GanttUtils.toEntityUid("Flight", rotationFlight.id) : undefined;

            // filter flights
            const showFlight = this.filtersMatchesFlight(flight);
            const showRotationFlight = rotationFlight && this.filtersMatchesFlight(rotationFlight);
            // if pair already added => don't add it again
            if ((!showFlight && !showRotationFlight) || groups.find(group => group.entityUid === flightUid || group.entityUid === rotationFlightUid)) {
                return;
            }

            groupIndex++;

            if (showFlight) {
                this.addTaskItems(items, newEntities, GanttUtils.findByUid(flightUid, newEntities), this.isTaskMode() ? 0 : groupIndex);
            }
            if (showRotationFlight){
                this.addTaskItems(items, newEntities, GanttUtils.findByUid(rotationFlightUid!, newEntities), this.isTaskMode() ? 0 : groupIndex);
            }

            if (this.isTaskMode()) {
                // if task mode don't add groups, flight items and layers
                if (groups.length) {
                    return;
                }
                // for task mode need to add a group for show the tasks on 1st row
                groups.push({
                    id: groupIndex,
                    entityUid: groupIndex,
                    key: groupIndex,
                    ganttId: this.constructor.name,
                    visible: true
                });
                return;
            }
            groups.push({
                id: groupIndex,
                entityUid: flightUid,
                key: flightUid,
                ganttId: this.constructor.name,
                visible: true
            });

            let layerFlight: GanttLayer = {
                rowNumber: groupIndex,
                start: x.startDate,
                end: x.endDate,
                style: { background: `lightgrey`, opacity: 0.5 },
                visible: true
            };

            layers.push(layerFlight);
        });
        const data = { layers, items: Object.values(items), groups };
        this.props.r.setInReduxState({ data: data });
    }

    protected entitiesChangedHandler(newEntities: any) {
        this.processData(newEntities);
    }

    protected onSelectionChange(selectedItems: (number | string)[]) {
        if (isFlexMode()) {
            globalThis.js_to_as(["onSelectionChange", ...selectedItems]);
        }
    }

    protected getAcceptedType() {
        return "Task";
    }

    protected getEntityDescriptor() {
        return flightEntityDescriptor;
    }

    protected getContextProps() {
        return {
            ... super.getContextProps(),
            isTaskMode: this.isTaskMode(),
            infoTaskMode: this.props.s.infoTaskMode,
            hideAssignedTasks: this.props.s.hideAssignedTasks
        };
    }

    protected getTableColumns(): ColumnDefinition[] | undefined {
        if (this.isTaskMode()) {
            return [];
        }
        const columns = super.getTableColumns();
        if (columns) {
            return columns;
        }
        const fields = ["planeIdentifier", "arrivalAirline", "arrivalNumber", "arrivalDate", "arrivalInitialDate", "arrivalOrigin",
            "departureAirline", "departureNumber", "departureDepartureDate", "departureInitialDate", "departureDestination", "parking", "planeType"];
        let cc: ColumnDefinition[] = [];
        fields.forEach(f => cc.push({ width: 150, name: f }));
        return cc;
    }

    protected renderTable(table: ReactElement<Table>) {
        if (this.isTaskMode()) {
            return null;
        }
        return super.renderTable(table);
    }

    protected renderTopBar(): React.ReactNode {
        const optionsGroup: DropdownItemProps[] = [{ value: GanttTasksMode.FLIGHT_TASKS, text: _msg("GanttTasks.modeFlights.label") }, { value: GanttTasksMode.TASKS, text: _msg("GanttTasks.modeTasks.label") }]
        return <>
            {!isFlexMode() ? this.renderAdditionalTopBar() : null}
            <div className="flex-container">{_msg("GanttTasks.displayMode.title")}
                <Dropdown selection options={optionsGroup} value={this.props.s.mode} onChange={(e, data) =>
                    this.props.r.setInReduxState({ mode: data.value as GanttTasksMode })
                } />
            </div>
            <div className="flex-container">{_msg("GanttTasks.hideAssignedTasks.title")}
                <Checkbox checked={this.props.s.hideAssignedTasks} onClick={(event, data) =>
                    this.props.r.setInReduxState({ hideAssignedTasks: data.checked ? true : false })
                } />
            </div>
        </>;
    }

    protected renderAdditionalTopBar() {
        return <>
            <FlightFilterComponentRRC id="flightFilterComponent" ref={this.flightFilterRef}
                showDepartureFilter={false} showFlightTypeFilter={false} showLastKnownDateTypeFilter={true}
                onFilterChange={(_) => this.processData(this.props.entities)} />
            {/* <IntervalSelectorRRC id="intervalSelector" setStartEndTimeGanttTask={(start: number, end: number) => this.props.r.setInReduxState({ start, end })} /> */}
        </>
    }

    render() {
        return <>
            {isFlexMode() ? <Segment className="flex-container gap5" style={{ width: this.props.s.tableWidth }}>
                {this.renderAdditionalTopBar()}
            </Segment> : null}
            {super.render()}
        </>;
    }
}

export const GanttTasksRRC = ReduxReusableComponents.connectRRC(GanttTasksState, GanttTasksReducers, GanttTasks);

const GANTT_TASKS_DISPLAY_MODE = 'ganttTasks.displayMode';
const GANTT_TASKS_HIDE_ASSIGNED_TASKS = 'ganttTasks.hideAssignedTasks';
