import { EntityDescriptor, EntityTablePage, EntityTablePageProps, FieldDescriptor, OmitFieldsOfTypeFunction } from "@crispico/foundation-react";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { humanResourceScheduleTeamsPageTab } from "./HumanResourceSchedule/HumanResourceScheduleEntityDescriptor";
import { EntityToTagFieldDescriptor } from "@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor";
import { GanttMessagesFieldDescriptor } from "./CommonFieldDescriptors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";

const FIELDS_FROM_DATA: string[] = ["correspondingAstusName"];

export class HumanResourceEntityDescriptor extends EntityDescriptor {
    protected customize() {
        this.isInDefaultColumnConfig(true, "unit", "organization", "identifier", "firstName", "lastName", "rfidIdentifier", "available", "vehicle",
            "lastEquipmentResourceAssignmentViaRfid", "pdaIdentifier", "todayStartTime", "todayEndTime", "comment", "mobileDevice")
            .addFieldDescriptor(new EntityToTagFieldDescriptor())
            .addFieldDescriptor(new GanttMessagesFieldDescriptor())
            .addFieldDescriptor({ name: "qualifications", type: FieldType.oneToMany("Qualification"), oneToManyOppositeField: "humanResource", enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true })
            .addFieldDescriptor({ name: "missions", type: FieldType.oneToMany("Mission2"), oneToManyOppositeField: "humanResource", enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true })
            .addTabDescriptor({ oneToManyEntityName: "Qualification", oneToManyOppositeField: "humanResource" })
            .addTabDescriptor({ oneToManyEntityName: "EquipmentUsageLog", oneToManyOppositeField: "driver" })
            .addTabDescriptor({ oneToManyEntityName: "HumanResourceSchedule", oneToManyOppositeField: "humanResource" })
            .addTabDescriptor({ oneToManyEntityName: "HumanResourceLog", oneToManyOppositeField: "humanResource" })
            .addTabDescriptor({ oneToManyEntityName: "Mission2", oneToManyOppositeField: "humanResource" })
            .addTabDescriptor({ oneToManyEntityName: "MobileDeviceActivationHistory", oneToManyOppositeField: "humanResource" })
            .addTabDescriptor({ oneToManyEntityName: "Interdiction", oneToManyOppositeField: "humanResource" })

        this.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps> {

            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                let extraTabPanes = [
                    humanResourceScheduleTeamsPageTab,
                    ...super.getExtraTabPanes()
                ];
                return extraTabPanes;
            }
        }

        this.doForFields(FIELDS_FROM_DATA, fd => fd.filterable = false);
        this.doForFields(FIELDS_FROM_DATA, fd => fd.sortable = false);
    }
}