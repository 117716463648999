import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Utils, apolloClientHolder } from "@crispico/foundation-react";
import { ClientCustomQuery } from "@crispico/foundation-react/components/CustomQuery/ClientCustomQuery";
import { getCustomQueryFromSessionStorage, loadAndUpdateCustomQuery } from "@crispico/foundation-react/components/CustomQuery/CustomQueryDropdown";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { CrudViewer } from "@crispico/foundation-react/entity_crud/CrudViewer";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { AbstractWidgetWithFilter, AbstractWidgetWithFilterProps, AbstractWidgetWithFilterReducers, AbstractWidgetWithFilterState, WidgetStatus } from "@crispico/foundation-react/pages/dashboard/AbstractWidgetWithFilter";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Button, Label, Modal } from "semantic-ui-react";
import { equipmentResourceEntityDescriptor } from "./equipmentResourceEntityDescriptor";
import { WidgetProps } from "@crispico/foundation-react/pages/dashboard/dashboardTab/WidgetWrapper";
import { EquipmentResourceInput } from "apollo-gen/globalTypes";
import { LOAD_DIAGNOSTIC_TROUBLE_CODE } from "./queries";
import { loadDiagnosticTroubleCode_diagnosticTroubleCodeService_findByFilter_results as DiagnosticTroubleCode } from 'apollo-gen/loadDiagnosticTroubleCode';
import moment from "moment";

const MAX_NUMBER_OF_RECORDS_IN_WIDGET = 10;
export class DiagnosticTroubleCodeWidgetState extends AbstractWidgetWithFilterState {
    entities = [] as DiagnosticTroubleCode[];
    totalCount = 0;
    modalOpen = undefined as string | undefined ;
}

export class DiagnosticTroubleCodeWidgetReducers<S extends DiagnosticTroubleCodeWidgetState = DiagnosticTroubleCodeWidgetState> extends AbstractWidgetWithFilterReducers<DiagnosticTroubleCodeWidgetState> { };

export type DiagnosticTroubleCodeWidgetProps = RRCProps<DiagnosticTroubleCodeWidgetState, DiagnosticTroubleCodeWidgetReducers> & AbstractWidgetWithFilterProps & WidgetProps;

export class DiagnosticTroubleCodeWidget extends AbstractWidgetWithFilter<DiagnosticTroubleCodeWidgetProps> {
    
        async loadData(entity: EquipmentResourceInput) {
            const ed = entityDescriptors["DiagnosticTroubleCode"];
            const crudSettingsCQId = ed.entityDescriptorSettings?.defaultCustomQuery ? Number(ed.entityDescriptorSettings?.defaultCustomQuery) : undefined;
            let cq: ClientCustomQuery = getCustomQueryFromSessionStorage("DiagnosticTroubleCode");
            const crudSettingsCQ = crudSettingsCQId ? await loadAndUpdateCustomQuery(crudSettingsCQId, true) : undefined;
            if (cq && cq.id !== -1) {
                if (crudSettingsCQ && cq.id === crudSettingsCQ.id) {
                    cq = crudSettingsCQ;
                }
            } else {
                cq = ed.getDefaultCustomQuery();
                if (crudSettingsCQ) {
                    cq = crudSettingsCQ;
                }
            }
            if (!cq) {
                return;
            }
            const { filter, sorts } = cq.customQueryDefinitionObject;
            const filterWithEntity = Filter.createComposedForClient(FilterOperators.forComposedFilter.and, [filter, Filter.createForClient("equipmentResource", FilterOperators.forEntityManyToOne.equals, entity.id)]);
            const { data } = await apolloClientHolder.apolloClient.query({
                context: {
                    showSpinner: false
                },
                query: LOAD_DIAGNOSTIC_TROUBLE_CODE,
                variables: FindByFilterParams.create().startIndex(0).pageSize(MAX_NUMBER_OF_RECORDS_IN_WIDGET).countMode(true).filter(Filter.eliminateDisabledFilters(filterWithEntity)).sorts(sorts)
            });
            this.props.r.setInReduxState({ entities: data.diagnosticTroubleCodeService_findByFilter.results, totalCount: data.diagnosticTroubleCodeService_findByFilter.totalCount });
        }

    protected componentDidUpdateInternal(prevProps: DiagnosticTroubleCodeWidgetProps) {
        super.componentDidUpdateInternal(prevProps);
        if (prevProps && !_.isEqual(prevProps.entityForAttachedDashboard, this.props.entityForAttachedDashboard)) {
            this.refresh();
        }
    }

    protected async refresh() {
        if (this.props.s.status !== WidgetStatus.NONE || !this.props.entityForAttachedDashboard) {
            return;
        }
        this.props.r.setInReduxState({ status: WidgetStatus.IN_PROGRESS });
        await this.loadData(this.props.entityForAttachedDashboard);
        this.props.r.setInReduxState({ status: WidgetStatus.DONE });
    }

    renderDiagnosticTroubleCode(diagnosticTroubleCode: DiagnosticTroubleCode) {
        const diagnosticTroubleCodeEntityDescriptor = entityDescriptors["DiagnosticTroubleCode"];
        const fields = Object.keys(diagnosticTroubleCodeEntityDescriptor.fields).filter(field => diagnosticTroubleCodeEntityDescriptor.getField(field).isInDefaultColumnConfig);
        const { id, name, code, timestamp } = diagnosticTroubleCode;
        return <div>
            <Label as="a" basic style={{ marginBottom: "0.3em", width: "100%" }} onClick={() => this.props.r.setInReduxState({ modalOpen: id })}>
                <Label circular size="tiny" color="blue">{code}</Label>&nbsp;
                {moment(timestamp).format(Utils.dateTimeWithSecFormat)} - {name}
            </Label>
            <ModalExt open={id === this.props.s.modalOpen} onClose={() => this.props.r.setInReduxState({ modalOpen: undefined })}>
                <Modal.Content>
                    <CrudViewer entity={diagnosticTroubleCode} entityDescriptor={diagnosticTroubleCodeEntityDescriptor} fields={fields} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.props.r.setInReduxState({ modalOpen: undefined })}>{_msg("general.close")}</Button>
                </Modal.Actions>
            </ModalExt>
        </div>;
    }

    renderMain() {
        const tabDescriptor = equipmentResourceEntityDescriptor.tabDescriptors.filter(td => td.oneToManyEntityName === "DiagnosticTroubleCode")[0];
        return <div style={{ overflowX: "hidden" }}>
            {this.props.s.entities.map(entity => this.renderDiagnosticTroubleCode(entity))}
            <p style={{ textAlign: "center" }}>{(this.props.s.totalCount > MAX_NUMBER_OF_RECORDS_IN_WIDGET
                ? _msg("general.displaying", this.props.s.entities.length, this.props.s.totalCount)
                : _msg("DiagnosticTroubleCode.displaying", this.props.s.entities.length)) + "."}
                <Link to={this.props.entityForAttachedDashboard?.id ? entityDescriptors["EquipmentResource"].getEntityEditorUrl(this.props.entityForAttachedDashboard.id) + tabDescriptor.tabRouterPane!.routeProps!.path : entityDescriptors["DiagnosticTroubleCode"].getEntityTableUrl()}>
                    {_msg("general.seeMore")}
                </Link>.</p>
        </div>;
    }
}

export const DiagnosticTroubleCodeWidgetRRC = ReduxReusableComponents.connectRRC(DiagnosticTroubleCodeWidgetState, DiagnosticTroubleCodeWidgetReducers, DiagnosticTroubleCodeWidget);
