import React from "react";
import { MissionStatus } from "./typeRenderers/constants";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { InitializationsForClient, TempSettingsXops } from "app";
import { GanttUtils } from "./GanttUtils";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { getNearestRowNumber } from "@crispico/react-timeline-10000";
import { flightEntityDescriptor, humanResourceEntityDescriptor, missionEntityDescriptor, taskEntityDescriptor } from "AppEntityDescriptors";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { AbstractGantt, GanttItem } from "./AbstractGantt";
import { Utils } from "@crispico/foundation-react";

const mapAssignTasksToResourcePagePropsFields: { [filed: string]: { [entityName: string]: string } } = {
    "equipmentResourceId": {
        "EquipmentResource": "id",
        "HumanResourceSchedule": "equipmentResource.id",
        "Mission2": "equipmentResource.id"
    },
    "humanResourceId": {
        "EquipmentResource": "humanResource.id",
        "HumanResourceSchedule": "humanResource.id",
        "Mission2": "humanResource.id"
    },
    "missionId": {
        "Mission2": "id"
    },
    "taskIds": {
        "Task": "id",
        "Flight": "tasks"
    }
}

export class DragAndDropContoller {

    private static _INSTANCE: DragAndDropContoller;
    private static _gantts: { [id: string]: AbstractGantt } = {};

    static get INSTANCE(): DragAndDropContoller {
        if (!DragAndDropContoller._INSTANCE) {
            DragAndDropContoller._INSTANCE = new DragAndDropContoller();
        }
        return DragAndDropContoller._INSTANCE;
    }

    private getFieldValue(fieldName: string, sourceEntityName: string, sourceEntity: any, destinationEntityName: string, destinationEntity: any) {
        let field;
        try {
            field = Utils.navigate(sourceEntity, mapAssignTasksToResourcePagePropsFields[fieldName][sourceEntityName], true, ".");
        } catch (e) {
            if (!field) {
                try {
                    field = Utils.navigate(destinationEntity, mapAssignTasksToResourcePagePropsFields[fieldName][destinationEntityName], true, ".");
                } catch {
                    // nop
                }
            }
        }
        return field;
    }

    private getGantt(ganttId: string): AbstractGantt {
        if (!DragAndDropContoller._gantts[ganttId]) {
            throw new Error("Gantt with id " + ganttId + " is not registered!");
        }
        return DragAndDropContoller._gantts[ganttId];
    }

    public getAcceptedType(entityUid: string) {
        switch (GanttUtils.fromEntityUid(entityUid).entityName) {
            case "Flight":
            case "Task":
                return ["Mission2", "HumanResourceSchedule", "EquipmentResource"];
            case "HumanResourceSchedule":
            case "EquipmentResource":
                return ["Flight", "Task"];
            case "Mission2":
                return ["Mission2", "Flight", "Task"];
            case "GanttTasks":
                return "Task";
            case "GanttResources":
                return "Mission2";
            default:
                return ["Mission2", "Flight", "Task", "HumanResourceSchedule", "EquipmentResource"];
        }
    }

    public registerGantt(ganttId: string, gantt: AbstractGantt) {
        if (DragAndDropContoller._gantts[ganttId]) {
            return;
        }
        DragAndDropContoller._gantts[ganttId] = gantt;
    }

    public canDrop(source: GanttItem, destination: GanttItem) {
        if (!source || !destination || !source.entityUid || !destination.entityUid) {
            return false;
        }
        const { props } = DragAndDropContoller.INSTANCE.getGantt(source.ganttId);

        const uidTokensDestination = GanttUtils.fromEntityUid(destination.entityUid);
        const destinationEntity = GanttUtils.findByUid(destination.entityUid, props.entities);

        if (uidTokensDestination.entityName == missionEntityDescriptor.name || uidTokensDestination.entityName == humanResourceEntityDescriptor.name) {
            const { currentOrganization, tempSettings } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
            if ((tempSettings as unknown as TempSettingsXops)?.tempUnitSettings?.length) {
                const hr = uidTokensDestination.entityName == missionEntityDescriptor.name ? destinationEntity.humanResource : destinationEntity;
                if (hr && currentOrganization && hr.unit.id != currentOrganization.id) {
                    if (!hr.borrowedToUnit || hr.borrowedToUnit.id != currentOrganization.id) {
                        return false;
                    }
                }
            }
            return uidTokensDestination.entityName == missionEntityDescriptor.name ? destinationEntity.status != MissionStatus.MISSION_STATUS_FINISHED : true;
        }
        return true;
    }

    public onDrop(source: GanttItem, destination: GanttItem) {
        if (!source || !destination || !source.entityUid || !destination.entityUid) {
            // if we don't have the information about entity(destination/source) nop.
            return;
        }
        const { props } = DragAndDropContoller.INSTANCE.getGantt(source.ganttId);
        const gantt = DragAndDropContoller.INSTANCE.getGantt(source.ganttId);
        const uidTokensSource = GanttUtils.fromEntityUid(source.entityUid);
        const uidTokensDestination = GanttUtils.fromEntityUid(destination.entityUid);
        const sourceEntity = GanttUtils.findByUid(source.entityUid, props.entities);
        const destinationEntity = GanttUtils.findByUid(destination.entityUid, props.entities);

        if (destination.entityUid.includes("Gantt")) {
            // when we drop on the gantt open editor
            const ganttBodyElement = document.querySelector(`.rct9k-id-${uidTokensDestination.id} .rct9k-grid`);
            if (!ganttBodyElement) {
                return;
            }
            const event = window.event as any;
            const { top, bottom, left, right } = ganttBodyElement.getBoundingClientRect();
            if (!(event.clientX < right && event.clientX > left && event.clientY > top && event.clientY < bottom)) {
                // nop if not in gantt body
                return;
            }
            let startTime = source.start;
            let endTime = source.end;
            let rowNumber = source.row;
            if (event) {
                startTime = gantt.getTimeAtPixel(event.clientX - JSON.parse(event.dataTransfer.getData("text/plain")).offsetX);
                // endTime = startTime + source.end - source.start;
                rowNumber = getNearestRowNumber(event.clientX, event.clientY, document.querySelector(uidTokensDestination.id + '') || document);
                // console.log(startTime, endTime, rowNumber)
            }
            AppMetaTempGlobals.history.push(entityDescriptors[uidTokensDestination.entityName].getEntityEditorUrl(uidTokensDestination.id));
            return;
        }
        // when drop on the entity(flight, task, mission, humanResource, equipmentResource) open assignation page.

        if (((uidTokensDestination.entityName == missionEntityDescriptor.name || uidTokensDestination.entityName == humanResourceEntityDescriptor.name || uidTokensDestination.entityName == equipmentResourceEntityDescriptor.name)
            && uidTokensSource.entityName != taskEntityDescriptor.name && uidTokensSource.entityName != flightEntityDescriptor.name) ||
            ((uidTokensDestination.entityName == taskEntityDescriptor.name && uidTokensDestination.entityName == flightEntityDescriptor.name)
                && uidTokensSource.entityName != missionEntityDescriptor.name && uidTokensSource.entityName != humanResourceEntityDescriptor.name && uidTokensSource.entityName != equipmentResourceEntityDescriptor.name)) {
            return;
        }
        let taskIds = DragAndDropContoller.INSTANCE.getFieldValue("taskIds", uidTokensSource.entityName, sourceEntity, uidTokensDestination.entityName, destinationEntity);
        let missionId = DragAndDropContoller.INSTANCE.getFieldValue("missionId", uidTokensSource.entityName, sourceEntity, uidTokensDestination.entityName, destinationEntity);
        let humanResourceId = DragAndDropContoller.INSTANCE.getFieldValue("humanResourceId", uidTokensSource.entityName, sourceEntity, uidTokensDestination.entityName, destinationEntity);
        let equipmentResourceId = DragAndDropContoller.INSTANCE.getFieldValue("equipmentResourceId", uidTokensSource.entityName, sourceEntity, uidTokensDestination.entityName, destinationEntity);

        if (Array.isArray(taskIds)) {
            taskIds = taskIds.map(t => t.id);
        } else if (taskIds) {
            taskIds = [taskIds];
        }

        gantt.props.r.updateAssignTasksToResourcePageProps(true, { humanResourceId, missionId, taskIds, equipmentResourceId, initialSelectedTaskIds: taskIds });
    }
}
