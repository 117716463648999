import gql from "graphql-tag";
import { ADDRESS_FOR_MAP } from "pages/Address/queries";
import { TERRITORY_FOR_MAP } from "pages/Territory/queries";

export const AIRPORT_FOR_MAP = gql`
    fragment AirportForMap on Airport {
        id code latitude longitude name
    }
`;

export const LOAD_TERRITORIES_FOR_REAL_TIME_MAP = gql`
    query loadTerritoriesForRealTimeMap($filter: FilterInput, $sorts: [SortInput], $coordinates:[Pair_Double_DoubleInput]) { 
        territoryService_findByRectangle(filter: $filter, sorts: $sorts, coordinates: $coordinates) {
            results  { ...TerritoryForMap } 
        }
    }
    ${TERRITORY_FOR_MAP}
`;

export const LOAD_ADDRESSES_FOR_REAL_TIME_MAP = gql`
    query loadAddressesForRealTimeMap($filter: FilterInput, $sorts: [SortInput], $coordinates:[Pair_Double_DoubleInput]) { 
        addressService_findByRectangle(filter: $filter, sorts: $sorts, coordinates: $coordinates) {
            results  { ...AddressForMap } 
        }
    }
    ${ADDRESS_FOR_MAP}
`;

export const LOAD_ADDRESSES_FOR_MAP_GO_TO_BUTTON = gql`
    query loadAddressesForMapGoToButton($params: FindByStringParamsInput) { 
        addressService_findByString(params: $params) {
            id name latitude longitude
        }
    }
`;

export const LOAD_EQUIPMENT_RESOURCES_FOR_MAP_GO_TO_BUTTON = gql`
    query loadEquipmentResourcesForMapGoToButton($params: FindByStringParamsInput) { 
        equipmentResourceService_findByString(params: $params) {
            id identifier plateNumber
        }
    }
`;

export const LOAD_AIRPORTS_FOR_MAP_GO_TO_BUTTON = gql`
    query loadAirportsForMapGoToButton($params: FindByStringParamsInput) { 
        airportService_findByString(params: $params) {
            id name code latitude longitude
        }
    }
`;

export const LOAD_AIRPORT_DATA_BY_ID = gql`
    query loadAirportDataById($id: Long) {
        airportService_findById(id: $id) {
            latitude longitude
        }
    }
`;

export const LOAD_TERRITORIES_FOR_MAP_GO_TO_BUTTON = gql`
    query loadTerritoriesForMapGoToButton($params: FindByStringParamsInput) { 
        territoryService_findByString(params: $params) {
            id name coordinates { a b }
        }
    }
`;

const FLIGHT_FOR_MAP_FRAGMENT = gql`
    fragment FlightForMap on Flight {
        id departure airline number date planeIdentifier parking { id name }
    }
`;
export const LOAD_FLIGHTS_FOR_TERRITORIES_FOR_MAP = gql`
    query loadFlightsForTerritoriesForMap($params: FindByFilterParamsInput) { 
        flightService_findByFilter(params: $params) {
            results  { ...FlightForMap } 
        }
    }
    ${FLIGHT_FOR_MAP_FRAGMENT}
`;
