import { apolloClient, ENT_SAVE, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { ganttAssignmentEntityDescriptor } from "AppEntityDescriptors";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, FormField, Header, Icon, Input, Message, Popup, Segment, TextArea } from "semantic-ui-react";
import { GET_PATHS_GA, SAVE_FROM_DISK_CSV_FILES } from "./queries";

const GANTT_ASSIGNMENT_FROM_FILES_ON_DISK_INPUT = "ganttAssignmentFromFilesOnDiskInput";
const DEFAULT_START_DATE = 1640995200000; // 2022-01-01T00:00:00Z
const DEFAULT_END_DATE = 1641081600000; // 2022-01-02T00:00:00Z

class GanttAssignmentFromFilesOnDiskPageState extends State {
    inputCsvFiles: string = "";
    outputCsvFiles: string = "";
    inputUpdateGanttAssignment: string = "";
    flightsStartDate: number = DEFAULT_START_DATE;
    flightsEndDate: number = DEFAULT_END_DATE;
    paths = [];
}

export class GanttAssignmentFromFilesOnDiskPageReducers<S extends GanttAssignmentFromFilesOnDiskPageState = GanttAssignmentFromFilesOnDiskPageState> extends Reducers<S> {

}

export class GanttAssignmentFromFilesOnDiskPage extends React.Component<RRCProps<GanttAssignmentFromFilesOnDiskPageState, GanttAssignmentFromFilesOnDiskPageReducers>> {

    async getPaths() {
        const result = (await (apolloClient.query({ query: GET_PATHS_GA }))).data["ganttAssignmentDevService_paths"];
        const paths = result ? result : [];
        this.props.r.setInReduxState({ paths });
    }

    componentDidMount() {
        const { inputCsvFiles, outputCsvFiles, inputUpdateGanttAssignment, flightsStartDate, flightsEndDate } = getGanttAssignmentFromFilesOnDiskInputToLocalStorage();
        this.props.r.setInReduxState({ inputCsvFiles, outputCsvFiles, inputUpdateGanttAssignment, flightsStartDate, flightsEndDate });
        this.getPaths();
    }

    async readFilesAndCreateAssignment() {
        const variables: { inputPaths: string[], outputPaths: string[], startDate: Date, endDate: Date, id?: number } = { inputPaths: this.props.s.inputCsvFiles?.replace(new RegExp(_.escapeRegExp(" "), "g"), "").split(","), outputPaths: this.props.s.outputCsvFiles?.replace(new RegExp(_.escapeRegExp(" "), "g"), "").split(","), startDate: new Date(this.props.s.flightsStartDate), endDate: new Date(this.props.s.flightsEndDate) };
        if (this.props.s.inputUpdateGanttAssignment !== "") {
            variables.id = Number(this.props.s.inputUpdateGanttAssignment);
        }
        const id = (await apolloClient.query({ query: SAVE_FROM_DISK_CSV_FILES!, variables })).data["ganttAssignmentDevService_saveFromDiskCsvFiles"];
        this.props.r.setInReduxState({ inputUpdateGanttAssignment: String(id) });
        setGanttAssignmentFromFilesOnDiskInputToLocalStorage(this.props.s.inputCsvFiles, this.props.s.outputCsvFiles, this.props.s.flightsStartDate, this.props.s.flightsEndDate, id);
        window.open(Utils.adjustUrlToServerContext("#" + ganttAssignmentEntityDescriptor.getEntityEditorUrl(id) + "/gantt"));
    }

    renderInput() {
        return <div className="flex-container">
            <div className="flex-container-row">
                <Header as="h4" style={{ marginTop: 0, flexGrow: "1" }}>{_msg("GanttAssignmentFromFilesOnDiskPage.input.header")}:</Header>
                <Popup position="top right" trigger={<Icon color="blue" size="large" name="question circle" />} content={_msg("GanttAssignmentFromFilesOnDiskPage.input.tooltip") + this.props.s.paths.join(",\n")} />
            </div>
            <TextArea onChange={(e, data) => this.props.r.setInReduxState({ inputCsvFiles: data.value as string })} rows={5} value={this.props.s.inputCsvFiles} />
        </div>
    }

    renderOutput() {
        return <div className="flex-container" style={{ marginTop: "1em" }}>
            <div className="flex-container-row">
                <Header as="h4" style={{ marginTop: 0, flexGrow: "1" }}>{_msg("GanttAssignmentFromFilesOnDiskPage.output.header")}:</Header>
                <Popup position="top right" trigger={<Icon color="blue" size="large" name="question circle" />} content={_msg("GanttAssignmentFromFilesOnDiskPage.output.tooltip") + this.props.s.paths.join(",\n")} />
            </div>
            <TextArea onChange={(e, data) => this.props.r.setInReduxState({ outputCsvFiles: data.value as string })} rows={5} value={this.props.s.outputCsvFiles} />
        </div>
    }

    render() {
        if (process.env.NODE_ENV !== "development" || !AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.isAdmin) {
            return <Redirect to={{ pathname: '/error', state: { from: undefined, headerMessage: _msg("error.insufficient.rights.title"), errorMessage: _msg("GanttAssignmentFromFilesOnDiskPage.invalidAccess") } }} />;
        }
        return <div className="flex-container flex-grow EntityEditorPage_container" style={{ margin: "1em" }}>
            <Segment className="flex-container flex-grow EntityEditorPage_segment">
                <Header as="h3" style={{ marginBottom: 0 }}><Icon name="chart bar" /> {_msg("GanttAssignmentFromFilesOnDiskPage.title")}</Header>
                <Message content={_msg("GanttAssignmentFromFilesOnDiskPage.message")} />
                <Form>
                    <FormField>
                        {this.renderInput()}
                    </FormField>
                    <FormField>
                        {this.renderOutput()}
                    </FormField>
                    <FormField>
                        <Header as="h4">{_msg("GanttAssignment.flightsStartDate.label")}:</Header>
                        <DatePickerReactCalendar value={moment(this.props.s.flightsStartDate)} onChange={(date) => this.props.r.setInReduxState({ flightsStartDate: date ? date.valueOf() : DEFAULT_START_DATE })} />
                    </FormField>
                    <FormField>
                        <Header as="h4">{_msg("GanttAssignment.flightsEndDate.label")}:</Header>
                        <DatePickerReactCalendar value={moment(this.props.s.flightsEndDate)} onChange={(date) => this.props.r.setInReduxState({ flightsEndDate: date ? date.valueOf() : DEFAULT_END_DATE })} />
                    </FormField>
                    <FormField>
                        <Header as="h4">{_msg("GanttAssignmentFromFilesOnDiskPage.updateGanttAssignment")}:</Header>
                        <Input type="number" onChange={(e, data) => this.props.r.setInReduxState({ inputUpdateGanttAssignment: data.value })} value={this.props.s.inputUpdateGanttAssignment} />
                    </FormField>
                </Form>
                <Button positive className="less-margin-top-bottom" onClick={async () => this.readFilesAndCreateAssignment()} >{_msg("GanttAssignmentFromFilesOnDiskPage.button." + (this.props.s.inputUpdateGanttAssignment === "" ? "create" : "update"))}</Button>
            </Segment>
        </div>;
    }
}

export const GanttAssignmentFromFilesOnDiskPageHOC = ReduxReusableComponents.connectRRC(GanttAssignmentFromFilesOnDiskPageState, GanttAssignmentFromFilesOnDiskPageReducers, GanttAssignmentFromFilesOnDiskPage);

export const ganttAssignmentFromFilesOnDiskPageUrl = "/AssignmentFromFilesOnDisk";
export const ganttAssignmentFromFilesOnDiskPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="assignmentFromFilesOnDiskPage"
        path={ganttAssignmentFromFilesOnDiskPageUrl}
        render={(props) => <GanttAssignmentFromFilesOnDiskPageHOC {...props} id="assignmentFromFilesOnDiskPage" />}
        permission={Utils.pipeJoin([ENT_SAVE, "GanttAssignment"])}
        computeRoute={computeRoute} />

export const ganttAssignmentFromFilesOnDiskPageMenuEntry = () => {
    return {
        id: "assignmentFromFilesOnDiskPage",
        content: _msg("GanttAssignmentFromFilesOnDiskPage.title"),
        to: ganttAssignmentFromFilesOnDiskPageUrl, exact: true, icon: "chart bar"
    }
};

function setGanttAssignmentFromFilesOnDiskInputToLocalStorage(inputCsvFiles: string, outputCsvFiles: string, flightsStartDate: number, flightsEndDate: number, inputUpdateGanttAssignment: string) {
    window.localStorage.setItem(GANTT_ASSIGNMENT_FROM_FILES_ON_DISK_INPUT, JSON.stringify({ inputCsvFiles, outputCsvFiles, flightsStartDate, flightsEndDate, inputUpdateGanttAssignment }));
}

function getGanttAssignmentFromFilesOnDiskInputToLocalStorage(): { inputCsvFiles: string, outputCsvFiles: string, flightsStartDate: number, flightsEndDate: number, inputUpdateGanttAssignment: string } {
    const item = window.localStorage.getItem(GANTT_ASSIGNMENT_FROM_FILES_ON_DISK_INPUT);
    if (item) {
        try {
            return JSON.parse(item);
        } catch { }
    }
    return { inputCsvFiles: "", outputCsvFiles: "", flightsStartDate: DEFAULT_START_DATE, flightsEndDate: DEFAULT_END_DATE, inputUpdateGanttAssignment: "" };
}
