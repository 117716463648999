import { EntityDescriptor, Utils, FieldDescriptor, EntityTableSimpleRRC } from "@crispico/foundation-react";
import { RRCProps, ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ChartConfig, ChartTab, ChartTabProps, ChartTabReducers, ChartTabState, getChartGenerationPeriodFromConfig } from "../ChartTab";
import moment from "moment";
import _ from "lodash";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { EntityFilterMode } from "@crispico/foundation-react/entity_crud/fieldEditors/FilterFieldEditor";

export const equipmentResourceUsageBasedOnNbMissionsConfigDescriptor = () => {
    return new EntityDescriptor({name: "EquipmentResourceUsageBasedOnNbMissions"}, false)
        .addFieldDescriptor({ name: "startDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeFormat }) })
        .addFieldDescriptor({ name: "endDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeFormat }) })
        .addFieldDescriptor({ name: "missionFilter", type: FieldType.filter, mode: EntityFilterMode.OBJECT, entityName: "Mission2"})
        .addFieldDescriptor({ name: "assignedToAtLeastNbMissions", type: FieldType.number})
}

export const equipmentResourceUsageBasedOnNbMissionsTableDescriptor = new EntityDescriptor({name: "EquipmentResourceUsageBasedOnNbMissionsOutput"}, true)
    .addFieldDescriptor({ name: "organization", type: FieldType.string })
    .addFieldDescriptor({ name: "equipmentResource", type: FieldType.string })
    .addFieldDescriptor({ name: "nbDaysInUse",  type: FieldType.number })
    .addFieldDescriptor({ name: "nbTotalMissions",  type: FieldType.number }) 

export interface Config extends ChartConfig {
    missionFilter: Filter;
    assignedToAtLeastNbMissions: number;
}

interface EquipmentResourceUsageBasedOnNbMissionsItem  {
    organization:{ id: number,  qualifiedName: string },
    equipmentResource: {id: number, identifier: string },
    nbDaysInUse: number,
    nbTotalMissions: number
}

export class CalculateEquipmentResourceUsageBasedOnNbMissionsPageState extends ChartTabState {
    showTableSpinner = false as boolean;
}

export class CalculateEquipmentResourceUsageBasedOnNbMissionsPageReducers<S extends CalculateEquipmentResourceUsageBasedOnNbMissionsPageState = CalculateEquipmentResourceUsageBasedOnNbMissionsPageState> extends ChartTabReducers<S>  {}

type Props = RRCProps<CalculateEquipmentResourceUsageBasedOnNbMissionsPageState, CalculateEquipmentResourceUsageBasedOnNbMissionsPageReducers> & ChartTabProps & { config: Config };

type LocalState = {
    equipmentResourceUsageBasedOnNbMissions: EquipmentResourceUsageBasedOnNbMissionsItem [],
}

export class CalculateEquipmentResourceUsageBasedOnNbMissionsPage extends ChartTab<Props, LocalState> {
    constructor(props: Props) {
        super(props); 
        this.state = {
            equipmentResourceUsageBasedOnNbMissions: []
        }
    }
   
    componentDidMount() {
        super.componentDidMount();
        if (this.props.entity.savedData) {
            this.setState({ equipmentResourceUsageBasedOnNbMissions: JSON.parse(this.props.entity.savedData) });
        } else {
            this.props.entity.config && this.generateChart(this.props.entity, JSON.parse(this.props.entity.config), this.props.editorDispatchers);
        }
    }

    render() {
        const props = this.props;
        const { startDate, endDate } = getChartGenerationPeriodFromConfig(props.config);
        return <>
            {this.renderTopBar(moment(startDate), moment(endDate))}
            {this.state.equipmentResourceUsageBasedOnNbMissions.length > 0 && !this.props.s.showTableSpinner?
                <EntityTableSimpleRRC id="entityTableSimple" entityDescriptor={equipmentResourceUsageBasedOnNbMissionsTableDescriptor}
                    entitiesAsParams={this.state.equipmentResourceUsageBasedOnNbMissions.map(eq => {
                        return { organization: eq.organization.qualifiedName, equipmentResource: eq.equipmentResource.identifier, nbDaysInUse: eq.nbDaysInUse, nbTotalMissions: eq.nbTotalMissions };
                })}/>
                : <h2>{_msg("EquipmentResourceUsageBasedOnNbMissions.noRecordsAvailable")}</h2>
            }
        </>
    }
}

export const CalculateEquipmentResourceUsageBasedOnNbMissionsPageRRC = ReduxReusableComponents.connectRRC(CalculateEquipmentResourceUsageBasedOnNbMissionsPageState, CalculateEquipmentResourceUsageBasedOnNbMissionsPageReducers, CalculateEquipmentResourceUsageBasedOnNbMissionsPage);