import { EntityDescriptor, Utils, apolloClientHolder, FieldDescriptor, EntityTableSimpleRRC, EntityTableSimple } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Button, Checkbox, Form, Icon, Input, Message, Modal, TextArea } from "semantic-ui-react";
import React from "react";
import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import _ from "lodash";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { entityDescriptors } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { AssociationFieldEditor } from '@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor';
import { GanttUtils } from '../GanttUtils';

export const options = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.timeFormat });

interface MessageEntity {
    id: number,
    date: string,
    sender: string,
    message: string,
    lastEditTime: string,
    lastEditBy: string,
    observation: string,
    status?: MessageStatus,
    userName?: string,
    answer?: string,
    driver?: string,
    driverId: number,
    type?: string,
}

interface SendMessageModal {
    to: { id: number, qualifiedName: string },
    message: string,
    isWarningDisplayed: boolean
}

enum ModalType { MESSAGE, OBSERVATION, CONFIRMATION }

export class GanttMessagesState extends State {
    data: number[] = [];
    showTableModal: boolean = false;
    showAllMessages: boolean = false;
    sendMessageModal: SendMessageModal = { isWarningDisplayed: false, to: { id: 0, qualifiedName: "" }, message: "" }
    showModal: ModalType | false = false;
    observationData: string = "";
    driverId?: number;
}

export class GanttMessagesReducers<S extends GanttMessagesState = GanttMessagesState> extends Reducers<S> {
}

type GanttMessagesProps = RRCProps<GanttMessagesState, GanttMessagesReducers> & {
    entities: { [entityName: string | number]: { [id: number]: any } } | undefined,
}

enum MessageStatus { READ = "read", UNREAD = "unread", NO_ANSWER = "No answer" };

const commentFd = new class extends FieldDescriptor { constructor() { super(); this.name = "comment"; this.type = "Comment"; } }
const organizationFd = new class extends FieldDescriptor { constructor() { super(); this.name = "organization"; this.type = "Organization"; } }

export class GanttMessages extends React.Component<GanttMessagesProps> {
    protected saveMissionEvent2Mutation!: DocumentNode;
    protected sendMessageMutation!: DocumentNode;
    protected ganttMessagesEntityDescriptor: EntityDescriptor;
    protected entityTableSimpleRef = React.createRef<EntityTableSimple>();

    constructor(props: GanttMessagesProps) {
        super(props);
        const that = this;
        this.ganttMessagesEntityDescriptor = new EntityDescriptor({ name: "GanttMessages" })
            .addFieldDescriptor({ name: "date", type: FieldType.date, additionalFieldRendererProps: options })
            .addFieldDescriptor({ name: "sender", type: FieldType.string })
            .addFieldDescriptor(new class extends FieldDescriptor {
                constructor() {
                    super();
                    this.name = "message";
                    this.type = FieldType.string;
                }

                renderField(entity: any): React.ReactNode {
                    let color = "";
                    let fontWeight: any = '';

                    if (entity.status === MessageStatus.UNREAD) {
                        color = "violet";
                        fontWeight = 'bold';
                    }

                    return <div style={{ fontWeight: fontWeight, color: color }}>{[`${entity.userName}:` + entity.message, <br />,
                    entity.driver + ": " + (entity.status === "No answer" ? "-" : entity.answer), <br />,
                    (entity.type === undefined ? "" : entity.type)]}</div>;
                }
            })
            .addFieldDescriptor({ name: "lastEditTime", type: FieldType.date, additionalFieldRendererProps: options })
            .addFieldDescriptor({ name: "lastEditBy", type: FieldType.string })
            .addFieldDescriptor(new class extends FieldDescriptor {

                constructor() {
                    super();
                    this.name = "read";
                    this.type = FieldType.string;
                }

                renderField(entity: any): React.ReactNode {
                    return <Checkbox checked={entity.status === MessageStatus.READ} onClick={() => that.clickReadHandler(entity)} />;
                }
            })
            .addFieldDescriptor(new class extends FieldDescriptor {
                constructor() {
                    super();
                    this.name = "observation";
                    this.type = FieldType.string;
                }

                renderField(entity: MessageEntity): React.ReactNode {
                    return <div className='flex-container-row flex-center justify-content-space-between gap5'>
                        {entity.observation}
                        <Button icon="signup" onClick={() => that.clickHandlerObservationButton(entity)} /></div>;
                }
            });

        this.processData = this.processData.bind(this);
        this.clickHandlerObservationButton = this.clickHandlerObservationButton.bind(this);
        this.closeMessagesModal = this.closeMessagesModal.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.closeObservationModal = this.closeObservationModal.bind(this);
        this.getEntityAt = this.getEntityAt.bind(this);
        this.initQueriesAndMutations();
    }

    protected initQueriesAndMutations() {
        this.saveMissionEvent2Mutation = gql(`
            mutation m($params: SaveParams_LongInput) {
                missionEvent2Service_save(params: $params) {
                    id, status
                }
            }
        `);
        this.sendMessageMutation = gql(`
            mutation sendMessage($answer: String, $fromMobile: Boolean, $message: String, $userid: Long, $humanResourceId: Long, $username: String) {
                missionEvent2Service_sendMessageToHumanResource(answer: $answer, fromMobile: $fromMobile, message: $message, userid: $userid, humanResourceId: $humanResourceId, username: $username)
            }
        `);
    }

    componentDidMount() {
        this.processData();
    }

    componentDidUpdate(prevProps?: GanttMessagesProps) {
        if (prevProps && (prevProps.s.driverId !== this.props.s.driverId)) {
            if (this.props.s.driverId) {
                const driver = this.props.entities?.["HumanResource"][this.props.s.driverId]
                this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, to: { id: this.props.s.driverId, qualifiedName: driver.firstName + ", " + driver.lastName } } })
            } else {
                this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, to: { id: 0, qualifiedName: '' } } })
            }
        }
        if (prevProps && (!_.isEqual(prevProps.entities, this.props.entities) || prevProps.s.showAllMessages != this.props.s.showAllMessages || prevProps.s.driverId != this.props.s.driverId)) {
            this.processData();
        }
    }

    protected markAllMessagesAsRead(): void {
        this.props.s.data.forEach(id => {
            const e = GanttUtils.findByUid(GanttUtils.toEntityUid("MissionEvent2", id), this.props.entities);
            if (e.status === MessageStatus.UNREAD) {
                this.clickReadHandler(e);
            }
        })
    }

    public getNumberUnreadMessages(): number {
        return this.props.s.data.filter(id => {
            const e = GanttUtils.findByUid(GanttUtils.toEntityUid("MissionEvent2", id), this.props.entities);
            return e.status === MessageStatus.UNREAD;
        }).length;
    }

    protected async saveMissionEvent(id: number, fieldsAndValues: {}): Promise<void> {
        await apolloClientHolder.apolloClient.mutate({
            mutation: this.saveMissionEvent2Mutation,
            variables: { params: { id, fieldsAndValues } },
            context: { showSpinner: true }
        });
    }

    protected async clickReadHandler(event: MessageEntity): Promise<void> {
        await this.saveMissionEvent(event.id, {
            status: event.status === MessageStatus.UNREAD ? MessageStatus.READ : MessageStatus.UNREAD,
            statusChangedAt: Utils.now().getTime()
        });
    }

    protected processData(): void {
        let data: number[] = [];
        const missions = this.props.entities?.["Mission2"] || {};
        Object.keys(missions).forEach(key => {
            const mission = GanttUtils.findByUid(GanttUtils.toEntityUid("Mission2", parseInt(key)), this.props.entities);
            if (!mission.humanResource || !mission.events) {
                return;
            }
            mission.events && mission.events.sort((e1: any, e2: any) => new Date(e2.date).getTime() - new Date(e1.date).getTime()).forEach((event: any) => {
                if (event.deleted !== false || !event.status || (!event.notificationCategory && event.descriptorType !== "DRIVER_MESSAGE") ||
                    (this.props.s.driverId ? this.props.s.driverId != event.driverId : false) || (this.props.s.showAllMessages ? false : event.status != MessageStatus.UNREAD)) {
                    return;
                }
                data.push(event.id);
            })
        })
        this.props.r.setInReduxState({ data });
    }

    protected clickHandlerObservationButton(event: any): void {
        this.props.r.setInReduxState({ showModal: ModalType.OBSERVATION, observationData: event["observation"] });
    }

    protected closeMessagesModal() {
        this.getNumberUnreadMessages() ? this.props.r.setInReduxState({ showModal: ModalType.CONFIRMATION }) : this.props.r.setInReduxState({ showTableModal: false });
    }

    protected async sendMessage() {
        const { sendMessageModal } = this.props.s;
        if (Utils.isNullOrEmpty(sendMessageModal.message)) {
            this.props.r.setInReduxState({ sendMessageModal: { ...sendMessageModal, isWarningDisplayed: true } });
        } else {
            const userid = AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.id;
            const username = AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.username;
            const allHumanResources = this.props.entities?.["HumanResource"];
            if (this.props.s.driverId) {
                const driver = this.props.entities?.["HumanResource"][this.props.s.driverId];
                await apolloClientHolder.apolloClient.mutate({
                    mutation: this.sendMessageMutation,
                    variables: { answer: "", fromMobile: true, message: sendMessageModal.message, userid, humanResourceId: driver.id, username: username }
                });
            } else {
                for (const hr in allHumanResources) {
                    if (sendMessageModal.to.qualifiedName === this.props.entities?.["HumanResource"][Number(hr)].unit?.name) {
                        await apolloClientHolder.apolloClient.mutate({
                            mutation: this.sendMessageMutation,
                            variables: { answer: "", fromMobile: true, message: sendMessageModal.message, userid, humanResourceId: hr, username }
                        });
                    }
                }
            }
            this.props.r.setInReduxState({ sendMessageModal: { ...sendMessageModal, isWarningDisplayed: false }, showModal: false });
        }
    }

    protected closeObservationModal() {
        let eventId = this.props.s.data.filter(id => id === this.entityTableSimpleRef.current!.getSelected())[0];
        this.saveMissionEvent(eventId, { statusChangedAt: Utils.now().getTime(), observation: this.props.s.observationData });
        this.props.r.setInReduxState({ showModal: false });
    }

    protected renderSendMessageModal(): JSX.Element {
        return <ModalExt size="tiny" open={this.props.s.showModal === ModalType.MESSAGE}
            onClose={() => this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, message: "", isWarningDisplayed: false }, showModal: false })} >
            <Modal.Header><Icon className="envelope outline" />{_msg("GanttMessages.sendMessage.label")}</Modal.Header>
            <Modal.Content className="flex-container">
                <Form className='wh100'>
                    <Form.Field>
                        <label>{_msg("GanttMessages.from.label")}</label>
                        <input type="text" defaultValue={AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.username} />
                    </Form.Field>
                    <Form.Field>
                        <label>{_msg("GanttMessages.to.label")}</label>
                        <AssociationFieldEditor isClearable={false} fieldDescriptor={organizationFd} innerEntityDescriptor={entityDescriptors[organizationFd.type]} isDisabled={this.props.s.driverId ? true : false} selectedValue={this.props.s.sendMessageModal.to}
                            onChange={(value) => value && this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, to: { id: value.id, qualifiedName: value.qualifiedName } } })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{_msg("GanttMessages.comments.label")}</label>
                        <AssociationFieldEditor fieldDescriptor={commentFd} innerEntityDescriptor={entityDescriptors[commentFd.type]}
                            onChange={value => Utils.isNullOrEmpty(value) ? this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, message: "" } }) : this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, message: value.text } })}
                        />
                    </Form.Field>
                    {this.props.s.sendMessageModal.isWarningDisplayed && <Message negative>
                        <Message.Header>{_msg("GanttMessages.warning.label")}</Message.Header>
                    </Message>}
                    <Form.Field>
                        <label id="message">{_msg("GanttMessages.message.label")}</label>
                        <TextArea rows={10} value={this.props.s.sendMessageModal.message}
                            onChange={(e, data) => this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, message: data.value as string } })} />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.sendMessage()} >{_msg("general.ok")}</Button>
                <Button onClick={() => this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, message: "", isWarningDisplayed: false }, showModal: false })} >{_msg("general.close")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    protected renderObservationModal(): JSX.Element {
        return <ModalExt size="tiny" open={this.props.s.showModal === ModalType.OBSERVATION} >
            <Modal.Header><Icon className="envelope outline" />{_msg("GanttMessages.message.label")}</Modal.Header>
            <Modal.Content className="flex-container">
                <Input defaultValue={this.props.s.observationData}
                    onChange={(e) => this.props.r.setInReduxState({ observationData: e.target?.value })} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={this.closeObservationModal} content={_msg("general.ok")} />
                <Button onClick={() => this.props.r.setInReduxState({ showModal: false })} content={_msg("general.cancel")} />
            </Modal.Actions>
        </ModalExt>
    }

    renderCloseConfirmationModal() {
        return (<ModalExt size="tiny" open={this.props.s.showModal === ModalType.CONFIRMATION} onClose={() => this.props.r.setInReduxState({ showModal: false })} severity={Severity.CONFIRMATION}>
            <Modal.Content>
                <Modal.Description as={"h3"}>
                    {_msg("GanttMessages.confirmation.label")}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.props.r.setInReduxState({ showModal: false, showTableModal: false })} primary>{_msg("general.yes")}</Button>
                <Button onClick={() => this.props.r.setInReduxState({ showModal: false })} primary>{_msg("general.no")}</Button>
            </Modal.Actions>
        </ModalExt>)
    }

    private getColumns() {
        return [{ name: "date", width: 75 }, { name: "sender", width: 150 }, { name: "message", width: 300 }, { name: "lastEditTime", width: 75 }, { name: "lastEditBy", width: 125 }, { name: "read", width: 75 }, { name: "observation", width: 300 }];
    }

    protected getEntityAt(index: number) {
        if (this.props.s.data.length < index) {
            return undefined;
        }
        const event = GanttUtils.findByUid(GanttUtils.toEntityUid("MissionEvent2", this.props.s.data[index]), this.props.entities);
        return {
            id: event.id,
            date: event.creationdate,
            sender: event.driver !== undefined ? event.driver : event.userName,
            message: event.message,
            lastEditTime: event.statusChangedAt,
            lastEditBy: event.statusChangedBy,
            observation: event.observation,
            status: event.status,
            userName: event.userName,
            answer: event.answer,
            driver: event.driver,
            driverId: event.driverId,
            type: event.type
        };
    }

    render() {
        return <>
            <ModalExt style={{ height: "525px" }} id="modalGanttMessages" size='large' closeIcon open={this.props.s.showTableModal}
                onClose={this.closeMessagesModal} >
                <Modal.Header><Icon className="envelope outline" />{_msg("GanttMessages.messages.label")}</Modal.Header>
                <Modal.Content className="flex-container gap5" style={{ height: "75%" }}>
                    <div className="flex-container-row flex-center justify-content-space-between">
                        <Checkbox label={_msg("GanttMessages.showAllMessages.label")} checked={this.props.s.showAllMessages}
                            onChange={() => this.props.r.setInReduxState({ showAllMessages: !this.props.s.showAllMessages })} />
                        <div>
                            <Button content={_msg("GanttMessages.markAllAsRead.label")} onClick={() => this.markAllMessagesAsRead()} disabled={!this.getNumberUnreadMessages()} />
                            <Button content={_msg("GanttMessages.sendMessage.label")} onClick={() => {
                                if (this.entityTableSimpleRef.current?.getSelected()) {
                                    let eventId = this.props.s.data.filter(id => id === this.entityTableSimpleRef.current!.getSelected())[0];
                                    const event = GanttUtils.findByUid(GanttUtils.toEntityUid("MissionEvent2", eventId), this.props.entities);
                                    this.props.r.setInReduxState({ sendMessageModal: { ...this.props.s.sendMessageModal, to: { id: eventId, qualifiedName: event.driver } } });
                                }
                                this.props.r.setInReduxState({ showModal: ModalType.MESSAGE })
                            }} />
                        </div>
                    </div>
                    <EntityTableSimpleRRC id="entityTableSimple_ganttMessages" ref={this.entityTableSimpleRef} entityDescriptor={this.ganttMessagesEntityDescriptor} getEntityAt={this.getEntityAt}
                        tableProps={{ rowsCount: this.props.s.data.length }}
                        columns={this.getColumns()} onDoubleClickItem={(entity: any, _) => this.clickHandlerObservationButton(entity)} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeMessagesModal} content={_msg("general.ok")} />
                </Modal.Actions>
            </ModalExt>
            {this.renderSendMessageModal()}
            {this.renderObservationModal()}
            {this.renderCloseConfirmationModal()}
        </>
    }
}

export const GanttMessagesRRC = ReduxReusableComponents.connectRRC(GanttMessagesState, GanttMessagesReducers, GanttMessages);
