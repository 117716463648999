import { EntityDescriptor, Utils } from "@crispico/foundation-react";
import { Color, XopsColorRegistry } from "pages/XopsColor/XopsColorRegistry";
import { Icon } from "semantic-ui-react";
import { AbstractItemRenderer, AbstractItemRendererProps } from "./AbstractItemRenderer";
import { MissionItemRenderer } from "./MissionItemRenderer";
import { GanttTasks } from "../GanttTasks";
import { IconItem } from "../ganttTypeRenderers";
import { FLIGHT_IMPORTER_SARIA_J } from "./constants";
import { taskEntityDescriptor } from "AppEntityDescriptors";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";

export enum InfoTaskMode {
    ARR_DPT = 0,
    ARR_DPT_IMM_P = 1
}

const flightIconsMap: { [key: string]: IconItem; } = {
    comment: {
        color: "purple",
        name: "comments",
        tooltip: ""
    },
    warning: {
        color: "yellow",
        name: "exclamation triangle",
        tooltip: ""
    },
    prepared: {
        color: "orange",
        name: "check",
        tooltip: ""
    },
    treated: {
        color: "green",
        name: "check",
        tooltip: ""
    },
    flightTreated: {
        color: "green",
        name: "check square outline",
        tooltip: ""
    },
    priority: {
        color: "red",
        name: "exclamation",
        tooltip: ""
    },
    verified: {
        color: "green",
        name: "check",
        tooltip: ""
    },
    modified: {
        color: "blue",
        name: "exclamation",
        tooltip: ""
    },
    flightOrder: {
        color: "black",
        name: "clock outline",
        tooltip: ""
    },
    alert: {
        color: "red",
        name: "exclamation",
        tooltip: ""
    },
    crewShuttleModified: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    },
    alreadyEngaged: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    },
    modifiedObjects: {
        color: "pink",
        name: "exclamation",
        tooltip: ""
    },
    modifiedPlaneIdentifier: {
        color: "red",
        name: "plane",
        tooltip: ""
    },
    efuelIcon: {
        color: "black",
        name: "question", // ! no icon for fuel 
        tooltip: ""
    },
    towedGreen: {
        color: "green",
        name: "truck",
        tooltip: ""
    },
    towedYellow: {
        color: "yellow",
        name: "truck",
        tooltip: ""
    },
    towedGrey: {
        color: "grey",
        name: "truck",
        tooltip: ""
    },
    sun: {
        color: "yellow",
        name: "sun",
        tooltip: ""
    },
    hangar: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    },
    delayedFlight: {
        color: "red",
        name: "exclamation",
        tooltip: ""
    },
    parkingChanged: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    }
};

const iconsMap: { [key: string]: IconItem; } = {
    comment: {
        color: "green",
        name: "comments",
        tooltip: ""
    },
    passengerWithDisabilities: {
        name: "wheelchair"
    }
}
type TaskItemRendererProps = {

} & AbstractItemRendererProps
export class TaskItemRenderer extends AbstractItemRenderer<TaskItemRendererProps> {

    protected get entityName(): string {
        return "Task";
    }

    getColor(): string {
        const task = this.propsCasted.entity;
        let color = task.taskType ? task.taskType.color : XopsColorRegistry.INSTANCE.get(Color.OBJECT_SPECIAL_COLOR);
        return color ? Utils.getRGBAColor(color) : "grey";
    }

    getStyle() {
        const task = this.propsCasted.entity;
        var style = {
            ...super.getStyle(),
            borderColor: this.getBorderColor(task),
            whiteSpace: 'nowrap'
        };
        return style;
    }

    private getBorderColor(task: any) {
        const missionType = task.missionType;
        if (missionType && missionType.color) {
            return missionType.color;
        }
        return "grey";
    }

    private getMissionStatusColor(task: any) {
        const mission = task ? task.mission : null;
        if (mission) {
            return MissionItemRenderer.getMissionColor(mission);
        } else {
            return "grey";
        }
    }

    static getIcons(task: any) {
        if (!task) {
            return [];
        }
        let icons: IconItem[] = [];

        task.comment && icons.push(iconsMap.comment);
        task.taskGroup.passengerWithDisabilities && icons.push(iconsMap.passengerWithDisabilities);

        return icons;
    }

    static getFlightIcons(flight: any) {
        if (!flight) {
            return [];
        }
        let icons: IconItem[] = [];

        flight.parking && flight.parking.hotspot && icons.push(flightIconsMap.sun);
        flight.priority && flight.importedBy === FLIGHT_IMPORTER_SARIA_J && icons.push(flightIconsMap.priority);
        flight.verified && icons.push(flightIconsMap.verified);
        flight.modifiedIcon && icons.push(flightIconsMap.modified);
        flight.crewShuttleModified && icons.push(flightIconsMap.crewShuttleModified);
        flight.alreadyEngaged && icons.push(flightIconsMap.alreadyEngaged);
        flight.modifiedObjects && icons.push(flightIconsMap.modifiedObjects);
        flight.modifiedPlaneIdentifier && icons.push(flightIconsMap.modifiedPlaneIdentifier);
        flight.towedStatus && icons.push(flight.towedStatus === "green" ? flightIconsMap.towedGreen : flight.towedStatus === "yellow" ? flightIconsMap.towedYellow : flightIconsMap.towedGrey);
        flight.comment?.trim().length > 0 && icons.push(flightIconsMap.comment);
        flight.alertCreateDefaultObjectsNoReset && icons.push(flightIconsMap.alert);
        flight.departure && flight.orderDate && icons.push(flightIconsMap.flightOrder);
        flight.inHangar && icons.push(flightIconsMap.hangar);
        flight.delayedFlight && icons.push(flightIconsMap.delayedFlight);
        flight.parkingChanged && icons.push(flightIconsMap.parkingChanged);

        return icons;
    };

    protected renderTaskMode(task: any) {
        const arvName = task.taskGroup?.departure ? task.taskGroup?.rotationFlight?.name : task.taskGroup?.name;
        const depName = task.taskGroup?.departure ? task.taskGroup?.name : task.taskGroup?.rotationFlight?.name;
        const planeIdentifier = task.taskGroup?.planeIdentifier;
        const parking = task.taskGroup?.parking?.name;
        let infoTask = "";
        if (this.propsCasted.ganttContextProps.infoTaskMode === InfoTaskMode.ARR_DPT) {
            infoTask = (arvName || '-') + "/" + (depName || '-');
        }
        if (this.propsCasted.ganttContextProps.infoTaskMode === InfoTaskMode.ARR_DPT_IMM_P) {
            infoTask = (arvName || '-') + "/" + (depName || '-') + (planeIdentifier ? (' - ' + planeIdentifier) : '') + ' ' + (parking ? (' - ' + parking) : '');
        }
        return <>
            {!this.propsCasted.ganttContextProps.hideAssignedTasks &&
                <span key="status"><Icon name="circle" style={{ color: this.getMissionStatusColor(task) }} /></span>
            }
            {TaskItemRenderer.getFlightIcons(task.taskGroup).map((icon, idx) => <span key={"flightIcon_" + idx}><Icon key={"flight" + idx} name={icon.name} color={icon.color} /></span>)}
            {TaskItemRenderer.getIcons(task).map((icon, idx) => <span key={"taskIcon_" + idx}><Icon key={"task" + idx} name={icon.name} color={icon.color} /></span>)}
            {infoTask}
        </>;
    }

    protected renderContent(): string | JSX.Element {
        const task = this.propsCasted.entity;
        if (this.propsCasted.ganttContextProps.isTaskMode) {
            return this.renderTaskMode(task);
        }
        return task && (
            <>
                <span><Icon name="circle" style={{ color: this.getMissionStatusColor(task) }} /></span>
                {this.renderFields(task, ["taskType.name"])}
            </>
        );
    }

}
