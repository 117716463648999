import { createSliceFoundation, DispatchersFrom, EntityDescriptor, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import React from "react";
import { ReactNode } from "react";
import { ChartEntityEditorPage, sliceEntityEditorPageChart } from "../chartEntityDescriptor";
import moment from "moment";
import { EntityFilterMode } from "@crispico/foundation-react/entity_crud/fieldEditors/FilterFieldEditor";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { HistoryCompare, sliceHistoryCompare } from "@crispico/foundation-react/pages/HistoryCompare/HistoryCompare";
import { Button, Icon, Segment } from "semantic-ui-react";
import { Chart } from "../ChartTab";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";

export const historyCompareConfigDescriptor = new EntityDescriptor({ name: "HistoryCompareReport" }, false)
    .addFieldDescriptor({ name: "startDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeFormat }) })
    .addFieldDescriptor({ name: "endDate", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeFormat }) })
    .addFieldDescriptor({ name: "entityName", type: FieldType.entityName })
    .addFieldDescriptor({ name: "filter", type: FieldType.filter, mode: EntityFilterMode.OBJECT, fieldForEntityName: "entityName" })
    .addFieldDescriptor({ name: "sorts", type: FieldType.sort, fieldForEntityName: "entityName" })
    .addFieldDescriptor({ name: "entityFields", type: FieldType.entityFields, fieldForEntityName: "entityName" })

interface HistoryCompareConfig {
    entityName: string;
    startDate?: string;
    endDate?: string;
    filter?: Filter;
    sorts?: Sort[];
    entityFields?: string;
}

export const sliceHistoryCompareTab = createSliceFoundation(class SliceHistoryCompareTab {
    initialState = {
        entities: [] as any,
        loading: false as boolean,
    }

    nestedSlices = {
        historyCompare: sliceHistoryCompare,
    }

    reducers = {
        ...getBaseReducers<SliceHistoryCompareTab>(this),
    }

    impures = {
        ...getBaseImpures<SliceHistoryCompareTab>(this),
        async save(entity: any, config: HistoryCompareConfig, editorDispatchers: DispatchersFrom<typeof sliceEntityEditorPageChart>, historyCompareRef: React.RefObject<HistoryCompare>) {
            const configCopy: HistoryCompareConfig = { ...config };
            configCopy.filter = Filter.eliminateDisabledFilters(this.getState().historyCompare.customQueryBar.customQuery?.customQueryDefinitionObject.filter!)!;
            configCopy.sorts = this.getState().historyCompare.customQueryBar.customQuery?.customQueryDefinitionObject.sorts!
            configCopy.entityFields = historyCompareRef.current!.auditGraphRef.current!.props.s.fields.join(",");
            configCopy.startDate = moment(historyCompareRef.current!.auditGraphRef.current!.props.s.currentStartDate).toDate().toISOString();
            configCopy.endDate = moment(historyCompareRef.current!.auditGraphRef.current!.props.s.currentEndDate).toDate().toISOString();
            editorDispatchers.setInReduxState({ config: configCopy })
            editorDispatchers.save(entity);
        }
    }
})

export type Props = PropsFrom<typeof sliceHistoryCompareTab> & {
    config: HistoryCompareConfig,
    entity: Chart,
    editor: ChartEntityEditorPage;
};

export class HistoryCompareTab extends React.Component<Props> {
    historyCompareRef = React.createRef<HistoryCompare>();

    constructor(props: Props) {
        super(props);
    }

    render(): ReactNode {
        if (!this.props.config.entityName) {
            return <Segment className="HistoryCompare_noData" size="large" inverted color="red"><Icon name="exclamation triangle" /> {_msg("HistoricalCompareReport.invalidConfigProperties")}</Segment>
        }
        const saveButton = <Button content={_msg("general.save")} primary floated="right" onClick={() => this.props.dispatchers.save(this.props.entity, this.props.config, this.props.editor.props.dispatchers, this.historyCompareRef)} />;
        return <HistoryCompare {...this.props.historyCompare} ref={this.historyCompareRef} dispatchers={this.props.dispatchers.historyCompare} entityName={this.props.config.entityName} additionalElements={[saveButton]}
            entityFields={this.props.config.entityFields} filter={this.props.config.filter} sorts={this.props.config.sorts} startDate={this.props.config.startDate} endDate={this.props.config.endDate} />;
    }
}
