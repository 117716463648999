import { TestsAreDemoAppWrapper } from "@famiprog-foundation/tests-are-demo";
import App from "app";
import ReactDOM from 'react-dom';
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";

function renderApp() {
    ReactDOM.render(<TestsAreDemoAppWrapper app={<App />} importTestsCallback={async () => await import("./appTests")} />, document.getElementById('root'));
}

async function initApp() {
    const wrapper = await import("pages/XopsMobile/XopsMobileWrapper");
    if (wrapper.XopsMobileWrapper.isDeviceMobileApp()) {
        ReactDOM.render(<Dimmer active page><Loader size='large'>{_msg("general.loading")}</Loader></Dimmer>, document.getElementById('root'));
        console.log("adding cordova.js!");
        // load cordova.js
        var cordovaScript = document.createElement('script');
        cordovaScript.setAttribute('src', 'cordova.js');
        // append the script tag to document head
        document.head.appendChild(cordovaScript);

        console.log("waiting for deviceready!");
        // Init app on device ready
        document.addEventListener("deviceready", () => {
            console.log("deviceready!");
            // at the end, render the app
            renderApp();
        }, false);
    } else {
        if (window.localStorage.getItem("flexMode") === "true") {
            // init flex mode
            window.sessionStorage.removeItem("global.currentOrganizationToFilterBy");
            prepareAppInFlexMode();
            // @ts-ignore
            await import("xops6-flex-mode.css");
        }
        renderApp();
    }
}

initApp();

declare global {
    var js_to_as: (args: any, jsCallback?: string) => void;
    var ganttTasksDisplayIntervalChange: (args: [number, number]) => void;
    var ganttTasksTableWidthChange: (widthPercent: number) => void;
    var setGanttTasksTableWidthAndDisplayInterval: (args: [number, number, number]) => void;
    var ganttDateChange: (date: number) => void;
    var ganttTasksFilter: (filter: string[]) => void;
    var ganttTasksInfoTaskMode: (mode: number) => void;
}

function prepareAppInFlexMode() {
    // the as <-> js communcation 
    const isChromium = navigator.vendor === "Google Inc.";
    const isEdge = /Edge/.test(navigator.userAgent);
    const isWebKit = !isChromium && !isEdge;
    const UNIQUE_ID = "A98DB973KWL8XP1LZ94KJ";

    const webViewANEWrapper = {
        postMessage: (message: any) => { // object
            if (isChromium) {
                // @ts-ignore
                webViewANE.postMessage(message);
            } else if (isEdge) {
                // @ts-ignore
                window.external.notify(JSON.stringify(message));
            } else {
                // @ts-ignore
                window.webkit.messageHandlers.webViewANE.postMessage(message);
            }
        }
    };

    globalThis.js_to_as = (args: any, jsCallback?: string) => {
        const messageToPost = {
            'functionName': 'js_to_as',
            'callbackName': jsCallback,
            'args': args
        };
        try {
            webViewANEWrapper.postMessage(messageToPost);
        } catch {
            // nop
        }
    }

    document.addEventListener('DOMContentLoaded', function () {
        if (isChromium) {
            // Important: CefSharp binding must now be performed async
            (async () => {
                // @ts-ignore
                await window.CefSharp?.BindObjectAsync("webViewANE", "bound").then((res) => {
                    if (res.Success) {
                        console.log("adding webViewANE was done successfully");
                    }
                });
            })();
        }
    });
    setTimeout(() => {
        const header = document.getElementById("root")!;
        const defaultDragData = {
            isDragging: false,
            draggedElement: undefined as HTMLElement | undefined,
            entityUid: 0,
            offsetX: 0,
            offsetY: 0,
            duration: 0,
        };
        let dragData = defaultDragData;
        header.addEventListener("dragstart", event => {
            if (event.target) {
                dragData.isDragging = true;
                try {
                    // we can sent in dataTranfer the entityUid(string)/json
                    // @ts-ignore
                    Object.assign(dragData, JSON.parse(event.dataTransfer.getData("text/plain")));
                } catch (e) {
                    console.error(e);
                }
                js_to_as(["dragStart", dragData.entityUid]);
                // create he the dragged element
                dragData.draggedElement = document.createElement("div");
                dragData.draggedElement.style.position = "fixed";
                dragData.draggedElement.style.opacity = '0.75';
                dragData.draggedElement.style.zIndex = "1000";
                dragData.draggedElement.style.overflow = "hidden";

                dragData.draggedElement.style.width = (event.target as HTMLElement).getBoundingClientRect().width + "px";
                dragData.draggedElement.style.height = (event.target as HTMLElement).getBoundingClientRect().height + "px";
                // for get the dom element of target we change the id to an unique id
                // @ts-ignore
                const id = event.target.id;
                // @ts-ignore
                event.target.id = UNIQUE_ID;
                const div = document.getElementById(UNIQUE_ID)!.cloneNode(true);
                // restore the target id
                // @ts-ignore
                event.target.id = id;
                div && dragData.draggedElement.appendChild(div);

                // add the dragged element into the DOM
                document.body.insertBefore(dragData.draggedElement, header);
                event.preventDefault();
            }
        });

        window.addEventListener("mouseup", event => {
            if (dragData.isDragging) {
                dragData.isDragging = false;
                // release the dragend
                js_to_as(["dragEnd"]);
                document.body.removeChild(dragData.draggedElement!);
                if (event.clientX > 0 && event.clientX < window.screen.availWidth && event.clientY > 0 && event.clientY < window.screen.availHeight) {
                    const detail = {
                        entityUid: dragData.entityUid,
                        clientX: event.clientX,
                        clientY: event.clientY,
                        offsetX: dragData.offsetX,
                        duration: dragData.duration
                    }
                    window.dispatchEvent(new CustomEvent("dropOnGantt", { detail: JSON.stringify(detail) }));
                }
                dragData = defaultDragData;
                event.preventDefault();
            }
        });

        window.addEventListener("mousemove", event => {
            if (dragData.isDragging) {
                // send drag event to as and move the dragged element
                js_to_as(["drag", event.clientX, event.clientY]);
                dragData.draggedElement!.style.left = event.clientX - dragData.offsetX + "px";
                dragData.draggedElement!.style.top = event.clientY - dragData.offsetY + "px";
                event.preventDefault();
            }
        });

        header.addEventListener("openEditor", event => {
            try {
                // first time we keep in event.detail only the entityUid as string,
                // now we keep a entityUid(string)/json for additional param on open editor
                // @ts-ignore
                let additionalParam = JSON.parse(event.detail);
                const { entityUid } = additionalParam;
                delete additionalParam.entityUid;
                js_to_as(["openEditor", entityUid, JSON.stringify(additionalParam)]);
            } catch {
                // @ts-ignore
                js_to_as(["openEditor", event.detail]);
                return;
            }
        });
    }, 1000);
}