import { Utils } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import _ from "lodash";
interface Db {
    [key: string]: {
        [id: number]: any;
    };
}
export class GanttUtils {

    static toEntityUid(entityName: string, id: number): string {
        return entityName + ":" + id;
    };

    static fromEntityUid(entityUid: string): { entityName: string, id: number } {
        if (!entityUid) {
            throw new Error();
        }
        const entityName = entityUid.split(":")[0];
        const id = Number(entityUid.split(":")[1]);
        return { entityName, id };
    }

    static find(entityName: string, field: string, value: any, miniDb: Db): any[] {
        if (miniDb) {
            const map = miniDb[entityName];
            if (map) {
                if (field === "id") { // shortcut to get it faster                 
                    return map[value] ? [GanttUtils.GanttProxy.get(entityName, map[value], miniDb)] : [];
                }
                return Object.keys(map).filter(key => {
                    const entity = map[Number(key)];
                    return Utils.navigate(entity, field, false, ".") === value;
                }).map(key => GanttUtils.GanttProxy.get(entityName, map[Number(key)], miniDb));
            } else {
                // for debug purposes
                // console.log("entities doesn't contain entityName = " + entityName);
            }
        }
        return [];
    }

    /**
     * Use this function while working with the gantt.
     * It's more efficient than a standard `denormalize` entity because it loads the sub-fields only when used!
     */
    static findByUid(entityUid: string, entities: any): any {
        const { id, entityName } = GanttUtils.fromEntityUid(entityUid);
        return GanttUtils.find(entityName, "id", id, entities)?.[0];
    }

    static GanttProxy = new class {

        public get(entityName: any, entity: any, miniDb: Db) {
            return this.getInternal(entityName, entity, miniDb);
        }

        private getInternal(entityName: any, entity: any, miniDb: Db, visitedEntityUids: { [key: string]: any } = {}, depth = 0, maxDepth = 5) {
            if (depth > maxDepth) {
                return undefined;
            }
            // According to the ECMAScript specification for proxies, if a property of the target object is non-writable and non-configurable,
            // the proxy must return the exact same value for that property !!!
            // In our case, if we have task.mission the proxy handler will return an error:
            // TypeError: 'get' on proxy: property 'mission' is a read-only and non-configurable data property on the proxy target but the proxy did not return its actual value
            // I think this happens because we keep the miniDb in a state; 
            // TODO: need to dig deeper to be sure!
            const entityCopy = _.clone(entity);

            const proxy = new Proxy(entityCopy, this.getHandler(entityName, miniDb!, visitedEntityUids, depth, maxDepth));

            return proxy;
        }

        private getOrCreate(type: string, entity: any, miniDb: Db, visitedEntityUids: { [key: string]: any }, depth: number, maxDepth: number) {
            if (entity === undefined) {
                return undefined;
            }
            const entityUid = GanttUtils.toEntityUid(type, entity.id) as any;
            if (!visitedEntityUids[entityUid]) {
                visitedEntityUids[entityUid] = this.getInternal(type, entity, miniDb, visitedEntityUids, depth + 1, maxDepth);
            }
            return visitedEntityUids[entityUid];
        }

        private getHandler(entityName: string, miniDb: Db, visitedEntityUids: { [key: string]: any } = {}, depth: number, maxDepth: number): ProxyHandler<any> {
            const that = this;
            return {
                get(target, property, receiver) {
                    let value = Reflect.get(target, property, receiver);

                    // added only because the property can have this kind of type, but, for the moment, I didn't see this case
                    if (typeof property === 'symbol') {
                        return value;
                    }
                    const fd = entityDescriptors[entityName]?.fields[property];
                    const type = fd?.getType();
                    if (fd?.typeIsOneToMany()) { // for OneToMany relation
                        // if the value exists, it indicates that we have a list of IDs. In that case, we iterate through the IDs and retrieve the corresponding proxy for each one
                        if (value) {
                            if (!Array.isArray(value)) {
                                throw new Error("OneToMany but the values isn't an Array! " + entityName + "." + property + " value=" + value);
                            }
                            return value.map(x => {
                                return that.getOrCreate(type, Object.assign({}, x, miniDb[type]?.[x.id]), miniDb, visitedEntityUids, depth, maxDepth);
                            });
                        } else {
                            // since we don't have a predefined list, we iterate through the miniDb to identify the opposite entities and return their proxies
                            const map = miniDb[type];
                            if (map) {
                                return Object.keys(map).filter(key => {
                                    const entity = map[Number(key)];
                                    return Utils.navigate(entity, fd.oneToManyOppositeField + ".id", false, ".") === (fd.oneToManyEntityField ? Utils.navigate(target, fd.oneToManyEntityField + ".id", false, ".") : target.id);
                                }).map(key => {
                                    return that.getOrCreate(type, map[Number(key)], miniDb, visitedEntityUids, depth, maxDepth);
                                });
                            } else {
                                // that type isn't in the miniDb
                            }
                        }
                    } else if (fd?.typeIsEntity()) { // for ManyToOne relation
                        if (value) {
                            return that.getOrCreate(type, Object.assign({}, value, miniDb[type]?.[value.id]), miniDb, visitedEntityUids, depth, maxDepth);
                        } else {
                            // not implemented
                            // should we fill the ManyToOne field if we have it in miniDb, but not as property in the entity? not sure
                        }

                    }
                    return value;
                }
            };
        };

    }
}
