import { FilterOperators } from "@crispico/foundation-gwt-js";
import { addEntityDescriptor, apolloClient, createSliceFoundation, DispatchersFrom, EntityDescriptor, EntityTablePage, EntityTablePageProps, FieldDescriptor, getBaseImpures, getBaseReducers, ITableActionParamForRun, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Modal, Form, Segment, Button, CheckboxProps, Checkbox, Icon, Dropdown, Menu, DropdownProps } from "semantic-ui-react";
import React, { ReactNode } from "react";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import gql from "graphql-tag";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { default as lodash } from "lodash";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { InitializationsForClient } from "app";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });

enum POSITION_DB_TYPE {
    REAL_TIME,
    LONG_STORAGE
}

export let positionEntityDescriptor: EntityDescriptor;
export let gpsLocationEntityDescriptor: EntityDescriptor;

export const sliceEntityTablePagePosition = createSliceFoundation(class Ext extends SliceEntityTablePage {
    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        telemetryEventMapping: [] as string[],
        markUnmappedFields: false,
        currentDb: POSITION_DB_TYPE.REAL_TIME
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        async getTelemetryEventMapping() {
            const loadOperationName = "telemetryEventMappingService_findByFilter";
            const customFields_findByFilter = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) { results { mappingFrom } } }`);
            const result = (await apolloClient.query({ query: customFields_findByFilter, variables: FindByFilterParams.create() })).data[loadOperationName];
            if (result.results.length > 0) {
                this.getDispatchers().setInReduxState({ telemetryEventMapping: result.results.map((x: { mappingFrom: string }) => x.mappingFrom.toUpperCase()) })
            }
        },

        getLoadOperationNameSuper: sliceEntityTablePageOnlyForExtension.impures.getLoadOperationName,
        getLoadOperationName(ed:EntityDescriptor) {
            if (this.getState().currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
                return `${lodash.lowerFirst(ed.name)}Service_findByFilterFromLongStorage`;
            }
            return this.getLoadOperationNameSuper(ed);
        }
    }
});

async function refresh(this: any, superRefresh: any) {
    if (!(this.props.telemetryEventMapping && this.props.telemetryEventMapping.length > 0)) {
        this.props.dispatchers.getTelemetryEventMapping();
    }
    await superRefresh();
}

type TelemetryFieldType = FieldRendererProps & { telemetryEventMapping: string[], markUnmappedFields: boolean }
class TelemetryFieldRenderer extends React.Component<TelemetryFieldType, { modalOpen: boolean }> {

    constructor(props: TelemetryFieldType)  {
        super(props);
        this.state = { modalOpen: false };
    }

    render() {
        const text = this.props.value ? String(this.props.value) : "";
        let field = <>{text}</>;

        if (this.props.markUnmappedFields) {
            field = <>{text.split(",").map(x => {
                if (x === "") return null;
                const fieldValue = x.split(":");
                if (this.props.telemetryEventMapping.includes(fieldValue[0].toUpperCase())) {
                    return <>{x},</>;
                } else {
                    return <><span style={{color: "red"}}>{fieldValue[0]}</span>:{fieldValue[1]},</>
                }
            })}</>
        }

        return <>
            <span onDoubleClick={(evt) => { this.setState({ modalOpen: true }); evt.stopPropagation() }} >{field}</span>
            <ModalExt open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.setState({ modalOpen: false })} size='tiny'>
                <Modal.Content>
                    <Form className="wh100">
                        <Segment style={{ overflowWrap: "break-word" }}>{field}</Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })}>Close</Button>
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

addAfterStartupRunnable(() => {
    const telemetryFieldDescriptor = new class extends FieldDescriptor {
        dispatchers!: DispatchersFrom<typeof sliceEntityTablePagePosition>;

        constructor() {
            super();
            this.name = "telemetry";
            this.type = "custom";
            this.sortable = false;
        }

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
            return <TelemetryFieldRenderer {...props} 
                        markUnmappedFields={this.dispatchers.getState().markUnmappedFields}
                        telemetryEventMapping={this.dispatchers.getState().telemetryEventMapping} />;
        }
    }();

    positionEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Position",
        miniFields: ["id"],
        icon: "point",
        defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today, ''),
        defaultSort: { field: "date", direction: "DESC" }
    })
        .removeFieldDescriptors("hasTelemetry")

        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "date", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        .addFieldDescriptor({ name: "dateReceived", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        .addFieldDescriptor({ name: "dateProcessed", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        .addFieldDescriptor({ name: "gpsProvider", type: FieldType.dropdown })
        .addFieldDescriptor({ name: "plateNumber", type: FieldType.string })
        .addFieldDescriptor({ name: "longitude", type: FieldType.double })
        .addFieldDescriptor({ name: "latitude", type: FieldType.double })
        .addFieldDescriptor(telemetryFieldDescriptor)
        .addFieldDescriptor({ name: "speed", type: FieldType.number })
        .addFieldDescriptor({ name: "accuracy", type: FieldType.double })
        .addFieldDescriptor({ name: "engineState", type: FieldType.string })
        .addFieldDescriptor({ name: "odometer", type: FieldType.double })
        .addFieldDescriptor({ name: "motorHour", type: FieldType.double })
    );

    sliceEntityTablePagePosition.setEntityDescriptor(positionEntityDescriptor);
    positionEntityDescriptor.infoTable.slice = sliceEntityTablePagePosition;
    (positionEntityDescriptor.infoTable.slice as SliceEntityTablePage).setOptions({ countMode: undefined });

    positionEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceEntityTablePagePosition>> {
        constructor(props: PropsFrom<typeof sliceEntityTablePagePosition>) {
            super(props);
            telemetryFieldDescriptor.dispatchers = this.props.dispatchers;
        }

        async refresh() {
            refresh.apply(this, [() => super.refresh()]);
        }

        private onCheckboxChange = (e: any, data: CheckboxProps) => {
            this.props.dispatchers.setInReduxState({ markUnmappedFields: data.checked });
            this.refresh();
        };

        private onDbChange = (e: any, data: DropdownProps) => {
            this.props.dispatchers.setInReduxState({ currentDb: data.value as POSITION_DB_TYPE });
            this.refresh();
        }

        protected preRenderButtons(params: any): Array<OverrideableElement> {
            const { hasLongStorage } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
            return [
                ...super.preRenderButtons(params),
                { elementType: Checkbox, props: { key: "markUnmappedFields", checked: this.props.markUnmappedFields, label: _msg("Position.markUnmappedFields"), onChange: this.onCheckboxChange, className: "tiny-margin-right" } },
                hasLongStorage && { element: <Dropdown key="xxxx" placeholder='Dropdown' options={[
                        { text: _msg("Position.realTimeDb"), value: POSITION_DB_TYPE.REAL_TIME},
                        { text: _msg("Position.archivedDb"), value: POSITION_DB_TYPE.LONG_STORAGE}
                    ]}  selection value={this.props.currentDb} onChange={ this.onDbChange } compact className="CustomQueryBar_sortFilterAddButton" /> }
            ];
        }

        protected onDoubleClickItem(entity: any) {
            if (this.props.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
                // show notification that archived cannot be shown in editor
                Utils.showGlobalAlert({ message: _msg("Position.archiveNoEditor"), severity: Severity.WARNING, title: _msg("general.warning") });
            } else {
                super.onDoubleClickItem(entity);
            }
        }

        protected isEditableCell(initialFormikValues: any, fieldDescriptor?: FieldDescriptor) : boolean {
            if (this.props.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
                // disable editing of fields
                return false;
            }
            return super.isEditableCell(initialFormikValues, fieldDescriptor);
        }

        protected renderCompactBarMenuModal() {
            if (this.props.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
                // show only the "Display as card" menu
                return <Menu vertical className="wh100" borderless>
                    <div className="flex-center EntityTablePage_compactMenu_additional">
                        <div className="small-margin-right" style={{ flexGrow: 1 }}>{_msg("ColumnConfig.displayAsCards.label")}</div>
                        <Checkbox checked={this.props.columnConfigDropdown.columnConfig?.displayAsCards} onChange={(e, data) =>
                            this.props.dispatchers.applyContextMenu({ displayAsCards: data.checked })
                        } />
                    </div>
                </Menu>;
            }
            return super.renderCompactBarMenuModal();
        }

        protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] { 
            if (this.props.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
                // replace the context menu with a text saying that archived position can not be edited
                return [
                    {
                        renderInMenu: (param) => <div className="flex-center EntityTablePage_compactMenu_additional">
                        <div className="small-margin-right" style={{ flexGrow: 1 }}>{_msg("Position.archiveNoEditor")}</div>
                    </div>
                }];
            } 
            return super.provideActionsForRow(actionParam);
        }

        renderMain() {
            if (this.props.oneToManyMode?.entity && !this.props.oneToManyMode.entity[this.props.oneToManyMode.entityField!]) {
                return <Segment size="large" inverted color="red" style={{ textAlign: 'center' }} ><Icon name="exclamation triangle" /> {_msg("Position.missingEntityField", _msg(this.props.oneToManyMode.entityDescriptor.name + ".label"), _msg(this.props.oneToManyMode.entityDescriptor.name + "." + this.props.oneToManyMode.entityField! + ".label"))}</Segment>
            }
            return super.renderMain()
        }
    };

    gpsLocationEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "GpsLocation",
        miniFields: ["id"],
        icon: "database",
        defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today, ''),
        defaultSort: { field: "date", direction: "DESC" }
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
        .addFieldDescriptor({ name: "date", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        .addFieldDescriptor({ name: "creationDate", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps, sortable: false })
        .addFieldDescriptor({ name: "gpsSystemName", type: FieldType.string, sortable: false })
        .addFieldDescriptor({ name: "longitude", type: FieldType.double, sortable: false })
        .addFieldDescriptor({ name: "latitude", type: FieldType.double, sortable: false })
        .addFieldDescriptor({ name: "telemetry", type: FieldType.text, sortable: false })

    );
    (gpsLocationEntityDescriptor.infoTable.slice as SliceEntityTablePage).setOptions({ countMode: undefined });
});