import { FieldDescriptor, Utils } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import moment from "moment";
import { ReactNode } from "react";
import { Button } from "semantic-ui-react";
import { AbstractGantt } from "./gantt/AbstractGantt";

export class MissionTotalTimeFieldDescriptor extends FieldDescriptor {

    constructor() {
        super();
        this.name = "missionsTotalTime";
        this.type = FieldType.string;
        this.clientOnly = true;
        this.enabled = false;
        this.sortable = false;
    }

    getLabel(getDefaultMeasurementUnitSymbol?: boolean | undefined, withMeasurementUnitSymbol?: boolean | undefined): string {
        return _msg("GanttResources.sortBy.missionTime");
    }

    protected renderFieldInternal(_: any, props: FieldRendererProps, entity: any): ReactNode {
        let duration = 0;
        if (!props || !props.entity || !props.entity.missions || props.entity.missions.length === 0) {
            return duration;
        }

        props.entity.missions.forEach((m: any) => {
            if (m.startTime && m.endTime) {
                duration += moment(m.endTime).toDate().getTime() - moment(m.startTime).toDate().getTime();
            }
        })

        return <div className="wh100 flex-center flex-justify-content-center flex-container">
            {Utils.formatDuration(duration)}
        </div>
    }
}

export class GanttMessagesFieldDescriptor extends FieldDescriptor {
    constructor() {
        super();
        this.name = "ganttMessages";
        this.type = FieldType.string;
        this.clientOnly = true;
    }

    getLabel(getDefaultMeasurementUnitSymbol?: boolean | undefined, withMeasurementUnitSymbol?: boolean | undefined): string {
        return _msg("GanttResource.ganttMessages");
    }

    protected renderFieldInternal(_: any, props: FieldRendererProps, entity: any): ReactNode {
        return <div className="wh100 flex-center flex-justify-content-center flex-container">
            <AbstractGantt.Context.Consumer>
                {(ganttProps) => {
                    return entity?.id ? <Button size="tiny" color="blue" icon="mail" onClick={(e) => {
                        ganttProps.showGanttMessageModal!(entity.id);
                        // need prevent event because it trigger row click and open the popup with info
                        e.preventDefault(); e.stopPropagation();
                    }} /> : null
                }}
            </AbstractGantt.Context.Consumer>
        </div>
    }
}
