import { FilterOperators } from "@crispico/foundation-gwt-js";
import {
    BigState, createSliceFoundation, EntityEditorPage, EntityTablePage, EntityTablePageProps, getBaseImpures, getBaseReducers,
    PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, SliceEntityTablePage,
    sliceEntityTablePageOnlyForExtension, Utils, EditMode
} from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { addEntityDescriptor, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityToTagFieldDescriptor } from "@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor";
import { InitializationsForClient, MapSettings } from "app";
import { RealTimeMapRRC } from "components/realTimeMap/RealTimeMap";
import { ReactNode } from "react";
//import { HistoricalMap, sliceHistoricalMap } from "../../components/HistoricalMap/HistoricalMap";
import { TerritoriesFieldDescriptor, TerritoriesFieldRenderer } from "./EquipmentResourceUtils";
import { EquipmentResourceUtils } from "./EquipmentResourceUtils";
import { VideoTab, sliceVideo } from "./VideoTab";
import { FieldDescriptorSettings, FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { uniqueId } from "lodash";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import _ from "lodash";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { AssociationFieldRenderer } from "@crispico/foundation-react/entity_crud/fieldRenderers/AssociationFieldRenderer";
import { MissionTotalTimeFieldDescriptor } from "pages/CommonFieldDescriptors";

export const MOBILE_DEVICE_TYPE: string = "MOBILE_DEVICE";
export enum TableMode { TABBED_MODE = "tabbedMode", SPLIT_MODE = "splitMode" };

const FIELDS_FROM_UNIT_DATA = ["label"];
const FIELDS_FROM_EQUIPMENT_RESOURCE_DATA = ["astusProvidedDriver", "fleetName", "fleetOwner"];

export const historyTrackEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "HistoryTrack",
    icon: "truck",
    defaultFilter: Filter.createForClient("startTime", FilterOperators.forDate.today, ''),
    defaultSort: { field: "startTime", direction: "DESC" }
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "startTime", type: FieldType.date,
        additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
        additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat }) })
    .addFieldDescriptor({ name: "endTime", type: FieldType.date,
        additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
        additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat }) })
    .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "startEngineHours", type: FieldType.double })
    .addFieldDescriptor({ name: "endEngineHours", type: FieldType.double })
    .addFieldDescriptor({ name: "startOdometer", type: FieldType.double })
    .addFieldDescriptor({ name: "endOdometer", type: FieldType.double })
);
(historyTrackEntityDescriptor.infoTable.slice as SliceEntityTablePage).setOptions({ countMode: undefined });

export const airportEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "Airport",
    miniFields: ["code"],
    icon: "plane"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "code", type: FieldType.string })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "longitude", type: FieldType.double })
    .addFieldDescriptor({ name: "latitude", type: FieldType.double })
);

export const telemetrySystemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "TelemetrySystem",
    miniFields: ["name"],
    icon: "bookmark"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
);

export const telemetryEventMappingEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "TelemetryEventMapping",
    miniFields: ["telemetrySystem.name", "mappingFrom"],
    icon: "truck"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "telemetrySystem", type: "TelemetrySystem" })
    .addFieldDescriptor({ name: "mappingFrom", type: FieldType.string })
    .addFieldDescriptor({ name: "mappingTo", type: FieldType.dropdown }, 
        new class extends FieldDescriptor { 
            customize() {
                const { fields } = EntityDescriptorForServerUtils.entityDescriptorsForServer["EquipmentResource"];
                const fieldIntervals: FieldInterval[] = Object.keys(fields).filter(key => fields[key].customField && key === fields[key].fid.toString())
                    .map(key => { return { from: fields[key].fid.toString(), label: fields[key].fieldLabel } as FieldInterval})
                this.fieldDescriptorSettings = { fieldIntervals } as FieldDescriptorSettings;
            }
        })
);

export const unitEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "Unit",
    miniFields: ["name"],
    icon: "truck"
})
    .doForFields(FIELDS_FROM_UNIT_DATA, fd => fd.filterable = false)
    .doForFields(FIELDS_FROM_UNIT_DATA, fd => fd.sortable = false)
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
);

export const equipmentTypeEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "EquipmentType",
    miniFields: ["name"],
    icon: "truck"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "icon", type: FieldType.string })
    .addFieldDescriptor({ name: "isNonMotorizedEquipment", type: FieldType.boolean })
    .addFieldDescriptor({ name: "qualificationType", type: "QualificationType" })
);

export const emissionRatingEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "EmissionRating",
    miniFields: ["name"],
    icon: "car"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
);

export const equipmentResourceEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "EquipmentResource",
    miniFields: ["identifier"],
    defaultSort: { field: "updated", direction: "DESC" },
    icon: "truck",
    hasAttachedDashboards: true
})
    .isInDefaultColumnConfig(true, "identifier", "astusId", "equipmentType", "equipmentModel", "unit", "organization", "available", "updated", "territoriesName", "detectedBy",
        "engineHours", "odometer", "plateNumber", "closestAddress", "imei", "territories", "enteredTerritories", "exitedTerritories")
    .removeFieldDescriptors("fieldsLastUpdate")
    .doForFields(FIELDS_FROM_EQUIPMENT_RESOURCE_DATA, fd => fd.filterable = false)
    .doForFields(FIELDS_FROM_EQUIPMENT_RESOURCE_DATA, fd => fd.sortable = false)
    // TODO by CS: nu am sters caci eram inainte de demo si mi-a fost frica sa nu stric; mai ales ca vad iconite
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "identifier", type: FieldType.string, additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLink: true }) })
    .addFieldDescriptor({ name: "astusId", type: FieldType.string })
    .addFieldDescriptor({ name: "equipmentType", type: "EquipmentType" })
    .addFieldDescriptor({ name: "organization", type: "Organization" })
    .addFieldDescriptor(new EntityToTagFieldDescriptor())
    .addFieldDescriptor({ name: "available", type: FieldType.boolean })
    .addFieldDescriptor({
        name: "updated",
        type: FieldType.date,
        icon: "database",
        additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
        additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat })
    })    
    .addFieldDescriptor({ name: "territoriesName", type: FieldType.string })
    .addFieldDescriptor({ name: "detectedBy", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "engineHours", type: FieldType.double, icon: "time" })
    .addFieldDescriptor({ name: "odometer", type: FieldType.double, icon: "flag checkered" })
    .addFieldDescriptor({ name: "plateNumber", type: FieldType.string, icon: "tag" })
    .addFieldDescriptor({ name: "closestAddress", type: "Address", icon: "map signs" })
    .addFieldDescriptor({ name: "imei", type: FieldType.string })
    .addFieldDescriptor({ name: "territories", enabled: false }, new TerritoriesFieldDescriptor("territories"))
    .addFieldDescriptor({ name: "enteredTerritories", enabled: false }, new TerritoriesFieldDescriptor("enteredTerritories"))
    .addFieldDescriptor({ name: "exitedTerritories", enabled: false }, new TerritoriesFieldDescriptor("exitedTerritories"))
    .addFieldDescriptor({ name: "vehicleId", type: FieldType.string })
    .addFieldDescriptor({ name: "engineState", type: FieldType.dropdown, icon: "key" })
    .addFieldDescriptor({ name: "status", type: FieldType.dropdown, icon: "play circle" })
    .addFieldDescriptor({ name: "operating", type: FieldType.boolean })
    .addFieldDescriptor({ name: "videoRecorderId", type: FieldType.string })
    .addFieldDescriptor(new MissionTotalTimeFieldDescriptor())
    .addFieldDescriptor({ name: "missions", type: FieldType.oneToMany("Mission2"), oneToManyOppositeField: "equipmentResource", enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true })
    
    // .addTabDescriptor({ oneToManyEntityName: "EquipmentUsageLog", oneToManyOppositeField: "vehicle" })
    // .addTabDescriptor({ oneToManyEntityName: "Checklist", oneToManyOppositeField: "vehicle" })

    //ATTENTION: if you change tabs, be sure to check getExtraTabPanes because the order changes for Audit and HistoricalMap
    .addTabDescriptor({ oneToManyEntityName: "Position", oneToManyOppositeField: "plateNumber", oneToManyEntityField: "plateNumber" })
    .addTabDescriptor({ oneToManyEntityName: "DetectionEvent", oneToManyOppositeField: "equipmentResource" })
    .addTabDescriptor({ oneToManyEntityName: "DiagnosticTroubleCode", oneToManyOppositeField: "equipmentResource", isVisible: (editor: EntityEditorPage) => {
        return editor.props.entity?.equipmentType ? !editor.props.entity.equipmentType.isNonMotorizedEquipment : true;
     } })
);

export const sliceEquipmentResourceTable = equipmentResourceEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
       // dashboard: undefined as Dashboard | undefined
    }

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
    }
}).setEntityDescriptor(equipmentResourceEntityDescriptor);
equipmentResourceEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceEquipmentResourceTable> & { mapSettings: MapSettings }> {

    constructor(props: EntityTablePageProps & PropsFrom<typeof sliceEquipmentResourceTable> & { mapSettings: MapSettings }) {
        super(props);
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        const that = this;
        let extraTabPanes = [...super.getExtraTabPanes(), {
            routeProps: { path: "/realTimeMap" },
            menuItemProps: { icon: "map outline", content: _msg("MapRealTime") },
            render: () => (<>
                {that.renderRealTimeMap(false, true, undefined)}
            </>)
        }];
        return extraTabPanes;
    }

    protected renderRealTimeMap(showGoToTableButton: boolean = true, withCustomQueryBar: boolean = true, rootFilter: Filter | undefined) {
        // Q: uniqueId("map-tab-" + EQUIPMENT_RESOURCE_TYPE) why is this neeeded ?
        // A: RealTimeMap contains MapContainerLeaflet which is RRC so uniqueId is needed because if the entity has dashboards
        // the component will be mounted once and once more after dashboards are loaded on changing order tabs
        const id = uniqueId("realTimeMap-tab-" + equipmentResourceEntityDescriptor.name);
        return <RealTimeMapRRC id={id} currentOrganizationToFilterBy={this.props.currentOrganizationToFilterBy}
            rootFilter={rootFilter} showCustomQueryBar={withCustomQueryBar} showGoToTableButton={showGoToTableButton} mapSettings={this.props.mapSettings} mapId={id} entityTypes={[equipmentResourceEntityDescriptor.name]} />
    }
}
equipmentResourceEntityDescriptor.infoTable.mapBigStateToProps = (state: BigState, props: any) => {
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
}

export const sliceEntityEquipmentResource = equipmentResourceEntityDescriptor.infoEditor.slice = createSliceFoundation(class Ext extends SliceEntityEditorPage {

    getFieldsToRequest() {
        return super.getFieldsToRequest().concat(["lastPointLatitude", "lastPointLongitude", "equipmentModel.image", "equipmentType.icon", "equipmentType.isNonMotorizedEquipment"]);
    }

    getAdditionalGraphQl() {
        const ed = this.entityDescriptor;
        const fields = Object.keys(ed.fields).filter(f => ed.fields[f].enabled && !ed.fields[f].clientOnly).map(name => "\"" + name + "\"").join(",");
        return `fieldsLastUpdate(whichFields: [${fields}])`;
    }

    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
        //historicalMap: sliceHistoricalMap,
        videoTab: sliceVideo
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
        async load(id: any) {
            // do this here to re-write queries with last version of customFields; see getFieldsToRequest()    
            // race condition for initIfNeeded and appMeta.getEntityDescriptorsForServer()   
            this.getSlice().initQueries();
            await this.loadSuper(id);
        }
    }
}).setEntityDescriptor(equipmentResourceEntityDescriptor);

equipmentResourceEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceEntityEquipmentResource> & { mapSettings: MapSettings }> {

    protected renderPageHeaderAdditionalContent() {
        const { props } = this;
        const { entityDescriptor } = props.dispatchers.getSlice();

        return props.entity?.id ? <div className="flex-container-row flex-center">
            <div className="EntityCrudHeader_white small-margin-right">
                {entityDescriptor.getField("equipmentType").renderField(props.entity, FieldDescriptor.castAdditionalFieldRendererProps(AssociationFieldRenderer, {
                    url: new ShareLinkLogic().createLink(false, entityDescriptor, Filter.enableAllFilters(Filter.create("equipmentType.name", FilterOperators.forEntityManyToOne.equals, props.entity.equipmentType?.name))),
                    showTooltip: true
                }))}
            </div>
            {entityDescriptor.getField("territories").renderField(props.entity, FieldDescriptor.castAdditionalFieldRendererProps(TerritoriesFieldRenderer, {
                linkToEquipmentResourceTableWithFilter: true,
                showTooltip: true
            }))}
        </div> : null
    }

    protected renderPageHeaderContent(lineWithFields: ReactNode, additionalContent?: ReactNode): ReactNode {
        const { props } = this;
        const { entityDescriptor } = props.dispatchers.getSlice();
        return <>{super.renderPageHeaderContent(lineWithFields, this.renderPageHeaderAdditionalContent())}
            {props.entity?.id ? <div className={"flex-container ER_EntityEditorPage_header_rightSide" + (this.props.entity?.equipmentModel ? (this.props.entity?.equipmentModel.image ? "" : " ER_EntityEditorPage_header_rightSide_noImage") : " ER_EntityEditorPage_header_rightSide_noModel")}>
                {this.props.entity?.equipmentModel?.image
                    ? <img src={this.props.entity?.equipmentModel?.image} alt={this.props.entity?.equipmentModel?.name} />
                    : <span></span>}
                {this.props.entity?.equipmentModel && entityDescriptor.getField("equipmentModel").renderField(props.entity, FieldDescriptor.castAdditionalFieldRendererProps(AssociationFieldRenderer, {
                    url: new ShareLinkLogic().createLink(false, entityDescriptor, Filter.enableAllFilters(Filter.create("equipmentModel.name", FilterOperators.forEntityManyToOne.equals, props.entity.equipmentModel?.name))),
                    asLabel: true, showTooltip: true
                }))}
            </div>
        : null}</> 
    }

    protected getHeaderIcon() {
        const props = this.props;
        return EquipmentResourceUtils.getImage(props.entity, false, '', { size: 'big' });
    }

    protected getExtraTabPanes() {
        let extraTabPanes = [...super.getExtraTabPanes()];
        extraTabPanes = extraTabPanes.slice(0, extraTabPanes.indexOf(null) + 1).concat(this.getAuditTabPanes()).concat(extraTabPanes.slice(extraTabPanes.indexOf(null) + 1));
        
        // extraTabPanes.push({
        //     routeProps: { path: "/historicalMap" },
        //     menuItemProps: { icon: "map outline", content: _msg("HistoricalMap.title") },
        //     render: () => <HistoricalMap {...this.props.historicalMap} dispatchers={this.props.dispatchers.historicalMap}
        //         entityId={this.props.entity?.id} plateNumber={this.props.entity?.plateNumber}
        //         mapSettings={this.props.mapSettings} rootReducerForPages={this.props.rootReducerForPages} />
        // });

        if (this.props.entity?.equipmentType && this.props.entity.equipmentType.isNonMotorizedEquipment) {
            // to add tabs for NME when needed
        } else {
            extraTabPanes.push({
                routeProps: { path: "/videoTab" },
                menuItemProps: { icon: "film", content: _msg("Video") },
                render: () => this.props.entity?.id ? <VideoTab {...this.props.videoTab} dispatchers={this.props.dispatchers.videoTab}
                equipmentResourceId={this.props.entity.id} mapSettings={this.props.mapSettings}/> : undefined
            }); 
        }
       

        return extraTabPanes;
    }

    protected getExtraTabPanesInternal() {
        if (this.props.mode === EditMode.ADD) {
            return null;
        } else {
            return this.getExtraTabPanes();
        }
    }
}

equipmentResourceEntityDescriptor.infoEditor.mapBigStateToProps = (state: BigState, props: any) => {
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
}

// HistoryGraphItemFactory.INSTANCE.addItemForEntityType(equipmentResourceEntityDescriptor, HistoryGraphMapItemRRC as any);
