import React from "react";
import { Image, Segment, Dimmer, Loader, Button, Icon, Popup, Label } from "semantic-ui-react";
import lodash from 'lodash';
import { Clock } from "@crispico/foundation-react/components/Clock/Clock";


enum VideoPlayerActions {
    Play,
    Replay,
    Muted
}

type Media = {
    cameraChannel: string;
    url: string;
}

export class GalleryMediaProps {
    data: { [key: string]: string; } | undefined;
    loading!: boolean;
    video?: boolean;
    extraInfo?: string
}

export class GalleryMediaState {
    media?: Media[] | undefined;
    mute?: boolean
    play?: boolean
    currentNbOfCamerasDisplayed: number = 2
}

export class GalleryMedia extends React.Component<GalleryMediaProps, GalleryMediaState> {

    constructor(props: GalleryMediaProps) {
        super(props);
        this.state = { media: this.getMedia(), mute: false, play: false, currentNbOfCamerasDisplayed: this.getNumberOfCameras() }
    }

    componentDidUpdate(prevProps: GalleryMediaProps, prevState: GalleryMediaState) {
        if (!lodash.isEqual(this.props.data, prevProps.data)) {
            this.setState({ media: this.getMedia() });
        }
        const nrOfCameras = this.getNumberOfCameras();
        if (nrOfCameras > prevState.currentNbOfCamerasDisplayed) {
            this.setState({ currentNbOfCamerasDisplayed: nrOfCameras });
        }
    }

    componentWillUnmount() {
        this.setState({ media: undefined })
    }

    getNumberOfCameras() {
        const media = this.getMedia();
        if (media.length < 2) {
            return 2;
        } else if (media.length > 8) {
            return 8;
        } else {
            return media.length + (media.length % 2);
        }
    }

    getMedia() {
        const mediaCopy: Media[] = []
        if (this.props.data) {
            Object.keys(this.props.data!).forEach((key) => {
                mediaCopy.push({ cameraChannel: key, url: this.props.data![key] })
            })
        }
        return mediaCopy
    }

    protected getOneGalleryElement(cameraChannel: number, url?: string, loading?: boolean) {
        let mediaElement;
        let noMediaMessage;
        const segmentDisplaying =  {width: '', height: ''};
        const labelStyle = { position: "absolute", transform: 'translateX(2.25em)',  zIndex: 2};
        if (this.props.video === true){
            mediaElement = <>
                <Label style={labelStyle} size='large' icon='video camera' content={cameraChannel} color='blue' ribbon />
                <video id={"video" + cameraChannel} className="wh100" style={{ objectFit: 'fill', position: 'relative'}} src={url} controls autoPlay={false} muted={false}/>
            </>
            noMediaMessage = _msg("Smartwitness.noContentAvailable")
        } else {
            mediaElement = <>
                <Label style={labelStyle} size='large' icon='video camera' content={cameraChannel} color='blue' ribbon />
                <Image className="wh100" src={url} style={{ objectFit: 'contain' }} ></Image>
            </> 
            noMediaMessage = _msg("Smartwitness.noSignal")
        }
        if (this.state.currentNbOfCamerasDisplayed <= 2) {
            segmentDisplaying.width = '50%'
            segmentDisplaying.height = '100%'
        } else {
            segmentDisplaying.width = String(Math.floor(100 / this.state.currentNbOfCamerasDisplayed * 2)) + '%';
        }

        return (
            <div className="GalleryMedia_galleryElement" style={segmentDisplaying}>
                <Segment style={{ height: '100%'}}>
                    {loading
                        ? <Dimmer active><Loader active>{_msg("general.loading")}</Loader></Dimmer>
                        : <>
                            {url
                                ? mediaElement
                                : <>
                                    <Label style={labelStyle} size='large' icon='video camera' content={cameraChannel} color='blue' ribbon />
                                    <h1 className="GalleryMedia_noMediaMessage">{noMediaMessage}</h1>
                                </>}
                        </>}
                </Segment>
            </div>
        )
    }

    protected getAllGallery() {
        const elements = [];
        for (var i = 1; i <= this.state.currentNbOfCamerasDisplayed; i++) {
            var media = this.state.media?.find((media => media.cameraChannel == i.toString()))
            if (media) {
                elements.push(this.getOneGalleryElement(i, media.url, false))
            } else {
                elements.push(this.getOneGalleryElement(i, undefined, this.props.loading))
            } 
        }

        return elements;
    }

    protected renderGallery() {
        return <>
            <Segment className="flex-wrap" style={{ height: '90%'}}>
                {this.getAllGallery()}
            </Segment>
        </>;
    }

    protected executeActionForAllVideoPlayers(action: VideoPlayerActions) {
        if (!this.state.media){
            return;
        }
        this.state.media.forEach((video => {
            var vid: any = document.getElementById("video" + video.cameraChannel);
            if (vid){
                switch (action){
                    case VideoPlayerActions.Play: {
                        this.state.play ?  vid.pause() : vid.play()
                        this.setState({play: !this.state.play})
                        break;
                    }
                    case VideoPlayerActions.Replay: {
                        vid.currentTime = 0;
                        vid.play()
                        this.setState({play: true})
                        break;
                    }
                    case VideoPlayerActions.Muted: {
                        this.setState({mute: !this.state.mute}, () => {vid.muted = this.state.mute})
                    }
                }
            }
        }))
    }

    protected renderButtonsForVideoPlayersActions() {
        return (
            this.props.video
                ?
                <div>
                    <Popup
                        trigger={
                            <Button icon={this.state.play ? 'pause' : 'play'} size='small' color={this.state.play ? 'blue' : 'red'}  onClick={() => this.executeActionForAllVideoPlayers(VideoPlayerActions.Play)} />
                        }
                        content={this.state.play ? _msg("Smartwitness.pauseAll") : _msg("Smartwitness.playAll")}
                        size='tiny'
                    />
                    <Popup
                        trigger={
                            <Button icon='redo alternate' size='small' color="green" onClick={() => this.executeActionForAllVideoPlayers(VideoPlayerActions.Replay)} />
                        }
                        content={_msg("Smartwitness.replayAll")}
                        size='tiny'
                    />
                    <Popup
                        trigger={
                            <Button icon={this.state.mute ? 'volume off' : 'volume up'} color='orange' size='small' onClick={() => this.executeActionForAllVideoPlayers(VideoPlayerActions.Muted)}/>
                        }
                        content={this.state.mute ? _msg("Smartwitness.soundOnAll") : _msg("Smartwitness.soundOffAll")}
                        size='tiny'
                    />
                </div>
                : null
        )
    }

    render() {
        return <>
            <div className="wh100">
                <Segment className="flex-wrap">   
                    {this.renderButtonsForVideoPlayersActions()}  
                    {this.props.extraInfo
                        ? <h4 className="margin-auto">{_msg("DrivingEvent.label") + ": " + this.props.extraInfo}</h4>
                        : <h4 className="margin-auto"><Clock format={"HH:mm:ss"} interval={500} /></h4>}
                </Segment>
                {this.renderGallery()}
            </div>
        </>;
    }
}