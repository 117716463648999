import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, StateFrom, PropsFrom, SliceEntityTablePage } from "@crispico/foundation-react";
import React from "react";
import { Header, Table, Grid, Button, Icon, Message, Segment, Container, Tab, Label, Menu } from "semantic-ui-react";
import { Checklist } from "apollo-gen/Checklist";
import { LOAD_CHECKLISTS } from "./queries";
import { legendsBottom, PieDatum, ResponsivePieExt, ResponsivePieProps } from "@crispico/foundation-react/components/nivoExt";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { loadChecklistTemplates } from "./ChecklistTemplates";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "@crispico/foundation-react/components/CustomQuery/CustomQueryBar";
import { checklistEntityDescriptor } from "./checklistEntityDescriptor";
import { Messages } from "@crispico/foundation-gwt-js";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

export enum Status {
    NOT_CALCULATED, IN_PROGRESS, FAIL, SUCCESS
}

export interface DataElement {
    name: string,
    values: DataElement[],
    value?: number
}

export const sliceChecklistReportTab = createSliceFoundation(class SliceChecklistReportTab {
    initialState = {
        templates: [] as any[],
        checklists: [] as any[],
        status: Status.NOT_CALCULATED as Status,
        data: [] as DataElement[],
        count: {} as any
    }

    nestedSlices = {
        customQueryBar: sliceCustomQueryBar
    }

    reducers = {
        ...getBaseReducers<SliceChecklistReportTab>(this),

        prepareData(state: StateFrom<SliceChecklistReportTab>) {
            state.data = []

            let data: any = {}
            let templateLabels: any = {}
            let fieldLabels: any = {}
    
            state.status = Status.FAIL
    
            state.templates.forEach((template: any) => {
                data = {...data, ...(template.label, {})}
                state.count = {...state.count, ...({[template.label]: 0})}
                let section: string
                template.fields.forEach((field: any) => {
                    if (field.mode === 'HEADER') {
                        section = field.label
                        data[template.label] = {...data[template.label], ...(field.label, {})}
                    }
                    else if (section && field.mode === 'FIELD' && field.valueType === 'multi-value' && field.values) {
                        fieldLabels = {...fieldLabels, ...({[field.name]: {section: section, label: field.label}})}
                        data[template.label][section] = {...data[template.label][section], ...(field.label, {})}
                        let values = {}
                        field.values.split(',').forEach((value: any) => {values = {...values, ...({[value]: 0})}})
                        data[template.label][section][field.label] = {...data[template.label][section][field.label], ...(values)}
                    }
                })
                templateLabels = {...templateLabels, ...({[template.name]: template.label})}
            })
    
            state.checklists.forEach(checklist => {
                let template = templateLabels[checklist.template]
                if (template !== undefined) {
                    state.count[template] ++
                    let keys = Object.keys(checklist.values)
                    keys.forEach((key: any) => {
                        let field = fieldLabels[key]
                        if (field) {
                            data[template][field.section][field.label][checklist.values[key].value] ++
                        }
                    })
                }
            })
    
            state.data = convertToListsOfLists(data)
    
            if (state.templates !== [] && state.checklists !== [] && state.data !== []) {
                state.status = Status.SUCCESS
            }
        }
    }

    impures = {
        ...getBaseImpures<SliceChecklistReportTab>(this),

        async loadData() {
            this.getDispatchers().setInReduxState({checklists: [], templates: [], status: Status.IN_PROGRESS})

            const templates = await loadChecklistTemplates()
            const page = (await apolloClient.query({ query: LOAD_CHECKLISTS, variables: FindByFilterParams.create().filter(Filter.eliminateDisabledFilters(this.getState().customQueryBar.customQuery?.customQueryDefinitionObject.filter!)) })).data['checklistService_findByFilter']
            this.getDispatchers().setInReduxState({checklists: page.results.map((c: Checklist) => {if (c.values && c.template) return ({values: JSON.parse(c.values), template: c.template})})})
            
            const templateNames: Set<string> = new Set(page.results.map((r: Checklist) => r.template))
            this.getDispatchers().setInReduxState({templates: Array.from(templateNames).map((templateName: string) => {
                if (typeof templateName === 'string') return templates[templateName] })})
            this.getDispatchers().prepareData()
        }
    }
})

type Props = PropsFrom<typeof sliceChecklistReportTab>

export class ChecklistReportTab extends React.Component<Props> {

    handleHeader = (field: DataElement) => {
        let jsxs: (JSX.Element | undefined)[] = []
        field.values.forEach(value => {
            jsxs.push(<Table.HeaderCell width="1" key={value.name}><Header as='h4'>{Messages.getInstance().maybeTranslateByUser(value.name)}</Header></Table.HeaderCell>);
        })
        return jsxs
    }

    handleValues = (field: DataElement) => {
        let jsxs: (JSX.Element | undefined)[] = []
        field.values.forEach(value => {
            jsxs.push(<Table.Cell key={value.name}>{value.value}</Table.Cell>)
        })
        return jsxs
    }

    renderField = (field: DataElement) => {
        return (
            <Table.Body key={field.name}>
                <Table.Row key={field.name}>
                    <Table.Cell key='label'>{Messages.getInstance().maybeTranslateByUser(field.name)}</Table.Cell>
                    {this.handleValues(field)}
                </Table.Row>
            </Table.Body>
        );
    }

    renderHeader = (field: DataElement) => {
        return (
            <Table.Header key='header'>
                <Table.Row key={'headerRow'}>
                    <Table.HeaderCell><Header as='h4' key='label'>{}</Header></Table.HeaderCell>
                    {this.handleHeader(field)}
                </Table.Row>
            </Table.Header>
        )
    }

    renderTableAndChart = (table: (JSX.Element | undefined)[], section: DataElement) => {
        return (
            [<Header as='h3' textAlign='center' attached='top' block key={section + '_header'}>{Messages.getInstance().maybeTranslateByUser(section.name)}</Header>,
            <Segment attached='bottom' key={section.name + '_grid'}><Grid stackable>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column key={'table'} width='11'><Table unstackable={true} compact='very'>{table}</Table></Grid.Column>
                    <Grid.Column key={'chart'} width='5'>{this.renderChart(section, 'section')}</Grid.Column>
                </Grid.Row>
            </Grid></Segment>]
        )
    }

    renderTabs = () => {
        let tabs: any[] = [];
        this.props.data.forEach(tab => {
            let tabElement: any[] = []
            if (tab.values.length > 1) tabElement.push(<Segment key='total'>{this.renderChart(tab, 'tab')}</Segment>)
            tab.values.forEach(section => {
                let table: (JSX.Element | undefined)[] = []
                table.push(this.renderHeader(section.values[0]))
                section.values.forEach(field => { table.push(this.renderField(field)) })
                tabElement.push(this.renderTableAndChart(table, section))
            })
            tabs.push({menuItem: <Menu.Item key={tab.name}>{Messages.getInstance().maybeTranslateByUser(tab.name)}<Label circular>{this.props.count[tab.name]}</Label></Menu.Item>, render: () => <Tab.Pane>{tabElement}</Tab.Pane>})
        });
        return <Segment className="less-margin-top-bottom wh100">
            {tabs.length > 0 ? <Tab panes={tabs} /> : null}
        </Segment>;
    }

    renderChart_section = (section: DataElement, sum: Map<string, any>) => {
        section.values.forEach(field => {
            field.values.forEach(value => {
                if (sum.has(value.name)) { sum.set(value.name, sum.get(value.name) + value.value) }
                else { sum.set(value.name, value.value) }
            })
        })
        return sum
    }

    renderChart = (element: DataElement, mode: string) => {
        let pieData: PieDatum[] = []
        let sum = new Map<string, any>()

        if (mode === 'tab') {
            element.values.forEach(section => {
                sum = this.renderChart_section(section, sum)
            })
        } else {
            sum = this.renderChart_section(element, sum)
        }

        Array.from(sum.keys()).forEach(s => {pieData.push({id: s, label: Messages.getInstance().maybeTranslateByUser(s), value: sum.get(s)})})

        if (mode === 'tab') {
            return <Container style={{ position: "static", height: 400}} key={element.name} textAlign='center'>
                <ResponsivePieChecklist data={pieData} margin={{ top: 20, right: 20, bottom: 70, left: 20 }} />
            </Container>
        } else {
            return <Container style={{ position: "static", height: 200}} key={element.name} textAlign='center'>
                <ResponsivePieChecklist data={pieData} />
            </Container>
        }
    }

    renderCalculateMessage = () => {
        if (this.props.status === Status.NOT_CALCULATED) {
            return(<p>{_msg('Checklist.report.notCalculated')}</p>)
        } else if (this.props.status === Status.IN_PROGRESS) {
            return(<p>{_msg('Checklist.report.inProgress')}</p>)
        } else if (this.props.status === Status.FAIL) {
            return(<p>{_msg('Checklist.report.fail')}</p>)
        } else if (this.props.status === Status.SUCCESS) {
            let sum = 0
            let keys: string[] = Array.from(Object.keys(this.props.count))
            keys.forEach(k => {sum += this.props.count[k]})
            return(<p>{_msg('Checklist.report.success', sum)}</p>)
        }
    }

    renderCalculateButton = () => {
        if (this.props.status === Status.IN_PROGRESS) {
            return <Button primary loading><Icon name='calculator'></Icon>{_msg("Checklist.report.calculate")}</Button>
        } else {
            return <Button primary onClick={() => this.props.dispatchers.loadData()}><Icon name='calculator'></Icon>{_msg("Checklist.report.calculate")}</Button>
        }
    }

    renderTopBar = () => {
        return <Segment className="less-padding less-margin-bottom flex-container-row flex-center">
            <div className="CustomQueryBar_div small-margin-right less-padding">
                <CustomQueryBar key="customQueryBar"
                    entityName={checklistEntityDescriptor.name} screen={checklistEntityDescriptor.name} {...this.props.customQueryBar}
                    dispatchers={this.props.dispatchers.customQueryBar} mode={CUSTOM_QUERY_BAR_MODE.TABLE} sortDisabled={true} />
            </div>
            <div className="tiny-margin-right">{this.renderCalculateButton()}</div>
            {this.renderCalculateMessage()}
        </Segment>;
    }
    
    render() {
        return <div className="flex-container flex-grow less-padding">
            {this.renderTopBar()}
            {this.renderTabs()}
        </div>;
    }

}

export const ResponsivePieChecklist = (props: ResponsivePieProps) => {
    return (<ResponsivePieExt
        arcLabel={'value'}
        arcLinkLabel={'label'}
        arcLinkLabelsDiagonalLength={10}
        margin={{ left: 20, right: 20, top: 20, bottom: 20 }}
        legends={legendsBottom as any}
        fit={true}
        {...props}
    />);
}

function convertToListsOfLists(obj: any) {
    let result: any[] = []
    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object') {
            result.push({name: key, values: convertToListsOfLists(obj[key])} as DataElement)
        } else {
            result.push({name: key, values: [], value: obj[key]})
        }
    })
    return result
}