import { EntityDescriptor, EntityTablePage, EntityTablePageProps, PropsFrom, SliceEntityTablePage, apolloClientHolder, createSliceFoundation, getBaseImpures, getBaseReducers, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import gql from "graphql-tag";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";

export enum AlertStatus { READ = 1, UNREAD = 0 };

export class AlertEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({ 
            name: "Alert",
            defaultFilter: Filter.createForClient("creationDate", FilterOperators.forDate.today),
            defaultSort: [{ field: "creationDate", direction: "DESC" }]
        });
    }
    protected customize() {
        this.addFieldDescriptor({ name: "creationDate", type: FieldType.date });
        this.addFieldDescriptor({ name: "status", type: FieldType.dropdown });
        this.isInDefaultColumnConfig(true, "id", "name", "creationDate", "status", "type", "canceled", "driverName", "subjectInfo", "message", "vehicleNo", "organization");

        const alertEntityDescriptor = this;
        const sliceAlertTablePage = alertEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

            nestedSlices = {
                ...sliceEntityTablePageOnlyForExtension.nestedSlices,
            }

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
                markAllAsRead: false
            }

            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),
            }

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
            }
        }).setEntityDescriptor(alertEntityDescriptor);

        type Props = EntityTablePageProps & PropsFrom<typeof sliceAlertTablePage>;
        alertEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<Props> {

            async refresh() {
                await super.refresh();
                this.hasUnreadAlert();
            }

            protected onMarkAllAsReadChecked = () => (e: any, data: CheckboxProps) => {
                this.props.dispatchers.setInReduxState({ markAllAsRead: data.checked });
                if (data.checked) {
                    this.entityTableSimpleRef.current?.getEntities().forEach(entity => (!entity.status || entity.status === AlertStatus.UNREAD) && this.markAlertAsRead(entity.id));
                }
                this.refresh();
            }

            protected hasUnreadAlert() {
                this.props.dispatchers.setInReduxState({ markAllAsRead: true });
                this.entityTableSimpleRef.current?.getEntities().every(entity => {
                    if (!entity.status || entity.status === AlertStatus.UNREAD) {
                        this.props.dispatchers.setInReduxState({ markAllAsRead: false });
                        return false;
                    }
                    return true
                });
            }

            protected async markAlertAsRead(entityId: number) {
                await apolloClientHolder.apolloClient.mutate({
                     mutation: gql(`mutation m($params: SaveParams_LongInput) {
                        alertService_save(params: $params) { id, status }
                    }`),
                    variables:
                        { params: { id: entityId, fieldsAndValues: {"status": AlertStatus.READ}} },
                    context: { showSpinner: true }
                });
            }

            protected preRenderButtons(params: any): Array<OverrideableElement> {
                return [
                    ...super.preRenderButtons(params),
                    // @ts-ignore
                    { elementType: Checkbox, props: { key: "markAllAsRead", disabled: this.props.markAllAsRead, checked: this.props.markAllAsRead, label: _msg("GanttMessages.markAllAsRead.label"), onChange: this.onMarkAllAsReadChecked() }},
                ];
            }
        }
    }
}
