import { EntityDescriptor, ENT_READ, PrivateRoute, PrivateRouteProps, Utils, EntityEditorPage, PropsFrom, FieldDescriptor, OmitFieldsOfTypeFunction } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { missionEntityDescriptor } from "AppEntityDescriptors";
import { MissionsAwaitingForDriversPageHOC } from "./MissionsAwaitingForDriversPage";
import { MissionEventValidationPageRRC } from "./MissionEventValidationPage";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });
export class Mission2EntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.isInDefaultColumnConfig(true, "creationDate", "humanResource", "equipmentResource", "startTime", "endTime", "type", "comment", "currentState", "mobileDevice")
            .removeFieldDescriptors("humanResourceId", "equipmentResourceId", "inactivityTypeId", "mobileDeviceId", "dataString", "dataVersion", "day", "currentStopId")
            .addFieldDescriptor({ name: "status", type: FieldType.dropdown })
            .addFieldDescriptor({ name: "creationDate", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "startTime", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "endTime", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "tasks", type: FieldType.oneToMany("Task"),  enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true, oneToManyOppositeField: "mission" } as OmitFieldsOfTypeFunction<FieldDescriptor>)
            .addFieldDescriptor({ name: "events", type: FieldType.oneToMany("MissionEvent2"),  enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true, oneToManyOppositeField: "mission" } as OmitFieldsOfTypeFunction<FieldDescriptor>)
            .addFieldDescriptor({ name: "stops", type: FieldType.oneToMany("Stop2"),  enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true, oneToManyOppositeField: "mission" } as OmitFieldsOfTypeFunction<FieldDescriptor>)
            .addTabDescriptor({ oneToManyEntityName: "MissionEvent2", oneToManyOppositeField: "missionId", oneToManyEntityField: "id" })
        this.infoEditor.wrappedComponentClass = class Ext extends EntityEditorPage<PropsFrom<typeof this.infoEditor.slice>> {
            protected getExtraTabPanes() {
                const pannes = super.getExtraTabPanes()
                pannes.push({
                    routeProps: { path: "/eventsValidation" }, menuItemProps: { content: _msg("MissionEventValidationPage.title") },
                    render: () => <MissionEventValidationPageRRC id="missionEventValidation" entityName="Mission2" entityId={this.props.entity?.id} />
                })
                return pannes;
            }
        }
    }
}

export const MissionsAwaitingForDriversPageUrl = "/MissionsAwaitingForDrivers";
export const missionsAwaitingForDriversPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="missionsAwaitingForDriversPage"
        path={MissionsAwaitingForDriversPageUrl}
        render={(props) => <MissionsAwaitingForDriversPageHOC {...props} id="missionsAwaitingForDriversPage" />}
        computeRoute={computeRoute} />

export const missionsAwaitingForDriversPageMenuEntry = () => {
    return {
        id: "missionsAwaitingForDriversPage",
        content: _msg("MissionsAwaitingForDriversPage.title"),
        to: MissionsAwaitingForDriversPageUrl, exact: true, icon: "list",
        permission: Utils.pipeJoin([ENT_READ, missionEntityDescriptor.name])
    }
};
