import { Messages } from "@crispico/foundation-gwt-js";
import { Utils } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { CrudFormInEditor } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { CrudEditorPageRenderHeaderParams, EditMode, EntityEditorPage, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { Divider } from "antd";
import { appMeta } from "app";
import { FormikProps } from "formik";
import _ from "lodash";
import React from "react";
import { Button, Confirm, Container, Dropdown, Form, Grid, Header, Icon, Message, Modal, Popup, Segment, TextArea } from "semantic-ui-react";
import { loadChecklistTemplates } from "./ChecklistTemplates";

export const sliceChecklistEntityEditorPage = createSliceFoundation(class Ext extends SliceEntityEditorPage {
    
    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
        values: {} as any,
        template: undefined as any,
        templateName: undefined as string | undefined,
        templateNameChangeTo: undefined as string | undefined,
        templates: {} as any,
        selectorOpen: false,
        confirmOpen_change: false,
        confirmOpen_clear: false,
        options: [] as {key: string, value: string, label: string}[],
        commentOpen: false,
        commentId: undefined as string | undefined,
        dirty: false as boolean
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),

        handleSelect(state: StateFrom<Ext>, data: {templateName: string}) {
            if (state.templateName || state.templateName === data.templateName || Object.entries(state.values).length < 1) {
                state.templateName = data.templateName
                state.values = {}
                if (state.templateName) {
                    state.template = state.templates[state.templateName]
                    state.entity.template = state.templateName
                }
            } else {
                state.confirmOpen_change = true
                state.templateNameChangeTo = data.templateName
            }
            state.selectorOpen = false
            state.dirty = true
        },
    
        handleConfirm_change(state: StateFrom<Ext>) {
            state.confirmOpen_change = false
            state.templateName = state.templateNameChangeTo
            state.templateNameChangeTo = undefined
            state.values = {}
            if (state.templateName) {
                state.template = state.templates[state.templateName]
                state.entity.template = state.templateName
            }
            state.dirty = true
        },
    
        handleClear(state: StateFrom<Ext>) {
            if (Object.entries(state.values).length > 0) {
                state.confirmOpen_clear = true
            }
        },
    
        handleConfirm_clear(state: StateFrom<Ext>) {
            state.confirmOpen_clear = false
            state.values = {}
            state.dirty = true
        },
    
        handleInitial(state: StateFrom<Ext>, humanResource: any) {
            if (!state.entity) {
                return;
            }
            state.values = {}
            const keys = Array.from(Object.keys(state.templates))
            if (state.entity.values) {
                state.values = JSON.parse(state.entity.values)
            } else {
                state.values = {}
            }
            if (state.entity.template && state.templates[state.entity.template]) {
                state.templateName = state.entity.template
                state.template = state.templates[state.entity.template]
            } else {
                state.template = undefined
                state.templateName = undefined
            }
            let options: any[] = []
            keys.forEach((template: any) => {
                options.push(({ key: state.templates[template].name, value: state.templates[template].name, text: Messages.getInstance().maybeTranslateByUser(state.templates[template].label) }))
            });
            state.options = options
            if (humanResource && !state.entity.vehicle) {
                state.entity.vehicle = humanResource.vehicle;
            }
            state.dirty = false
        },
    
        handleButtonClick(state: StateFrom<Ext>, data: { name: string, value: any }) {
            if (!state.values[data.name]) {
                state.values[data.name] = { value: data.value, comment: "" }
            } else {
                state.values[data.name] = { ...state.values[data.name], ...({ value: data.value }) }
            }
            state.dirty = true
        },
    
        handleTextBoxChange(state: StateFrom<Ext>, data: { name: string, value: any }) {
            if (!state.values[data.name]) {
                state.values[data.name] = { value: undefined, comment: data.value.value }
            } else {
                state.values[data.name] = { ...state.values[data.name], ...({ comment: data.value }) }
            }
            state.dirty = true
        },

        closeTextBox(state: StateFrom<Ext>) {
            state.commentOpen = false
            state.commentId = undefined
        }
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures,
        ...getBaseImpures<Ext>(this),

        async init() {
            if (!Object.keys(this.getState().templates).length) {
                await loadChecklistTemplates().then(templates => this.getDispatchers().setInReduxState({templates: templates}))
            }
            this.getDispatchers().handleInitial(appMeta.getCurrentHumanResource());
        }
    }

    getFieldsToRequest() {
        return super.getFieldsToRequest().concat(["values"]);
    }

})

type Props = PropsFrom<typeof sliceChecklistEntityEditorPage>

export class ChecklistEntityEditorPage extends EntityEditorPage<Props> {
    constructor(props: Props) {
        super(props);
        const getTemplateName = () => this.props.templateName;
        const isADD = () => this.props.mode === EditMode.ADD;
        this.editorFormSimpleClass = class extends CrudFormInEditor {
            renderFieldEditor(field: string, formikProps: FormikProps<any>) {
                if ((field === 'user' || field === 'creationDate') && isADD()) return <></>
                if (field === 'template' || !getTemplateName()) return <></>
                const { entityDescriptor } = this.props;
                const fieldDescriptor = entityDescriptor.getField(field);
                return (<Grid.Column className="EntityEditorPage_grid_row_column">
                    <Form.Field key={fieldDescriptor.getFieldName()} disabled={!fieldDescriptor.enabled} data-cy={"field" + field}>
                        <label>{fieldDescriptor.getLabel()}</label>
                        {fieldDescriptor.renderFieldEditor(formikProps)}
                    </Form.Field>
                </Grid.Column>)
            }
        }
    }
    
    componentDidUpdate(prevProps: Props) {
        if (this.props.entity && (!prevProps.entity || !_.isEqualWith(prevProps.entity, this.props.entity))) {
            this.props.dispatchers.init()
        }
    }

    isMainDirty() {
        return this.props.dirty
    }

    protected async onSaveInternal() {
        const forSave: any = { ...this.props.entity }
        forSave['values'] = JSON.stringify(this.props.values)
        forSave['template'] = this.props.templateName
        forSave['user'] = appMeta.getCurrentUser()
        forSave['creationDate'] = Utils.now();
        this.props.dispatchers.save(forSave, true, ['template', 'values', 'user', 'creationDate', 'vehicle']);
    }

    renderHeader(params: CrudEditorPageRenderHeaderParams) {
        if (!this.props.templateName) {
            return <Message size='large'><p>
                {_msg('Checklist.template.select')}
            </p></Message>;
        }
        return super.renderHeader(params);
    }

    protected getContainerCssClasses() {
        return { outer: "EntityEditorPage_container", inner: this.props.template && this.props.template.buttonsUnderDescription ? "EntityEditorPage_segment" : "ChecklistEditorPage_segment" }
    }

    protected renderForm() {
        return <>
            {React.createElement(this.editorFormSimpleClass, this.getPropsForFormSimple())}
            <ChecklistValues {...this.props} />
        </>
    }
}

class ChecklistValues extends React.Component<Props> {
    handleComment(entry: any) {
        return <div style={{textAlign: this.props.template.buttonsUnderDescription ? 'center' : undefined}}><p><b>{_msg('Checklist.comment') + ':'}</b> {entry.comment}</p></div>
    }

    handleField(field: any, entry: any) {
        let row: (JSX.Element)[] = []
        if (field.valueType === 'multi-value') {
            let buttons: (JSX.Element)[] = []
            let comment: JSX.Element | undefined = undefined
            if (field.values) {
                field.values.split(',').forEach((value: any) => {
                    buttons.push(<Button type='button' className="ChecklistButton" toggle active={entry ? entry.value === value : false} key={value} onClick={e => this.props.dispatchers.handleButtonClick({ name: field.name, value: value })}>{Messages.getInstance().maybeTranslateByUser(value)}</Button>)
                });
                if (field.hasComment) {
                    const commentCompleted = entry && entry.comment && entry.comment.length > 0
                    buttons.push(<Button className="ChecklistButton" positive={commentCompleted ? true : false} icon={<Icon name='comment alternate'/>} type='button' key={'comment'} onClick={e => this.props.dispatchers.setInReduxState({commentOpen: true, commentId: field.name})}/>)
                    if (commentCompleted && this.props.template.buttonsUnderDescription) {
                        comment = this.handleComment(entry)
                    }
                }
            } else if (field.hasComment) {
                return <TextArea className='flex-grow-shrink-no-overflow ChecklistTextArea' value={this.props.values[field.name] ? this.props.values[field.name].comment : ''} onInput={(e: any) => this.props.dispatchers.handleTextBoxChange({ name: field.name, value: e.target.value })}/>;
            }

            if (buttons.length > 0) {
                if (this.props.template.buttonsUnderDescription) {
                    row.push(<Grid.Column key='buttons' className='ChecklistEditorPageGrid' width='16' textAlign='center'>{buttons}</Grid.Column>)
                } else {
                    row.push(<Grid.Column key='buttons' className='ChecklistEditorPageGrid' width='6' textAlign='center'>{buttons}</Grid.Column>)
                }
            }
            if (comment) {
                row.push(<Grid.Column key='comment' className='ChecklistEditorPageGrid' width='16' textAlign='center'>{comment}</Grid.Column>)
            }
        }
        return row
    }

    renderField(field: any) {
        const entry = this.props.values[field.name]
        if (this.props.template.buttonsUnderDescription) {
            return (<Grid.Row key={field.name}>{[<Grid.Column key='header' className='ChecklistEditorPageGrid' width='16' textAlign='center'><Header as='h4'>{Messages.getInstance().maybeTranslateByUser(field.label)}</Header></Grid.Column>,
                this.handleField(field, this.props.values[field.name])]}
            </Grid.Row>)
        } else {
            return (<>
                <Grid.Row key={field.name} verticalAlign='middle'>{
                    [<Grid.Column key='header' className='ChecklistEditorPageGrid' width={field.values ? '10' : '8'}>{[<Header as='h4'>{Messages.getInstance().maybeTranslateByUser(field.label)}</Header>, (field.values && field.hasComment && entry && entry.comment && entry.comment.length > 0) ? this.handleComment(entry) : null]}</Grid.Column>,
                    this.handleField(field, entry)]}
                </Grid.Row>
            </>)
        }
    }

    renderHeader(field: any) {
        return (<Header as='h3' attached='top' block textAlign='center' key={field.label}>
            {Messages.getInstance().maybeTranslateByUser(field.label)}
        </Header>)
    }

    renderTables() {
        if (this.props.templateName && this.props.template && this.props.template.fields) {
            let jsxs: (JSX.Element | undefined)[] = []

            jsxs.push(<Divider key='divider'/>, <Segment key='segment' clearing>
                <Container as='h3' fluid><b>{_msg('Checklist.template') + ':'}</b> {Messages.getInstance().maybeTranslateByUser(this.props.template.label)}
                    <Popup key="clear" position='top right' content={_msg('Checklist.clear.popup')} trigger={<Button type='button' compact icon size='small' floated='right' onClick={(e) => this.props.dispatchers.handleClear()}><Icon name='redo alternate' color='blue' /></Button>} />
                </Container></Segment>)

            let table: (JSX.Element)[] = []
            let section = -1
            for (let i = 0; i < this.props.template.fields.length; i++) {
                let field = this.props.template.fields[i]
                if (field.mode === 'HEADER') {
                    section++
                    if (i > 0) {
                        jsxs.push(<Segment attached='bottom' key={section}><Grid stackable divided='vertically'>{table}</Grid></Segment>)
                        table = []
                    }
                    jsxs.push(this.renderHeader(field))
                } else {
                    table.push(this.renderField(field))
                }
            }
            jsxs.push(<Segment attached='bottom' key={'last section'}><Grid stackable divided='vertically'>{table}</Grid></Segment>)
            return jsxs
        } else {
            return (<Message size='large'><p>
                {_msg('Checklist.template.select')}
            </p></Message>)
        }
    }

    render() {
        return (<>
            <Form.Field key='template' style={{paddingTop: '0.25rem'}}><label style={{fontSize: '13px'}}><b>{_msg('Checklist.template')}</b></label>
                <Dropdown disabled={this.props.entity.id} open={this.props.selectorOpen} fluid options={this.props.options} selection
                    value={this.props.templateName}
                    onChange={(event, data) => this.props.dispatchers.handleSelect({ templateName: data.value as string })}
                    onOpen={e => this.props.dispatchers.setInReduxState({ confirmOpen_change: false, confirmOpen_clear: false, selectorOpen: true })}
                    onClose={e => this.props.dispatchers.setInReduxState({ confirmOpen_change: false, confirmOpen_clear: false, selectorOpen: false })}
                />
            </Form.Field>
            <Confirm key='change'
                open={this.props.confirmOpen_change}
                header={_msg('Checklist.warning')}
                content={_msg('Checklist.change')}
                cancelButton={_msg('general.cancel')}
                confirmButton='Remove template (lose data)'
                onCancel={e => this.props.dispatchers.setInReduxState({ confirmOpen_change: false, confirmOpen_clear: false, selectorOpen: false })}
                onConfirm={e => this.props.dispatchers.handleConfirm_change()}
            />
            <Confirm key='clear'
                open={this.props.confirmOpen_clear}
                header={_msg('Checklist.warning')}
                content={_msg('Checklist.clear')}
                cancelButton={_msg('general.cancel')}
                confirmButton='Clear checklist'
                onCancel={e => this.props.dispatchers.setInReduxState({ confirmOpen_change: false, confirmOpen_clear: false, selectorOpen: false })}
                onConfirm={e => this.props.dispatchers.handleConfirm_clear()}
            />
            <ModalExt open={this.props.commentOpen} closeIcon={false} onClose={() => this.props.dispatchers.closeTextBox()}>
                <Modal.Header>{_msg('Checklist.comment.header')}</Modal.Header>
                <Modal.Content className='flex-container-row'><TextArea className='flex-grow-shrink-no-overflow ChecklistTextArea' rows={2} value={this.props.values[this.props.commentId as string] ? this.props.values[this.props.commentId as string].comment : ''} onInput={(e: any) => this.props.dispatchers.handleTextBoxChange({ name: this.props.commentId as string, value: e.target.value })}/></Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.props.dispatchers.closeTextBox()}>{_msg('general.ok')}</Button>
                    <Button negative onClick={() => {this.props.dispatchers.handleTextBoxChange({ name: this.props.commentId as string, value: '' }); this.props.dispatchers.closeTextBox()}} >{_msg('general.remove')}</Button>
                </Modal.Actions>
            </ModalExt>
            {this.props.templateName ? this.renderTables() : null}
        </>);
    }
}