import React from "react";
import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { MissionItemRenderer } from "./typeRenderers/MissionItemRenderer";
import { TaskItemRenderer } from "./typeRenderers/TaskItemRenderer";
import { GanttUtils } from "./GanttUtils";
import { AbstractGantt } from "./AbstractGantt";
import { GanttTasksMode } from "./GanttTasks";
import _ from "lodash";
import { AbstractItemRendererProps } from "./typeRenderers/AbstractItemRenderer";

export type IconItem = {
    name: SemanticICONS,
    color?: SemanticCOLORS,
    tooltip?: string;
};

export interface CommonItemProps {
    leftLabel?: string;
    middleLabel?: string;
    rightLabel?: string;
    icons?: IconItem[];
}

export class GanttTypeRenderer extends React.Component<any> {

    render() {
        const { entityUid } = this.props.item;
        if (!entityUid) {
            return <></>;
        }
        const uidTokens = GanttUtils.fromEntityUid(entityUid);
        return <AbstractGantt.Context.Consumer>
            {(ganttProps) => {
                let itemRendererClass = undefined;
                const ganttContextProps = _.omit(ganttProps, 'entities');
                switch (uidTokens.entityName) {
                    case "Task": {
                        itemRendererClass = TaskItemRenderer;
                        break;
                    }
                    case "Mission2": {
                        itemRendererClass = MissionItemRenderer;
                        break;
                    }
                }
                if (itemRendererClass) {
                    const rendererProps = {
                        ...this.props as any,
                        ganttContextProps: ganttContextProps,
                        entity: GanttUtils.findByUid(GanttUtils.toEntityUid(uidTokens.entityName, uidTokens.id), ganttProps.entities)
                    } as AbstractItemRendererProps;                   
                    return React.createElement(itemRendererClass as any, rendererProps);
                }
                return <></>;
            }
            }
        </AbstractGantt.Context.Consumer>
    }
}
