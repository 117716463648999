import { EntityDescriptor, EntityTablePage, EntityTablePageProps, createSliceFoundation, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, getBaseReducers, getBaseImpures, PropsFrom, BigState, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { ReactNode } from "react";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";

export class BackgroundProcessEntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.isInDefaultColumnConfig(true, "label", "user", "creationDate", "finishDate", "status", "result")
            .addFieldDescriptor(new class extends FieldDescriptor {
                name = "status";
                renderField(entity: any) {
                    return _msg("backgroundprocess.status." + entity.status);
                }
            })
            .addFieldDescriptor({ name: "result", type: FieldType.string, additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLink: true }) }, new class extends FieldDescriptor {
                protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
                    // to be left as a abackup in case the <a> component, does not route the code correctly
                    // return <Button onClick={() => window.open(Utils.adjustUrlToServerContext("graniteamf-tide/api/fileRequest/getFileFromBackgroundProcess/") + entity.id)}>{entity.result.substring(entity.result.lastIndexOf("/") + 1)}</Button>
                    return entity.result ? <a {...props} href={Utils.adjustUrlToServerContext("graniteamf-tide/api/fileRequest/getFileFromBackgroundProcess/") + entity.id}>{entity.result.substring(entity.result.lastIndexOf("/") + 1)}</a> : null;
                }
            })

        // as var because it's used in some inner functions
        const backgroundProcessEntityDescriptor = this;

        const sliceBackgroundProcessTablePage = backgroundProcessEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

            nestedSlices = {
                ...sliceEntityTablePageOnlyForExtension.nestedSlices,
            }

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
            }

            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),
            }

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
            }
        }).setEntityDescriptor(backgroundProcessEntityDescriptor);

        type Props = EntityTablePageProps & PropsFrom<typeof sliceBackgroundProcessTablePage>;
        this.infoTable.wrappedComponentClass = class extends EntityTablePage<Props> { }

        this.infoTable.mapBigStateToProps = (state: BigState, props: any) => {
            props.itemsHidedFromCell = ["add", "fileImport"];
        }
    }
}