import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Group, Timeline } from "@crispico/react-timeline-10000";
//@ts-ignore - apparently categoricalColorSchemes is not exported
import { categoricalColorSchemes } from "@nivo/colors";
//@ts-ignore - needed because Defs is not defined in @nico/core but it is in the js code ...
import { Defs, getRelativeCursor } from "@nivo/core";
import { BoxLegendSvg } from "@nivo/legends";
//@ts-ignore
import { Datum, PointSymbolProps, Serie, useSlices } from "@nivo/line";
import { Slider } from "antd";
import { ResponsiveLineChartOld, sliceResponsiveLineChartOld } from "components/HistoricalMap/ResponsiveLineChartOld";
//@ts-ignore d3-shape is dependency for @nivo/line
import { area } from "d3-shape";
import moment from 'moment';
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import React from "react";
import SplitPane from "react-split-pane";
import { Button, Checkbox, Dropdown, Form, Icon, Input, InputProps, Label, Popup, Segment } from "semantic-ui-react";
import { Column, Table } from "fixed-data-table-2";
export enum PointType { GPS_LOCATION, TRACK_START, TRACK_END, TASK }

export const TASKS = "tasks";
export const EQUIPMENT_USAGE_LOGS = "equipmentUsageLogs";

// 42 unique colors.
const historicalMapColors = [
    ...new Set([
        ...categoricalColorSchemes["category10"],
        ...categoricalColorSchemes["dark2"],
        ...categoricalColorSchemes["paired"],
        ...categoricalColorSchemes["accent"],
        ...categoricalColorSchemes["nivo"],
    ])
]

/**
 * Gets the color at index i from our color map (historicalMapColors) which is a concatenated
 * array of colors from nivo/d3. If the i is bigger than the size of array, a rollover is used 
 * to starts from the top again
 * @param i color number
 */
export function getHistoricalMapColor(i: number): string {
    if (i < historicalMapColors.length) {
        return historicalMapColors[i];
    } else {
        return historicalMapColors[i % historicalMapColors.length];
    }
}

// if there is nothing in state regarding these, we will add them automatically to checkbox and speed to dropdown
// since components from this are pushed to front of checkboxes, just put them here in reverse order
const defaultAvailableSeries = [
    { id: EQUIPMENT_USAGE_LOGS, label: _msg("HistoricalMap.equipmentUsageLog") },
    { id: TASKS, label: _msg("HistoricalMap.tasks") },
    { id: "speed", label: _msg("HistoricalMap.speed") },
];

interface BarGroup extends Group {
    color?: string | undefined
}

interface BarItem {
    /* Mandatory properties */
    start: any,
    end: any,
    row: number,
    key: number,
    /* Optional properties */
    style?: any,
    value: string
}

interface BarChartMapping {
    id: number,
    label: string,
    color: string | number,
    start?: number,
    end?: number,
    value?: number
}

interface SerieData {
    id: string; label: string, checked?: boolean, color?: any
}

export const PERCENTAGE_FOR_USAGE_LOGS_POSITION = 83.3;

type Props = {
    onToggleSeries?: (serieId: string, value: boolean) => void;
    onPointHover?: (points: any[] | undefined) => void;
    onClickOrDragPoint?: (points: any[] | undefined, drag: boolean) => void;
    getNameAndValue?: (data: any) => { nameSerie: string, valueSerie: number };
    // serieId is needed in case of multipleVehicles, because in this case the chartId = vehicleId
    // and positions are inside vehicle in tree
    isPointIdOnTrack?: (pointId: string, trackId: string, serieId: string) => boolean;
    animationStep?: number
    customButtons?: Array<any>;
};

export const sliceHistoricalMapChartComponent = createSliceFoundation(class SliceHistoricalMapChartComponent {
    initialState = {
        series: [] as Array<Serie>,
        availableSeries: [] as Array<SerieData>,

        telemetryFields: {} as { [key: string]: { id: string; label: string, color?: any } },
        isTelemetryFieldPopupOpen: false as boolean,

        currentScaleMaxValue: undefined as number | undefined,
        currentScaleMinValue: undefined as number | undefined,

        addDefaultAvailableSeries: true,
        filteredByTrack: undefined as any | undefined,
        hoverTrack: undefined as string | undefined,
        selectedPoints: [] as any[],
        currentPoints: [] as any[],

        animationInProgress: false as boolean,
        animationCurrentTime: undefined as number | undefined,

        chartType: "line" as "line" | "bar",

        scaleId: "speed" as string | undefined,
        barChartThreshold: 1 as number,
        mappingForStringValues: {} as any
    }

    nestedSlices = {
        responsiveLineChart: sliceResponsiveLineChartOld
    }

    reducers = {
        ...getBaseReducers<SliceHistoricalMapChartComponent>(this),

        changeScale(state: StateFrom<SliceHistoricalMapChartComponent>, newTelemetry: string) {
            // recomputes y for series based on newTelemetry (and computes the margins too)
            // algo:
            //  - find serie
            //      - find min/max
            //      - put y as serie[newTelemetry]
            //  - rest of series
            //      - find min/max
            //      - change y by formula
            //  - change scaleId, currentScaleMinValue, currentScaleMaxValue

            state.scaleId = newTelemetry;

            // TODO HM: SCALE ADJUSTING TEMPORARY DISABLED; modified the serie y axis, and we don't want that for bar charts
            // waiting for new specs v6.5 

            let serieFound = undefined;
            for (const serie of state.series) {
                if (serie.id === newTelemetry) {
                    serieFound = serie;
                    break;
                }
            }
            if (!serieFound || serieFound.data.length === 0) {
                return;
            }
            // find min max
            let max = serieFound?.data[0][newTelemetry];
            let min = serieFound?.data[0][newTelemetry];
            for (const dat of serieFound?.data!) {
                if (max < dat[newTelemetry]) {
                    max = dat[newTelemetry];
                }
                if (min > dat[newTelemetry]) {
                    min = dat[newTelemetry];
                }
            }
            // apply scaling on each series
            for (let i = 0; i < state.series.length; i++) {
                state.series[i].data = this._applyScaling(state.series[i], min, max, newTelemetry);
            }
            // update min/max           
            state.currentScaleMaxValue = max;
            state.currentScaleMinValue = min;
        },

        _applyScaling(serie: Serie, scaleMin: number, scaleMax: number, telemetry: string) {
            if (serie.id === TASKS) {
                // for TASKS no need to update
                return serie.data;
            }
            const { min, max } = this._getMinMax(serie.data, serie.id as string);
            return serie.data.map((x: Datum) => {
                if (serie.id === telemetry) {
                    x.y = x.y !== null ? x[telemetry] : x.y;
                } else if (serie.id === EQUIPMENT_USAGE_LOGS) {
                    x.y = x.y != null ? PERCENTAGE_FOR_USAGE_LOGS_POSITION * (scaleMax - scaleMin) / 100 + scaleMin : x.y;
                } else {
                    x.y = x.y !== null ? x.y = x[serie.id] * (scaleMax - scaleMin) / (max - min) + scaleMin : x.y
                }
                return x;
            });
        },

        _getMinMax(data: Datum[], telemetry: string) {
            let min = data[0][telemetry];
            let max = data[0][telemetry];
            for (let info of data) {
                if (min > info[telemetry]) {
                    min = info[telemetry];
                }
                if (max < info[telemetry]) {
                    max = info[telemetry];
                }
            }
            return { min: min, max: max };
        },

        _alreadyContainsSeries(series: Serie[], serieId: string) {
            for (let serie of series) {
                /*eslint-disable */
                if (serie.id == serieId) {
                    /*eslint-enable */
                    return true;
                }
            }
            return false;
        },

        populateAvailableSeries(state: StateFrom<SliceHistoricalMapChartComponent>, onlySpeed: boolean) {
            let i = 0;
            for (let das of defaultAvailableSeries) {
                if ((onlySpeed && das.id === "speed") || !onlySpeed) {
                    if (!this._alreadyContainsSeries(state.series, das.id)) {
                        state.availableSeries.push({
                            id: das.id,
                            label: das.label,
                            checked: false,
                            color: getHistoricalMapColor(i)
                        })
                        i++
                    }
                }
            }
        },

        onChartTypeChanged(state: StateFrom<SliceHistoricalMapChartComponent>, value: 'line' | 'bar') {
            state.chartType = value;
        },

        onChartBarThresholdChanged(state: StateFrom<SliceHistoricalMapChartComponent>, value: number) {
            state.barChartThreshold = value;
        },

        onMappingForStringValuesChanged(state: StateFrom<SliceHistoricalMapChartComponent>, value: string) {
            try {
                state.mappingForStringValues = JSON.parse(value);
            } catch (e) {
                console.log(e);
            }
        }
    }

    impures = {
        ...getBaseImpures<SliceHistoricalMapChartComponent>(this),

        _containsPoint(points: any[], serieId: string, pointId: string | undefined) {
            if (points && points.length > 0) {
                for (const info of points) {
                    if (info.serieId === serieId && (pointId === undefined || info.pointId === pointId)) {
                        return true;
                    }
                }
            }
            return false;
        },

        _removePoint(points: any[], serieId: string, pointId: string | undefined) {
            if (points && points.length > 0) {
                return points.filter(info => info.serieId !== serieId || (pointId !== undefined && info.pointId !== pointId));
            }
            return points;
        },

        computeBarChartData(series: any[], mapping: BarChartMapping[]): { groups: BarGroup[], items: BarItem[], startDate: number | undefined, endDate: number | undefined } {
            let barGroups: BarGroup[] = [];
            let barItems: BarItem[] = [];
            let min: number = Number.MAX_VALUE;
            let max: number = Number.MIN_VALUE;

            let rowNumber = -1;

            for (let s = 0; s < series.length; s++) {
                const serie = this.getState().series.find(ss => ss.id === series[s].id);
                if (!serie || serie.id === EQUIPMENT_USAGE_LOGS || serie.id === TASKS) {
                    continue;
                }
                rowNumber++;
                let index = 0;
                let currentMapping: any = undefined;
                let section: { id?: string, startDate?: number, endDate?: number, value?: any } = {};
                let sortedData = [...serie.data].sort((a: Datum, b: Datum) => a.x! < b.x! ? -1 : a.x! > b.x! ? 1 : 0);
                for (let i = 0; i < sortedData.length; i++) {
                    const point = sortedData[i];
                    if (point.y === null) {
                        if (section.id) {
                            section.endDate = point.x as number;
                            min = Math.min(min, section.startDate!);
                            max = Math.max(max, section.endDate!);
                            barItems.push({ key: index, row: rowNumber, start: moment(section.startDate!), end: moment(section.endDate!), value: section.value, style: { backgroundColor: currentMapping.color } });
                            section = {};
                            index++;
                        }
                        currentMapping = undefined;
                        continue;
                    }
                    let pointMapping = mapping.find(t => t.start !== undefined ? point.y! >= t.start! && point.y! < t.end! : point.y === t.value);
                    if (pointMapping === undefined) {
                        continue;
                    }
                    if (!currentMapping || pointMapping?.id !== currentMapping.id) {

                        if (section.id) {
                            section.endDate = point.x as number;
                            min = Math.min(min, section.startDate!);
                            max = Math.max(max, section.endDate!);
                            barItems.push({ key: index, row: rowNumber, start: moment(section.startDate!), end: moment(section.endDate!), value: section.value, style: { backgroundColor: currentMapping.color } });
                            section = {};
                            index++;
                        }
                        currentMapping = pointMapping;
                        section.id = "" + index;
                        section.startDate = point.x as number;
                        section.endDate = point.x as number;
                        section.value = currentMapping.label;
                    } else {
                        section.endDate = point.x as number;
                    }
                }
                if (section.id) {
                    min = Math.min(min, section.startDate!);
                    max = Math.max(max, section.endDate!);
                    barItems.push({ key: index, row: rowNumber, start: moment(section.startDate!), end: moment(section.endDate!), value: section.value, style: { backgroundColor: currentMapping.color } });
                }
                const serieInfo = series.find(s => s.id === serie.id);
                barGroups.push({ id: rowNumber, title: serieInfo.label, color: serieInfo.color });
            }
            return { groups: barGroups, items: barItems, startDate: min, endDate: max };
        },

        isScaleIdNumeric() {
            if (!this.getState().scaleId) {
                return false;
            }
            if (this.getState().scaleId === "speed" || this.getState().scaleId === "odometer" || this.getState().scaleId === "motorHour") {
                return true;
            }
            try {
                const type = equipmentResourceEntityDescriptor.getField(this.getState().scaleId!).type;
                return type === FieldType.number || type === FieldType.double;
            } catch (e) {
                return false;
            }
        }
    }
})

export class HistoricalMapChartComponent extends React.Component<PropsFrom<typeof sliceHistoricalMapChartComponent> & Props> {

    static defaultProps: Props = {
        animationStep: 30 * 1000 // 30 sec
    }

    slices = [] as any[];

    // keep the last xScale used by the chart, we need it to convert time to xCoord (slices) 
    private xScale: any;

    private sliderRef = React.createRef<any>();
    private animationTimer: number | undefined = undefined;

    constructor(props: PropsFrom<typeof sliceHistoricalMapChartComponent> & Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onAnimationBtnClick = this.onAnimationBtnClick.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceHistoricalMapChartComponent> & Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: PropsFrom<typeof sliceHistoricalMapChartComponent> & Props) {
        const props = this.props;

        if (this.xScale !== undefined && (!prevProps || prevProps.animationCurrentTime !== props.animationCurrentTime)) {
            const points = this.getPointsFor(this.xScale(props.animationCurrentTime));
            if (points === undefined) {
                return;
            }
            this.props.onClickOrDragPoint?.call(null, points, false);
            this.sliderRef.current?.setState({ value: props.animationCurrentTime });
        }
        if (!prevProps || (prevProps.responsiveLineChart.startTime !== props.responsiveLineChart.startTime || prevProps.responsiveLineChart.endTime !== props.responsiveLineChart.endTime)) {
            this.stopAnimationTimer();
            this.sliderRef.current?.setState({ value: props.responsiveLineChart.startTime, bounds: [props.responsiveLineChart.startTime, props.responsiveLineChart.endTime] });
            this.props.dispatchers.setInReduxState({ animationCurrentTime: props.responsiveLineChart.startTime });
        }
        if (!prevProps || prevProps.animationInProgress !== props.animationInProgress) {
            if (this.props.animationInProgress) {
                this.startAnimationTimer();
            } else {
                this.stopAnimationTimer();
            }
        }
    }

    componentWillUnmount() {
        this.slices = [];
        this.localDataToRecompute = {};
        this.stopAnimationTimer();
    }

    getPointSymbol = ({ size, color, borderWidth, borderColor, datum }: PointSymbolProps) => {
        if (this.props.selectedPoints && this.props.selectedPoints.length > 0
            && this.props.dispatchers._containsPoint(this.props.selectedPoints, datum.serieId, datum.id)) {
            return (<g>
                <circle fill={'#000'} r={size / 1.2} strokeWidth={0} stroke={borderColor} fillOpacity={0.4} />
                <circle fill={"#fff"} r={size / 2} strokeWidth={0} stroke={borderColor} />
                <circle r={size / 3} strokeWidth={0} stroke={borderColor} fill={'#000'} fillOpacity={0.80} />
            </g>)
        } else if (this.props.currentPoints && this.props.currentPoints.length > 0
            && this.props.dispatchers._containsPoint(this.props.currentPoints, datum.serieId, datum.id)) {
            return <circle r={size / 2} fill={color} />
        }

        if (datum.type === PointType.TRACK_START) {
            // circle with a small circle inside
            return (<g>
                <circle r={size / 2} strokeWidth={borderWidth} fill="#fff" stroke={borderColor} />
                <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
            </g>)

        }
        else if (datum.type === PointType.TRACK_END) {
            // rectangle with a small circle inside
            return (<g>
                <rect width={size} height={size} x={- size / 2} y={- size / 2} strokeWidth={borderWidth} fill="#fff" stroke={borderColor} />
                <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
            </g>)
        } else if (datum.type === PointType.TASK) {
            // no bigger point
            return (<g></g>);
        } else {
            return <circle r={size / 3} fill={color} />
        }
    }

    _getBasicAreaGenerator(p: any) {
        const { xScale, innerHeight } = p;
        return area()
            .x((d: Datum) => xScale(d.data.x))
            .y0((d: Datum) => d.data.type != null ? .1 * innerHeight : 0)
            .y1((d: Datum) => {
                if (d.data.type == null) {
                    return 0;
                }
                let height = 20;
                if (innerHeight > 170) {
                    height = 30;
                }
                return d.data.type != null ? .1 * innerHeight + height : 0
            });
    }

    // in order not use an expensive cache (like lodash.memoize).
    // we are strictly interested to recompute only when one of this parameters change
    protected localDataToRecompute: any = { width: undefined, height: undefined, data: undefined }

    /**
     * Compute the slices needed for faster compute where the mouse is when hovering/clicking on the graphs
     * 
     * It should be faster using the slice than to make a for on each of the series and compare the 
     * current cursor coordinates with the x data transformed.
     * 
     * Uses this.localDataToRecompute to know when to recompute the slices. We are not interested in keeping many values
     * (just the last one), since we do not envison people to play with the graph dimension
     * 
     * Next upgrade should be binary seaching in the slice
     * 
     * @param p generic nivo object received by layer functions. We use it to get the data and width/height 
     */
    computeSlicesIfNeeded(p: any) {
        if (this.localDataToRecompute.width !== p.innerWidth
            || this.localDataToRecompute.height !== p.innerHeight
            || this.localDataToRecompute.data !== p.data) {
            this.localDataToRecompute = {
                width: p.innerWidth,
                height: p.innerHeight,
                data: p.data
            }
            // TODO de fixat; temporar am silenced eroarea
            // miroase a copy/paste din ResponsiveLineChart :(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            this.slices = useSlices({ enableSlices: "x", points: p.points, width: p.innerWidth, height: p.innerHeight });
        } else {
            // need this bogus call to avoid "Rendered fewer hooks than expected. This may be caused by an accidental early return statement." error
            // TODO de fixat; temporar am silenced eroarea
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useSlices({ enableSlices: false, points: [], width: p.innerWidth, height: p.innerHeight });
        }

    }

    areaLayer = (p: any) => {
        this.computeSlicesIfNeeded(p);

        const series = p.series;
        // find a series with id TASKS
        let color = null;
        for (let i = 0; i < series.length; i++) {
            if (series[i].id === TASKS) {
                color = series[i].color;
                break;
            }
        }

        if (color == null) {
            // could not find the EquipmentUsageLogs series, so don't draw any area
            return;
        }

        // for normal tasks
        const areaGenerator =
            this._getBasicAreaGenerator(p)
                .defined((d: Datum) => d.data.type != null ? true : false)

        // for selected tasks
        const areaGeneratorSelected = this._getBasicAreaGenerator(p)
            .defined((d: Datum) => d.data.type != null &&
                (this.props.dispatchers._containsPoint(this.props.selectedPoints, TASKS, d.data.id) || d.data.id === undefined)
                ? true : false)

        let tasksSeries = undefined;
        for (let i = 0; i < series.length; i++) {
            if (series[i].id === TASKS) {
                tasksSeries = i;
                break;
            }
        }

        if (tasksSeries !== undefined) {
            return (
                <>
                    <Defs
                        defs={[
                            {
                                id: 'pattern',
                                type: 'patternLines',
                                background: 'transparent',
                                color: color,
                                lineWidth: 1,
                                spacing: 6,
                                rotation: -45,
                            },
                            {
                                id: 'patternSelected',
                                type: 'patternLines',
                                background: 'transparent',
                                color: color,
                                lineWidth: 4,
                                spacing: 6,
                                rotation: -45,
                            },
                        ]}
                    />
                    <path
                        d={areaGenerator(series[tasksSeries].data) as string}
                        fill="url(#pattern)"
                        fillOpacity={0.6}
                        stroke={color}
                        strokeWidth={2}
                    />
                    <path
                        d={areaGeneratorSelected(series[tasksSeries].data) as string}
                        fill="url(#patternSelected)"
                        fillOpacity={0.6}
                        stroke={color}
                        strokeWidth={4}
                    />
                </>
            )
        }
        return;
    }

    lineLayer = (p: any) => {
        const { series, lineGenerator, xScale, yScale } = p;
        this.xScale = xScale;

        if (this.props.hoverTrack === undefined || this.props.isPointIdOnTrack == null) {
            return;
        }
        return series.map((p1: any) => (
            <path
                key={p1.id}
                d={lineGenerator(
                    p1.data.map((d: any) => ({
                        x: xScale(d.data.x),
                        y: d.data.y != null
                            && (d.data.serieId !== TASKS && d.data.serieId !== EQUIPMENT_USAGE_LOGS)
                            && this.props.hoverTrack !== undefined
                            && this.props.isPointIdOnTrack!(d.data.id, this.props.hoverTrack, d.data.serieId)
                            ? yScale(d.data.y) : null,
                    }))
                )}
                fill="none"
                stroke={p1.color}
                strokeWidth={4}
            />
        ))
    }

    barLegend = (p: any) => (
        <React.Fragment>
            {p.legends.map((legend: any) => (
                <BoxLegendSvg
                    key={JSON.stringify(legend.data.map((id: any) => id))}
                    {...legend}
                    containerHeight={p.height}
                    containerWidth={p.width}
                />
            ))}
        </React.Fragment>
    );

    protected getPointsFor(xCoord: any) {
        let points: any[] | undefined = undefined;
        let sliceIndex = 0;
        // first get the points from the slice having this xCoord
        for (let i = 0; i < this.slices.length; i++) {
            const ptTemp = this.slices[i];
            if (ptTemp.x0 <= xCoord && ptTemp.x0 + ptTemp.width >= xCoord) {
                points = ptTemp.points?.filter((p: any) => p.x <= xCoord && p.serieId !== EQUIPMENT_USAGE_LOGS && p.serieId !== TASKS);
                sliceIndex = i;
                break;
            }
        }
        // second, find the series w/o points in that slice
        let seriesWithoutPoints: any[] = [];
        for (let s of this.props.series) {
            if (!points || points.findIndex(p => p.serieId === s.id && p.serieId !== EQUIPMENT_USAGE_LOGS && p.serieId !== TASKS) === -1) {
                seriesWithoutPoints.push(s.id);
            }
        }
        // third, for those series, find the previous slice that contains a point and add that point to the result
        if (seriesWithoutPoints.length > 0) {
            for (let j = sliceIndex - 1; j >= 0; j--) {
                if (seriesWithoutPoints.length === 0) {
                    break;
                }
                const ps = this.slices[j].points ? this.slices[j].points.filter((p: { serieId: any; }) => seriesWithoutPoints.findIndex(id => p.serieId === id) !== -1) : [];
                for (let p of ps) {
                    if (!points) {
                        points = [];
                    }
                    points.push(p);
                    delete seriesWithoutPoints[seriesWithoutPoints.findIndex(s => s === p.serieId)];
                }
            }
        }

        return points;
    }

    private onChange(value: any) {
        this.props.dispatchers.setInReduxState({ animationCurrentTime: value });
    }

    private onAnimationBtnClick() {
        this.props.dispatchers.setInReduxState({ animationInProgress: !this.props.animationInProgress });
    }

    private startAnimationTimer() {
        this.animationTimer = window.setTimeout(() => {
            let newTime;
            if (this.props.animationCurrentTime === undefined || this.props.animationCurrentTime === this.props.responsiveLineChart.endTime) {
                newTime = this.props.responsiveLineChart.startTime;
            } else {
                newTime = Math.min(this.props.animationCurrentTime! + this.props.animationStep!, this.props.responsiveLineChart.endTime!);
            }
            this.props.dispatchers.setInReduxState({ animationCurrentTime: newTime });
            this.startAnimationTimer();
        });
    }

    private stopAnimationTimer() {
        this.props.dispatchers.setInReduxState({ animationInProgress: false });
        clearTimeout(this.animationTimer);
    }

    protected renderLineChartTooltip(value: any) {
        return (<div id='popupPointId'>
            <div style={{
                background: 'white',
                padding: '5px 9px',
                borderRadius: '2px',
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
                transform: value.point.y >= document.getElementById('ResponsiveLineChartContainer')?.offsetHeight! / 2 ? "translate(-110px, -55px)" : "translate(-110px, 20px)",
                position: 'absolute'
            }}>
                {this.props.currentPoints.map((point) => {
                    let nameSerie = point.serieId;
                    let valueSerie = undefined;
                    if (this.props.getNameAndValue !== undefined && nameSerie !== EQUIPMENT_USAGE_LOGS && nameSerie !== TASKS) {
                        const nameAndValue = this.props.getNameAndValue!({ serieId: point.serieId, id: point.pointId });
                        nameSerie = nameAndValue.nameSerie;
                        valueSerie = nameAndValue.valueSerie;
                    }
                    let info = <span>at {moment(point.data.x).format(Utils.timeFormat)}</span>;
                    if (nameSerie !== EQUIPMENT_USAGE_LOGS) {
                        info = <>
                            <span>{nameSerie}: <strong>{valueSerie}</strong></span>
                            <span> [{_msg("HistoricalMap.atHour", moment(point.data.x).format(Utils.timeFormat))}]</span>
                        </>
                    }
                    return (<div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={point.serieId + "." + point.pointId}>
                        <span style={{ display: "block", width: "12px", height: "12px", background: point.data.color, marginRight: "7px" }} />
                        {info}
                    </div>);
                })}
            </div>
        </div>);
    }

    private getBarChartMappings(): BarChartMapping[] {
        let result: BarChartMapping[] = [];
        if (this.props.dispatchers.isScaleIdNumeric()) {
            for (let i: number = 0; i < 10; i++) {
                result.push({
                    color: getHistoricalMapColor(i), id: i,
                    start: i * this.props.barChartThreshold, end: (i + 1) * this.props.barChartThreshold,
                    label: i * this.props.barChartThreshold + " " + _msg("general.to.lowercase") + " " + (i + 1) * this.props.barChartThreshold
                });
            }
            result.push({
                color: getHistoricalMapColor(10), id: 10,
                start: 10 * this.props.barChartThreshold, end: Number.MAX_VALUE,
                label: "> " + 10 * this.props.barChartThreshold
            });
        } else {
            const keys: Array<any> = Object.keys(this.props.mappingForStringValues);
            for (let i = 0; i < keys.length; i++) {
                result.push({ value: keys[i], color: this.props.mappingForStringValues[keys[i]], id: i, label: keys[i] });
            }
        }
        return result;
    }

    protected renderBarChart() {
        const props = this.props;

        const mapping = this.getBarChartMappings();
        if (mapping.length === 0) {
            return <></>;
        }
        const data: { groups: BarGroup[], items: BarItem[], startDate: number | undefined, endDate: number | undefined } = props.dispatchers.computeBarChartData(this.getCumulatedAvailableSeries().filter(s => s.checked), mapping);
        if (data.startDate && data.endDate) {
            console.log(data, moment(data.startDate).format(Utils.dateTimeFormat), moment(data.endDate).format(Utils.dateTimeFormat));
        }
        // data.items[0].start = moment(new Date("2021-03-15T18:44:00.000+0200"));
        // data.items[0].end = moment(new Date("2021-03-15T18:51:00.000+0200"));
        return data.startDate && data.endDate && <div className="flex-container-row flex-grow">
            <div className="flex-container flex-grow-shrink-no-overflow">
                <Timeline startDate={moment(data.startDate)} endDate={moment(data.endDate)}
                    groups={data.groups} items={data.items} table={
                        <Table rowsCount={1} headerHeight={20} rowHeight={20} width={135} >
                            <Column width={150}
                                cell={({ rowIndex }) => data.groups[rowIndex] && <span key={data.groups[rowIndex].id} style={{ color: data.groups[rowIndex].color ? data.groups[rowIndex].color : 'black' }}>{data.groups[rowIndex].title}</span>}>
                            </Column>
                        </Table>
                    }
                    onInteraction={() => { }} showCursorTime={false}
                    itemRenderer={(value: any) => <Popup hoverable={false} trigger={<span className='rct9k-items-inner EntityTablePage_contextMenu' style={value.item.style}>&nbsp;</span>}
                        content={<div className="flex-container-row flex-center">
                            <Label circular empty horizontal style={{ backgroundColor: value.item.style.backgroundColor }} key={value.item.key} />
                            {value.item.value + " [" + moment(value.item.start).format(Utils.timeFormat) + " - " + moment(value.item.end).format(Utils.timeFormat) + "]"}
                        </div>}
                    />}
                />
            </div>
            <div className="less-padding">
                {mapping.map((item) => (
                    <div className="flex-container-row flex-center"><Label circular empty horizontal style={{ backgroundColor: item.color }} key={item.id} />{item.label}</div>
                ))}</div>
        </div>;
    }

    protected renderLineChart() {
        const props = this.props;
        const rightPadding = 45; // the same value as the one from ResponsiveLineChart.render
        const leftPadding = 10; // the same value as the one from ResponsiveLineChart.render

        return props.series.length > 0 && <>
            <div className="flex-container-row flex-center">
                <div className="flex-container flex-center" style={{ width: props.responsiveLineChart.chartPaddingLeft + leftPadding }}>
                    <Button size='mini' icon color={props.animationInProgress ? "orange" : "green"} onClick={this.onAnimationBtnClick}>
                        <Icon name={props.animationInProgress ? "pause" : "play"} />
                    </Button>
                </div>
                <Slider ref={this.sliderRef} style={{ width: "100%", right: rightPadding, marginLeft: leftPadding + rightPadding, marginRight: 0 }}
                    trackStyle={{ backgroundColor: "#21ba45" }} handleStyle={{ borderColor: "#21ba45" }} // copied from semantic-ui -> "green" 
                    step={1000} min={props.responsiveLineChart.startTime} max={props.responsiveLineChart.endTime}
                    defaultValue={props.animationCurrentTime} tooltipVisible={props.animationInProgress}
                    onChange={this.onChange} tipFormatter={value => moment(value).format(Utils.dateFormatShorter + " " + Utils.timeWithSecFormat)}
                />
            </div>
            <ResponsiveLineChartOld {...props.responsiveLineChart} dispatchers={props.dispatchers.responsiveLineChart}
                initialSeries={props.series} data={[]}
                sliderTooltipFormat={value => moment(value).format(Utils.dateFormatShorter + " " + Utils.timeFormat)}
                axisBottom={{ tickRotation: 45, legend: "", format: value => `${moment(value).format(Utils.dateFormatShorter + " " + Utils.timeFormat)}` }}
                colors={{ datum: 'color' }} curve="monotoneX"
                legendX={props.scaleId && props.telemetryFields[props.scaleId] ? props.telemetryFields[props.scaleId].label : _msg("general.notAvailable")} legendY={_msg("HistoricalMap.time")}
                pointSymbol={this.getPointSymbol}
                legends={[]}
                layers={[
                    'grid',
                    'markers',
                    'axes',
                    'areas',
                    this.areaLayer,
                    'lines',
                    this.lineLayer,
                    'slices',
                    'points',
                    'mesh',
                    'legends',
                ]}
                animate={false}
                enableCrosshair={false}
                tooltip={(value) => this.renderLineChartTooltip(value)}
                onClick={(point, event) => {
                    const points = this.getPointsFor(getRelativeCursor(event.currentTarget, event)[0]);
                    if (points === undefined) {
                        return;
                    }

                    this.props.dispatchers.setInReduxState({ animationCurrentTime: Math.max.apply(Math, points.map(p => p.data.x)) });
                    this.props.onClickOrDragPoint?.call(null, points, false);
                }}
                onMouseMove={(point1, event) => {
                    const points = this.getPointsFor(getRelativeCursor(event.currentTarget, event)[0]);

                    if (points === undefined) {
                        return;
                    }

                    this.props.onPointHover?.call(null, points);
                    if ((event.buttons & 1) === 1) {
                        // primary butoon pressed -> we have a drag
                        this.props.dispatchers.setInReduxState({ animationCurrentTime: Math.max.apply(Math, points.map(p => p.data.x)) });
                        this.props.onClickOrDragPoint?.call(null, points, false);
                    }
                }}
                onMouseLeave={(point, event) => {
                    this.props.onPointHover?.call(null, undefined)
                }}
                markers={[
                    {
                        axis: 'x',
                        value: this.props.currentPoints[0] === undefined ? 0 : this.props.currentPoints[0].data.x,
                        lineStyle: {
                            stroke: '#000',
                            strokeWidth: this.props.currentPoints[0] === undefined ? 0 : 1,
                            strokeOpacity: 0.75,
                            strokeDasharray: "6, 6"
                        }
                    }
                ]}
            />
        </>;
    }

    // gets available series from props toghether with defaultAvailableSeries (to force some in case nothing is in state)
    protected getCumulatedAvailableSeries(): Array<any> {
        if (!this.props.addDefaultAvailableSeries) {
            return this.props.availableSeries;
        }

        let result = [...this.props.availableSeries];
        let already = [];
        const defaultIds = defaultAvailableSeries.map(x => x.id);
        for (let avalSerie of result) {
            if (defaultIds.includes(avalSerie.id)) {
                already.push(avalSerie.id);
            }
        }

        if (already.length < defaultAvailableSeries.length) {
            // need to add from default to result
            for (let defaultEl of defaultAvailableSeries) {
                if (!already.includes(defaultEl.id)) {
                    result.unshift(defaultEl);
                }
            }
        }
        return result;
    }

    render() {
        const props = this.props;
        return props.telemetryFields ? (
            <div className="flex-container flex-grow">
                <MessageExt className="flex-container-row HistogramPresenceInTerritoriesTab_segment">
                    <Button.Group className="HistoricalMapChartComponent_chartType">
                        <Button primary={props.chartType === 'line'} onClick={(event: any) => props.dispatchers.onChartTypeChanged('line')}>
                            {_msg("HistoricalMap.lineChart.title")}
                        </Button>
                        <Button.Or text='or' />
                        <Button primary={props.chartType === 'bar'} onClick={(event: any) => props.dispatchers.onChartTypeChanged('bar')}>
                            {_msg("HistoricalMap.barChart.title")}
                        </Button>
                    </Button.Group>
                    <Popup open={props.isTelemetryFieldPopupOpen} popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                        onOpen={() => props.dispatchers.setInReduxState({ isTelemetryFieldPopupOpen: true })}
                        onClose={() => props.dispatchers.setInReduxState({ isTelemetryFieldPopupOpen: false })}
                        trigger={<Button color='olive' icon="configure" content={props.scaleId && props.telemetryFields[props.scaleId] ? props.telemetryFields[props.scaleId].label : _msg("general.notAvailable")} />}
                        content={<div className="flex-container flex-center">
                            <Form>
                                {props.chartType === 'bar' && <><Form.Field>
                                    {_msg("HistoricalMap.threshhold")}
                                    <div className="flex-container-row flex-center">
                                        <Input name={_msg("HistoricalMap.threshhold")} value={props.barChartThreshold} type='number' min="1"
                                            onChange={(event: any, data: InputProps) => props.dispatchers.onChartBarThresholdChanged(event.target.valueAsNumber)} />
                                    </div>
                                </Form.Field>
                                    <Form.Field>
                                        {_msg("HistoricalMap.mapping")}
                                        <div className="flex-container-row flex-center">
                                            <Input name={_msg("HistoricalMap.mapping")} defaultValue={JSON.stringify(props.mappingForStringValues)} type="text"
                                                onChange={(event: any, data: InputProps) => props.dispatchers.onMappingForStringValuesChanged(event.target.value)} />
                                        </div>
                                    </Form.Field></>}
                                <Form.Field>
                                    {_msg("HistoricalMap.scale")}
                                    <Dropdown
                                        value={props.scaleId} search selection searchInput={{ autoFocus: true }}
                                        options={Object.keys(props.telemetryFields).map(key => ({ key: key, value: key, text: props.telemetryFields[key].label }))}
                                        onChange={(e, { value }) => {
                                            props.dispatchers.changeScale(value as string);
                                            props.dispatchers.setInReduxState({ isTelemetryFieldPopupOpen: false })
                                        }}
                                        data-cy="scale" />
                                </Form.Field>
                            </Form></div>}
                        on='click' position='right center' />
                    {props.customButtons}
                </MessageExt>
                <SplitPane split="vertical" className="flex-container flex-grow" defaultSize="20%" style={{ position: "static" }} >
                    <div className="flex-container flex-grow">
                        <Segment.Group>{
                            this.getCumulatedAvailableSeries().map(x => {
                                return (
                                    <Segment key={x.id}>
                                        <Form.Field
                                            control={Checkbox}
                                            label={{ children: <span style={{ color: x.color ? x.color : 'black' }}>{x.label}</span> }}
                                            key={x.id}
                                            checked={x.checked != null ? x.checked : false}
                                            onClick={() => this.props.onToggleSeries?.call(null, x.id, !x.checked)}
                                        />
                                    </Segment>
                                );
                            })}
                        </Segment.Group>
                    </div>
                    <div className="flex-container flex-grow" data-cy="HistoricalMap.chart">
                        {props.chartType === 'bar' ? this.renderBarChart() : this.renderLineChart()}
                    </div>
                </SplitPane>
            </div>
        ) : <></>;
    }

}
