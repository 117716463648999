import gql from "graphql-tag";

export const HUMAN_RESOURCE_SCHEDULE_LOAD_QUERY = gql`
query humanResourceScheduleService_findByFilter($params: FindByFilterParamsInput) { 
    humanResourceScheduleService_findByFilter(params: $params) {
        results { id humanResource { id identifier firstName lastName qualifications { qualificationType { id name } } } startTime endTime finished team teamName }
    }
}  
`;

export const HUMAN_RESOURCE_GET_QUALIFICATIONS_FOR_DATE = gql`
query humanResourceService_qualificationsForDate($date: Date, $humanResourcesIds: [Long]) { 
    humanResourceService_qualificationsForDate(date: $date, humanResourcesIds: $humanResourcesIds) {
        qualificationType { id description name } startDate endDate
    }
}
`;
