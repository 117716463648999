import { createSliceFoundation, ConnectedPageInfo, getBaseImpures, getBaseReducers, PropsFrom, apolloClient, Utils, FieldDescriptor } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { Segment, Button, Input, Header, Label, Tab, Icon, Grid, Form, Menu, Step, Message, Container, Dropdown, Confirm, Radio, MenuItemProps } from "semantic-ui-react";
import React from "react";
import { Flight } from "apollo-gen/Flight";
import { Baggage } from "apollo-gen/Baggage";
import { Task } from "apollo-gen/Task";
import { loadFlights } from "apollo-gen/loadFlights";
import { LOAD_FLIGHTS, LOAD_CONTAINERS, LOAD_BAGGAGES } from "./queries";
import moment from "moment";
import { loadContainers } from "apollo-gen/loadContainers";
import { loadBaggages } from "apollo-gen/loadBaggages";
import gql from "graphql-tag";
import lodash from 'lodash';
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { entityDescriptors, getOrganizationFilter, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";

export interface BaggageTypeStructure {
    name: string,
    colorValue: number
}

export interface ScanBaggageSettings {
    offset: number,
    baggageTypes: BaggageTypeStructure[]
}

export const sliceScanBaggageFormPage = createSliceFoundation(class SliceScanBaggageFormPage {
    initialState = {
        // option = 1 - when flight is good; = 2 - flight not valid; = 3 when typed containter is not present for flight; = 4 when baggage already exists for flight and container; = 5 multiple flights returned from the server
        option: 0,
        settings: {} as ScanBaggageSettings,
        flightToSearch: "",
        containerToSearch: "",
        baggageToSearch: "",
        baggageTypeSelected: "",
        selectedContainerIndex: undefined as number | undefined,
        selectedFlightIndex: undefined as number | undefined,
        flights: [] as Array<Flight>,
        containers: [] as Array<Task>,
        allBaggages: [] as Array<Baggage>,
        justScannedBaggages: [] as Array<Baggage>,
        address: undefined as any
    }

    reducers = {
        ...getBaseReducers<SliceScanBaggageFormPage>(this),
    }

    impures = {
        ...getBaseImpures<SliceScanBaggageFormPage>(this),

        async loadSettings() {
            const query = gql(`query loadSettings { 
                baggageService_settings { offset baggageTypes { name colorValue} }
            }`);

            const response = (await apolloClient.query({
                query: query
            })).data;

            let settings = response.baggageService_settings as ScanBaggageSettings;
            this.getDispatchers().setInReduxState({ settings: settings, baggageTypeSelected: settings.baggageTypes[0].name });
        },

        moveToScreenFlight() {
            this.getDispatchers().setInReduxState({
                option: 0,
                containerToSearch: "",
                baggageToSearch: "",
                selectedContainerIndex: -1,
                selectedFlightIndex: -1,
                flights: [],
                containers: [],
                justScannedBaggages: [],
                allBaggages: [],
                address: ""
            });
            AppMetaTempGlobals.history.push(`/ScanBaggageForm`);
        },

        async loadContainersForFlight(flightId: number) {
            const response = (await apolloClient.query<loadContainers>({
                query: LOAD_CONTAINERS,
                variables: FindByFilterParams.create().filter(Filter.create('taskGroup', FilterOperators.forEntityManyToOne.equals, flightId.toString()))
            })).data;

            let containers = response.taskService_findByFilter!.results as Array<Task>
            this.getDispatchers().setInReduxState({ containers: containers });
        },

        async loadFlights(flightToSearch: string, flightId?: number) {
            if (flightToSearch === "") {
                return;
            }

            let dateNow = moment(Utils.now()).toISOString()
            let dateWithOffset = moment(Utils.now()).add(this.getState().settings?.offset, "hours").toISOString()

            let customSearch = [
                Filter.create('name', FilterOperators.forString.equals, flightToSearch),
                Filter.createComposed(FilterOperators.forComposedFilter.or, [Filter.create('canceled', FilterOperators.forBoolean.equals, 'false'), Filter.create('canceled', FilterOperators.forBoolean.equals, "null")]),
                Filter.create('date', FilterOperators.forDate.greaterThanOrEqualTo, dateNow),
                Filter.create('date', FilterOperators.forDate.lessThanOrEqualTo, dateWithOffset)
            ];

            if (flightId !== undefined) {
                customSearch.push(Filter.create('id', FilterOperators.forNumber.equals, flightId.toString()))
            }

            const response = (await apolloClient.query<loadFlights>({
                query: LOAD_FLIGHTS,
                variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, customSearch))
            })).data;

            let flights = response.flightService_findByFilter!.results as Array<Flight>
            if (flights.length === 0) {
                this.getDispatchers().setInReduxState({ option: 2 });
            } else {
                this.getDispatchers().setInReduxState({ flights: flights });
                if (flights.length === 1) {
                    this.getDispatchers().setInReduxState({ selectedFlightIndex: 0, option: 1 });
                    this.loadContainersForFlight(flights[0]?.id);
                } else {
                    this.getDispatchers().setInReduxState({ selectedFlightIndex: 0 });
                    this.getDispatchers().setInReduxState({ option: 5 });
                }
            }
        },

        async onContainerSelected(flightId: number, selectedContainerIndex: number) {
            this.getDispatchers().setInReduxState({ selectedContainerIndex: selectedContainerIndex, justScannedBaggages: [], option: 1 });
            await this.loadBaggages(flightId, this.getState().containers[selectedContainerIndex]?.id);

            AppMetaTempGlobals.history.push(`/ScanBaggageForm/scan-page`);
        },

        async onContainerSearchBtnPressed(containerToSearch: string) {
            let container = this.getState().containers.find((e) => e.name === containerToSearch) as Task;
            if (container === undefined) {
                this.getDispatchers().setInReduxState({ option: 3 });
            } else {
                this.getDispatchers().setInReduxState({ containerToSearch: "" });
                const containerIndex = (element: Task) => element === container;
                await this.onContainerSelected(this.getState().flights[this.getState().selectedFlightIndex!].id, this.getState().containers.findIndex(containerIndex));
            }
        },

        async addContainer(nameContainer: string, flightId: number) {
            const saveOperationName = `taskService_save`;
            const saveMutation = gql(`mutation q($params: SaveParams_LongInput) { 
                ${saveOperationName}(params: $params) { ${ID} }
            }`);

            const entity = { name: nameContainer, taskGroup: { id: flightId } };

            const response = await apolloClient.mutate({
                mutation: saveMutation,
                variables: { params: { id: undefined, fieldsAndValues: entity } }
            });

            if (response !== undefined) {
                await this.loadContainersForFlight(flightId);
                await this.onContainerSearchBtnPressed(nameContainer);
            }
        },

        async loadBaggages(flightId: number, containerId: number) {
            const response = (await apolloClient.query<loadBaggages>({
                query: LOAD_BAGGAGES,
                variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                    Filter.createComposed(FilterOperators.forComposedFilter.or, [
                        Filter.create('outFlight', FilterOperators.forEntityManyToOne.equals, flightId.toString()),
                        Filter.create('inFlight', FilterOperators.forEntityManyToOne.equals, flightId.toString())
                    ]),
                    Filter.create('object', FilterOperators.forEntityManyToOne.equals, containerId.toString())
                ]))
            })).data;

            let baggages = response.baggageService_findByFilter!.results as Array<Baggage>
            this.getDispatchers().setInReduxState({ allBaggages: baggages });
        },

        async onScanBaggageBtnPressed(baggageNumber: string, flightId: number, selectedContainerIndex: number, baggageType: string, location: any) {
            if (baggageNumber === "") {
                return;
            }

            await this.loadFlights(this.getState().flightToSearch, flightId);

            if (this.getState().option === 1) {
                let baggage = this.getState().allBaggages.find((e) => e.number === baggageNumber) as Baggage;
                if (baggage !== undefined) {
                    this.getDispatchers().setInReduxState({ option: 4 });
                } else {
                    const saveOperationName = `baggageService_save`;
                    const saveMutation = gql(`mutation q($params: SaveParams_LongInput) { 
                        ${saveOperationName}(params: $params) { ${ID} }
                    }`);

                    let address = location ? location.name : null;
                    let entity = { number: baggageNumber, object: { id: this.getState().containers[selectedContainerIndex].id, taskGroup: { id: flightId } }, location: address, type: baggageType };
                    let newEntity;

                    if (this.getState().flights[this.getState().selectedFlightIndex!].departure) {
                        newEntity = { ...entity, outFlight: { id: flightId } };
                    } else {
                        newEntity = { ...entity, inFlight: { id: flightId } };
                    }

                    await apolloClient.mutate({
                        mutation: saveMutation,
                        variables: { params: { id: undefined, fieldsAndValues: newEntity } }
                    });

                    await this.loadBaggages(flightId, this.getState().containers[selectedContainerIndex].id);
                    let newBaggage = this.getState().allBaggages.find((e) => e.number === baggageNumber) as Baggage;
                    let scannedBaggages = lodash.cloneDeep(this.getState().justScannedBaggages);
                    scannedBaggages.push(newBaggage);
                    this.getDispatchers().setInReduxState({ justScannedBaggages: scannedBaggages, baggageToSearch: "" });
                }
            }
        },

        async removeBaggage(baggageNumber: string) {
            let baggage = this.getState().allBaggages.find((e) => e.number === baggageNumber) as Baggage;
            const removeMutation = gql(`mutation deleteEntity($id: Long){baggageService_deleteById(id: $id)}`);
            await apolloClient.mutate({ mutation: removeMutation, variables: { id: baggage.id } });

            this.getDispatchers().setInReduxState({ justScannedBaggages: this.getState().justScannedBaggages.filter((e) => { return e.number !== baggageNumber }) });

            await this.loadBaggages(this.getState().flights[this.getState().selectedFlightIndex!].id, this.getState().containers[this.getState().selectedContainerIndex!].id);
            this.getDispatchers().setInReduxState({ option: 1 });
        },

        setValueInDropdown(parkingAddress: any, addressDropdownRef?: any) {
            this.getDispatchers().setInReduxState({ address: parkingAddress });
            if (addressDropdownRef) {
                addressDropdownRef.setValues(parkingAddress);
            }
        }
    }
})

export class ScanBaggageFormPage extends TabbedPage<PropsFrom<typeof sliceScanBaggageFormPage>> {

    addressDropdownRef = React.createRef<any>();

    componentDidMount() {
        this.props.dispatchers.loadSettings();
    }

    protected getTitle() {
        return { icon: "suitcase", title: "ScanBaggages" };
    }

    protected getMainRoutePath(): string {
        return "/ScanBaggageForm";
    }

    protected getMainMenuItemProps(): string | MenuItemProps {
        return { icon: "plane", content: _msg("mission.print.vol") };
    }

    protected getExtraTabPanes() {
        const props = this.props;
        return [
            null,
            { routeProps: { path: `/scan-page` }, menuItemProps: { icon: "barcode", content: "Scan" }, render: () => this.renderModeScanBaggage(props) }
        ];
    }

    showTextMessage(props: PropsFrom<typeof sliceScanBaggageFormPage>) {
        switch (props.option) {
            case 2: {
                return _msg("ScanBaggage.flightMsg", props.flightToSearch);
            }
            case 3: {
                return _msg("ScanBaggage.objectMsg", props.containerToSearch, props.flightToSearch);
            }
            case 4: {
                return _msg("ScanBaggage.baggageMsg", props.baggageToSearch, props.flightToSearch);
            }
            case 5: {
                return _msg("ScanBaggage.multipleFlights", props.flightToSearch);
            }
            default: {
                return 'Default message';
            }
        }
    }

    renderModeSelectFlight(props: PropsFrom<typeof sliceScanBaggageFormPage>) {
        return (<Container className='ScanBaggage_container' fluid>
            <Segment className='ScanBaggage_segment'>
                <Grid>
                    <Grid.Column>
                        <Grid.Row>
                            <Header as='h3' attached='top' icon='plane' content={_msg("mission.print.vol")} />
                            <Segment attached>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Input data-cy={"inputFlight"} fluid action={{ color: 'green', icon: 'search', onClick: () => props.dispatchers.loadFlights(props.flightToSearch) }}
                                                value={props.flightToSearch}
                                                onChange={e => props.dispatchers.setInReduxState({ flightToSearch: e.target.value })}
                                                autoFocus
                                            />
                                            {props.option === 2 ?
                                                <Message negative>
                                                    <Message.Header>{this.showTextMessage(props)}</Message.Header>
                                                    <p>{_msg("ScanBaggage.searchAgain")}</p>
                                                </Message> : ""
                                            }
                                            <Confirm
                                                open={props.option === 5}
                                                header={this.showTextMessage(props)}
                                                content={
                                                    <Header size='medium' content={<Form>
                                                        <Header size='medium' content={_msg("ScanBaggage.chooseOption")} />
                                                        {props.flights.map((e, index) => <Form.Field
                                                            control={Radio}
                                                            label={e.name + ' ' + moment(e.date).format('DD/MM/YY HH:mm')}
                                                            value={index.toString()}
                                                            checked={props.selectedFlightIndex === index}
                                                            onChange={() => props.dispatchers.setInReduxState({ selectedFlightIndex: index })}
                                                        />)}
                                                    </Form>} />
                                                }
                                                onConfirm={() => { props.dispatchers.setInReduxState({ option: 1 }); props.dispatchers.loadContainersForFlight(props.flights[props.selectedFlightIndex!]?.id) }}
                                                onCancel={() => props.dispatchers.setInReduxState({ option: 0 })}
                                                cancelButton={_msg("global.button.cancel")}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Row>
                        <br />
                        <Grid.Row>
                            <Header as='h3' attached='top' icon='cube' content={_msg("flight.container")} />
                            <Segment attached>
                                <Input data-cy={"inputContainer"} fluid action={{ color: 'blue', icon: 'search', disabled: props.option === 0 || props.option === 2 || props.containerToSearch === "", onClick: () => props.dispatchers.onContainerSearchBtnPressed(props.containerToSearch) }}
                                    value={props.containerToSearch}
                                    onChange={e => props.dispatchers.setInReduxState({ containerToSearch: e.target.value })}
                                />
                                <Confirm
                                    open={props.option === 3}
                                    header={this.showTextMessage(props)}
                                    content={<Header size='medium' content={_msg("ScanBaggage.addObject")} />}
                                    onConfirm={() => props.dispatchers.addContainer(props.containerToSearch, props.flights[props.selectedFlightIndex!]?.id)}
                                    onCancel={() => props.dispatchers.setInReduxState({ option: 1 })}
                                    cancelButton={_msg("global.button.cancel")}
                                />
                                {props.option === 0 ?
                                    <Message warning>
                                        <Message.Header>{_msg("flight.noFlightSelected")}</Message.Header>
                                    </Message> : ""
                                }
                                {props.containers.length > 0 ?
                                    <Segment.Group>
                                        {props.containers.map((e, index) => <Segment data-cy={"segmentContainer" + index} key={"container" + index} onClick={() => props.dispatchers.onContainerSelected(props.flights[props.selectedFlightIndex!]?.id, index)}>{e.name}</Segment>)}
                                    </Segment.Group> : ""}
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Container>);
    }

    renderModeScanBaggage(props: PropsFrom<typeof sliceScanBaggageFormPage>) {
        const fieldDescriptor = new FieldDescriptor();
        fieldDescriptor.name = "myField";
        fieldDescriptor.type = "Address";

        return (<Container className='ScanBaggage_container' fluid>
            <Segment className='ScanBaggage_segment'>
                <Step.Group widths={2} unstackable>
                    <Step data-cy={"step0"} active onClick={() => props.dispatchers.moveToScreenFlight()}>
                        <Icon name='plane' />
                        <Step.Content>
                            <Step.Title>{props.flights[props.selectedFlightIndex!]?.name}</Step.Title>
                            <Step.Description>{props.option !== 0 ? moment(props.flights[props.selectedFlightIndex!]?.date).format('DD/MM/YY HH:mm') : ""}</Step.Description>
                        </Step.Content>
                    </Step>
                    <Step data-cy={"step1"}>
                        <Icon name='cube' />
                        <Step.Content>
                            <Step.Title>{props.containers[props.selectedContainerIndex!]?.name}</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
                <Grid>
                    <Grid.Column>
                        <Grid.Row>
                            <Form>
                                <Form.Field >
                                    <label>{_msg("ScanBaggage.address")}</label>
                                    <div className="ScanBaggage_formDiv">
                                        <Button.Group >
                                            <Button data-cy={"flightParking"} icon="plane" disabled={props.selectedFlightIndex === undefined || props.selectedFlightIndex === -1} onClick={() => props.dispatchers.setValueInDropdown(props.flights[props.selectedFlightIndex!].parking, this.addressDropdownRef.current!)} />
                                        </Button.Group>
                                        <div className={"ScanBaggage_formDivElement"}>
                                            <AssociationFieldEditor
                                                innerEntityDescriptor={entityDescriptors["Address"]}
                                                fieldDescriptor={fieldDescriptor}
                                                ref={this.addressDropdownRef}
                                                onChange={value => {
                                                    setTimeout(() => {
                                                        props.dispatchers.setInReduxState({ address: value });
                                                    })
                                                }} selectedValue={props.address}
                                                filter={getOrganizationFilter(entityDescriptors["Address"], global.currentOrganizationToFilterBy)}
                                            />
                                        </div>
                                        <Button.Group >
                                            <Button data-cy={"scanLocation"} icon="barcode" color='blue' />
                                        </Button.Group>
                                    </div>
                                </Form.Field >

                                {props.settings.baggageTypes?.length > 0 ?
                                    <Form.Field >
                                        <label>{_msg("ScanBaggage.baggageType")}</label>
                                        <Button.Group fluid className="ScanBaggage_groupBtn">
                                            {props.settings.baggageTypes.map((e, index) =>
                                                <>
                                                    <Button data-cy={"baggageType" + index} key={"option" + index} style={props.baggageTypeSelected === e.name ? { "background": Utils.convertColorToHex(e.colorValue) } : null} positive={props.baggageTypeSelected === e.name} onClick={() => props.dispatchers.setInReduxState({ baggageTypeSelected: e.name })}>{e.name}</Button>
                                                    {index < props.settings.baggageTypes.length - 1 ? <Button.Or /> : ""}
                                                </>)}
                                        </Button.Group>
                                    </Form.Field>
                                    : ""}

                                <Form.Field >
                                    <label>{_msg("ScanBaggage.baggage")}</label>
                                    <div className="ScanBaggage_formDiv">
                                        <div className="ScanBaggage_formDivElement">
                                            <Input data-cy={"inputBaggage"} value={props.baggageToSearch} onChange={e => props.dispatchers.setInReduxState({ baggageToSearch: e.target.value })} autoFocus />
                                        </div>
                                        <Button.Group >
                                            <Button data-cy={"scanBaggage"} icon="barcode" color='blue' />
                                            <Button data-cy={"addBaggage"} icon="add" color='green' disabled={props.baggageToSearch === "" || props.selectedContainerIndex === undefined || props.selectedContainerIndex === -1} onClick={() => props.dispatchers.onScanBaggageBtnPressed(props.baggageToSearch, props.flights[props.selectedFlightIndex!]?.id, props.selectedContainerIndex!, props.baggageTypeSelected, props.address)} />
                                        </Button.Group>
                                    </div>
                                </Form.Field>

                                {props.option === 2 ?
                                    <Message negative>
                                        <Message.Header>{this.showTextMessage(props)}</Message.Header>
                                        <p>{_msg("ScanBaggage.firstScreen")}</p>
                                    </Message> : ""
                                }
                                <Confirm
                                    open={props.option === 4}
                                    header={this.showTextMessage(props)}
                                    content={<Header size='medium' content={_msg("ScanBaggage.deleteBaggage")} />}
                                    onConfirm={() => props.dispatchers.removeBaggage(props.baggageToSearch)}
                                    onCancel={() => props.dispatchers.setInReduxState({ option: 1 })}
                                    cancelButton={_msg("global.button.cancel")}
                                />
                            </Form>
                        </Grid.Row>
                        <br />
                        <Grid.Row>
                            <Tab panes={[
                                {
                                    menuItem: (
                                        <Menu.Item key='scanned'>
                                            <Icon name='suitcase' />{_msg("ScanBaggage.justScanned")}<Label data-cy={"justScannedLbl"} circular color='purple'>{props.justScannedBaggages.length}</Label>
                                        </Menu.Item>
                                    ),
                                    render: () => <Tab.Pane>
                                        <Segment.Group>
                                            {props.justScannedBaggages.map((e, index) => <Segment data-cy={"justScanned" + index} key={"justScanned" + index} className='ScanBaggage_segmentBg'>
                                                <label>{e.number}</label>
                                                <div>
                                                    {props.settings.baggageTypes?.length > 0 && e.type !== "" ? <Label style={{ "color": "white", "background": Utils.convertColorToHex(props.settings.baggageTypes[props.settings.baggageTypes.findIndex((c) => c.name === e.type)]?.colorValue) }}> {e.type}</Label> : ""}
                                                    <Dropdown as={Button} primary floating button icon='content' className='icon EntityTablePage_contextMenu'>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Header content='Actions' />
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item icon="remove" text={_msg("global.button.delete")} onClick={() => props.dispatchers.removeBaggage(e.number!)} />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Segment>)}
                                        </Segment.Group>
                                    </Tab.Pane>
                                },
                                {
                                    menuItem: (
                                        <Menu.Item key='all'>
                                            {_msg("flightAndObjectAction.paxType.all")}<Label data-cy={"allLbl"} circular color='violet'>{props.allBaggages.length}</Label>
                                        </Menu.Item>
                                    ),
                                    render: () => <Tab.Pane>
                                        <Segment.Group>
                                            {props.allBaggages.map((e, index) => <Segment data-cy={"all" + index} key={"allB" + index} className='ScanBaggage_segmentBg'>
                                                <label>{e.number}</label>
                                                <div>
                                                    {props.settings.baggageTypes?.length > 0 && e.type !== "" ? <Label style={{ "color": "white", "background": Utils.convertColorToHex(props.settings.baggageTypes[props.settings.baggageTypes.findIndex((c) => c.name === e.type)]?.colorValue) }}> {e.type}</Label> : ""}
                                                    <Dropdown data-cy={"dropdown-" + index} as={Button} primary floating button icon='content' className='icon EntityTablePage_contextMenu'>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Header content='Actions' />
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item icon="remove" text={_msg("global.button.delete")} onClick={() => props.dispatchers.removeBaggage(e.number!)} />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Segment>)}
                                        </Segment.Group>
                                    </Tab.Pane>
                                }
                            ]} />
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Container>);
    }

    protected renderMain() {
        const props = this.props;
        return this.renderModeSelectFlight(props);
    }
}

export const infoScanBaggageFormPage = new ConnectedPageInfo(sliceScanBaggageFormPage, ScanBaggageFormPage, "ScanBaggageForm");
